import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

const JSONInputWrapper = ({ setFieldValue, jsonData,fieldName }) => {
  const handleJsonChange = (data) => {
    if (data.jsObject) {
      setFieldValue(fieldName, data.jsObject);
    }
  };

  return (
    <JSONInput
      id="json-editor"
      placeholder={jsonData}
      locale={locale}
      theme="light_mitsuketa_tribute"
      colors={{
        default: "#39ADB5",
        keys: "#E53935",
        string: "#91B859",
        number: "#F76D47",
        background: "#F7F7F7",
      }}
      width={"100%"}
      height={"100%"}
      style={{
        outerBox: {
          borderRadius: "6px",
          border: "1px solid #CCC",
          overflow: "hidden",
          background: "#F7F7F7",
        },
      }}
      onChange={handleJsonChange}
      // Include additional props as needed
    />
  );
};

export default JSONInputWrapper;
