import React, { useEffect, useState } from "react";
import { format, formatDistanceToNow } from 'date-fns';
import DotsDropdown from "../../components/DotsDropdown";
import PopupCred from "../../components/cred-popup";
import * as Yup from "yup";
import DynamicFormPopup from "../../components/DynamicFormPopup";
import { formConfigurations } from "../../config/formField";
import createAPI from "../../api";
import { toast } from "react-toastify";
import CommonHeader from "../../components/view/common-header";
import CommonLayout from "../../components/view/common-layout";
import { Link } from "react-router-dom";
import ShareCredentials from "../../components/view/ShareCredentials";

const Credentials = () => {
  const API = createAPI();
  
  
  const [isShareCredentialsPopupOpen, setShareCredentialsPopupOpen] = useState(false);
  const [isFirstPopupOpen, setFirstPopupOpen] = useState(false);
  const [isSecondPopupOpen, setSecondPopupOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [credentials, setCredentials] = useState([]);
  const [buttonText, setButtonText] = useState("Submit");
  const [formConfig, setFormConfig] = useState(null);
  const [isConfirmationPopupOpen, setConfirmationPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const items = [
    { value: "mysql", label: "MySQL", image: "../../images/mysql-logo.png" },
    { value: "whatsAppBusiness", label: "WhatsApp Business", image: "../../images/whatsapp-logo.png" },
    { value: "telegram", label: "Telegram", image: "../../images/telegram-logo.png" },
    { value: "slack", label: "Slack", image: "../../images/slack-logo.png" },
    { value: "msTeams", label: "MS Teams", image: "../../images/ms-teams-logo.png" },
    { value: "intercom", label: "Intercom", image: "../../images/intercom-logo.png" },
    { value: "freshworkscrmaccount", label: "Freshworks CRM account", image: "../../images/freshworkscrm-logo.png" },
    { value: "freshdesk", label: "Freshdesk", image: "../../images/freshdesk-logo.png" },
    { value: "wordpress", label: "Wordpress", image: "../../images/wordpress-logo.png" },
    { value: "googleDoc", label: "Google Doc", image: "../../images/google-docs-logo.png" },
    { value: "googleSheets", label: "Google Sheets", image: "../../images/google-sheet-logo.png" },
    { value: "notion", label: "Notion", image: "../../images/notion-logo.png" },
    { value: "youTube", label: "YouTube", image: "../../images/youtube-logo.png" },
    // Add more items as needed
  ];
  const ShareCredentialsItems = [
    { value: "sharecredentials1", label: "Mahmadjakir Chavda", image: "../../images/sidebar-avatar.jpg", subtitle: "Mahadjakir.c@indianic.com" },
    { value: "sharecredentials2", label: "Mahmad al-saeed", image: "../../images/second-avatar.jpg", subtitle: "saeedalhassan@nicgulf.com" },
  ];
  const handleOpenCredential = (credential) => {
    let selectedConfig = formConfigurations[credential.credType]; 
    setFormConfig(selectedConfig); 
let formFields=selectedConfig.fields
formFields=formFields.map(val=>{
  val['defaultValue']=credential.credFields[val.name]
  return val
}) 
  
    setSecondPopupOpen(true);
  };
  const handleContinue = (selectedOption) => {
    const selectedConfig = formConfigurations[selectedOption.value];
    console.log(selectedOption.value);
    setFormConfig(selectedConfig);
    setSelectedOption(selectedOption.value);
    setFirstPopupOpen(false);
    setSecondPopupOpen(true);
  };
  const handleFormSubmit = async (values) => {
    // API call to submit the form data
    setButtonText("Submiting....");
    await API.post(
      "mysql/create-credential",
      {
        credFields: values,
        name: selectedOption,
        credType: selectedOption,
      },
      {
        headers: {
          "Requires-Auth": true, // This tells the interceptor to add the auth token
        },
      }
    );
   await getCerd()
    setButtonText("Submit");
    setSecondPopupOpen(false);
  };
  async function  getCerd(){
    API.get("mysql/get-credential", {
      headers: {
        "Requires-Auth": true, // This tells the interceptor to add the auth token
      },
    }).then((res) => {
      console.log(res.data);
      setCredentials(res.data.credentals)
    });
  }
  useEffect(() => {
    API.get("mysql/get-credential", {
      headers: {
        "Requires-Auth": true, // This tells the interceptor to add the auth token
      },
    }).then((res) => {
      console.log(res.data);
      setCredentials(res.data.credentals)
    });
  }, []);
  const togglePopup = () => {
    // API call to submit the form data
    setFirstPopupOpen((prev) => !prev);
  };
  const toggleShareCredentialsPopup = () => {
    // API call to submit the form data
    setShareCredentialsPopupOpen((prev) => !prev);
  };
  const formatDates = (dateString) => {
    const date = new Date(dateString);
  
    // "Last updated 29 minutes ago"
    const lastUpdated = `Last updated ${formatDistanceToNow(date, { addSuffix: true })}`;
  
    // "Created 23 April"
    const createdDate = `Created ${format(date, 'd MMMM')}`;
  
    return { lastUpdated, createdDate };
  };
  function handelCredStatus(id){
    setConfirmationPopupOpen(true);
  }
  const handleDeleteClick = (item) => {
    setSelectedItem(item);
    setConfirmationPopupOpen(true); // Show the confirmation popup
  };

  const confirmDelete = async () => {
    try {
      // Call the delete API here with the selectedItem
      await API.delete(`mysql/delete/${selectedItem.id}`,{
        headers: {
          "Requires-Auth": true, // This tells the interceptor to add the auth token
        },
      });
      toast.success('Item deleted successfully!');
      await getCerd()
      // Optionally remove the item from the list or refresh the data
    } catch (error) {
      toast.error('Failed to delete the item.');
    } finally {
      setConfirmationPopupOpen(false); // Close the confirmation popup
    }
  };

  const cancelDelete = () => {
    setConfirmationPopupOpen(false); // Close the confirmation popup
  };
  const dotoptions = [
    {
      imageSrc: '../../images/drop_share.svg',
      label: 'Share',
      onClick: () => {console.log('Share clicked')
        toggleShareCredentialsPopup();
      },
    },
    {
      imageSrc: '../../images/drop_delete.svg',
      label: 'Delete',
      onClick: () => {console.log('Delete clicked')},
    }
  ];
  const iconSrc = '../../images/common-module-dots.svg';
  return (
    <CommonLayout>
      {isFirstPopupOpen && (
        <PopupCred
          isOpen={isFirstPopupOpen}
          onClose={() => setFirstPopupOpen(false)}
          items={items}
          onContinue={handleContinue}
        />
      )}
      {isShareCredentialsPopupOpen && (
        <ShareCredentials
          isOpen={isShareCredentialsPopupOpen}
          onClose={() => setShareCredentialsPopupOpen(false)}
          items={ShareCredentialsItems}
          onContinue={handleContinue}
        />
      )}
      {formConfig && (
        <DynamicFormPopup
          isOpen={isSecondPopupOpen}
          onClose={() => setSecondPopupOpen(false)}
          config={formConfig}
          onSubmit={handleFormSubmit}
          buttonText={buttonText}
        />
      )}
      
      <div className="workflow-listing w-[calc(100%_-_80px)] h-screen ml-auto bg-[#F2F5F7]">
        <CommonHeader />
        <div className="common-module-list px-[40px]">
          <div className="common-module-list-head flex items-center justify-between py-[25px]">
            <div className="workflow-listing-head-title text-[#1C1E21] font-['inter-bold'] text-[20px] not-italic font-normal leading-[normal]">
              My Credentials
            </div>
            <button
              onClick={togglePopup}
              className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-80 flex items-center justify-center border-0 w-[206px] h-[48px] [box-shadow:none] bg-[#006DE0] text-[#FFF] font-[inter-semibold] text-[16px] not-italic font-semibold leading-[normal] !py-0 rounded-[4px]"
            >
              <img
                src="../../images/add-node-plus.svg"
                alt="add-node-plus"
                className="mr-[6px]"
              />
              <span>Add Credentials</span>
            </button>
          </div>
          <div className="common-module-list-head-body bg-[#fff]">
            <div className="common-module-list-actions p-[18px] pb-0">
              <div className="common-module-list-actions-wrap border-b [border-bottom-style:solid] [border-bottom-color:#DCE4E8] flex items-start justify-between pb-[18px]">
                <div className="common-module-list-action-search flex align-center justify-start border [border-style:solid] [border-color:#DCE4E8] rounded-[6px] px-[14px] w-[267px]">
                  <img src="../../images/credentials-dropdown-search.svg" alt="credentials-dropdown-search" width={13} />
                  <input type="text" placeholder="Search credentials..." className="text-[#1C1E21] h-[32px] text-[12px] w-full pl-[10px] font-[inter-medium]" />
                </div>

                <div className="common-module-list-action-buttons flex items-start justify-end">
                  <div className="common-module-list-action-button common-module-list-action-button-filter cursor-pointer w-[92px] h-[32px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[8px]">
                    <img src="../../images/filter-button-icon.svg" alt="filter-button-icon" width={19} />
                    <span className="font-[inter-semibold] text-[11px] text-[#1C1E21] ml-[4px]">Filter</span>
                  </div>
                  <div className="common-module-list-action-button common-module-list-action-button-sortby ml-[8px] cursor-pointer w-[92px] h-[32px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[8px]">
                    <img src="../../images/sort-by-icon.svg" alt="sort-by-icon" width={19} />
                    <span className="font-[inter-semibold] text-[11px] text-[#1C1E21] ml-[4px]">Sort By</span>
                  </div>
                  <div className="common-module-list-action-button common-module-list-action-button-group ml-[8px] cursor-pointer h-[32px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[8px] px-[3px]">
                    <div className="common-module-list-action-button-group-block h-[24px] font-[inter-semibold] bg-[#6A737D] text-[12px] px-[12px] rounded-[6px] leading-[24px] text-[#fff] active">By me</div>
                    <div className="common-module-list-action-button-group-block h-[24px] font-[inter-regular] text-[12px] px-[12px] rounded-[6px] leading-[24px] text-[#1A1C1E]">Team</div>
                    <div className="common-module-list-action-button-group-block h-[24px] font-[inter-regular] text-[12px] px-[12px] rounded-[6px] leading-[24px] text-[#1A1C1E]">Shared</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="common-module-list-rows p-[18px] pb-[4px] max-h-[calc(100vh_-_233px)] overflow-auto">
              <div className="common-module-list-rows-wrap ">
                <div className="common-module-list-row mb-[12px] border [border-style:solid] [border-color:#DCE4E8] rounded-[12px] px-[24px] py-[18px] flex items-center justify-between">
                  <div className="common-module-list-row-left flex iterms-center justify-start">
                    <div className="common-module-list-row-icon">
                      <img src="../../images/gmail-icon.png" alt="gmail-icon" width={36} />
                    </div>
                    <div className="common-module-list-row-left-wrap ml-[15px]">
                      <div className="common-module-list-row-title font-[inter-bold] text-[14px] leading-[16px] text-[#1A1C1E] mb-[8px]">Add label to message</div>
                      <div className="common-module-list-row-desc font-[inter-medium] text-[12px] leading-[14px] text-[#6C7278]">Last updated 4 hours ago by <Link to="/" className="underline" >Debasis</Link> • Created 29 August</div>
                    </div>
                  </div>
                  <div className="common-module-list-row-right flex items-center justify-end">
                    <div className="common-module-list-row-avatars mr-[16px] flex items-center justify-center">
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar-count border-2 [border-style:solid] [border-color:#fff] rounded-[100%] bg-[#EBEEF1]">
                        <div className="w-[32px] h-[32px] flex items-center justify-center font-[inter-bold] text-[13px] tracking-[0.53px]">+2</div>
                      </div>
                    </div>
                    <div className="common-module-list-row-created-by bg-[#EBEEF1] rounded-[4px] font-[inter-medium] text-[12px] leading-[20px] text-[#545969] px-[7px] mr-[16px]">
                      Owned by me
                    </div>                    
                    <div className="common-module-list-row-dots-action w-[32px] h-[32px] cursor-pointer">                    
                      <DotsDropdown options={dotoptions} iconSrc={iconSrc} />
                    </div>
                  </div>
                </div>
                <div className="common-module-list-row mb-[12px] border [border-style:solid] [border-color:#DCE4E8] rounded-[12px] px-[24px] py-[18px] flex items-center justify-between">
                  <div className="common-module-list-row-left flex iterms-center justify-start">
                    <div className="common-module-list-row-icon">
                      <img src="../../images/gmail-icon.png" alt="gmail-icon" width={36} />
                    </div>
                    <div className="common-module-list-row-left-wrap ml-[15px]">
                      <div className="common-module-list-row-title font-[inter-bold] text-[14px] leading-[16px] text-[#1A1C1E] mb-[8px]">Add label to message</div>
                      <div className="common-module-list-row-desc font-[inter-medium] text-[12px] leading-[14px] text-[#6C7278]">Last updated 4 hours ago by <Link to="/" className="underline" >Debasis</Link> • Created 29 August</div>
                    </div>
                  </div>
                  <div className="common-module-list-row-right flex items-center justify-end">
                    <div className="common-module-list-row-avatars mr-[16px] flex items-center justify-center">
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar-count border-2 [border-style:solid] [border-color:#fff] rounded-[100%] bg-[#EBEEF1]">
                        <div className="w-[32px] h-[32px] flex items-center justify-center font-[inter-bold] text-[13px] tracking-[0.53px]">+2</div>
                      </div>
                    </div>
                    <div className="common-module-list-row-created-by bg-[#EBEEF1] rounded-[4px] font-[inter-medium] text-[12px] leading-[20px] text-[#545969] px-[7px] mr-[16px]">
                      Owned by me
                    </div>                    
                    <div className="common-module-list-row-dots-action w-[32px] h-[32px] cursor-pointer">
                      <DotsDropdown options={dotoptions} iconSrc={iconSrc} />
                    </div>
                  </div>
                </div>
                <div className="common-module-list-row mb-[12px] border [border-style:solid] [border-color:#DCE4E8] rounded-[12px] px-[24px] py-[18px] flex items-center justify-between">
                  <div className="common-module-list-row-left flex iterms-center justify-start">
                    <div className="common-module-list-row-icon">
                      <img src="../../images/gmail-icon.png" alt="gmail-icon" width={36} />
                    </div>
                    <div className="common-module-list-row-left-wrap ml-[15px]">
                      <div className="common-module-list-row-title font-[inter-bold] text-[14px] leading-[16px] text-[#1A1C1E] mb-[8px]">Add label to message</div>
                      <div className="common-module-list-row-desc font-[inter-medium] text-[12px] leading-[14px] text-[#6C7278]">Last updated 4 hours ago by <Link to="/" className="underline" >Debasis</Link> • Created 29 August</div>
                    </div>
                  </div>
                  <div className="common-module-list-row-right flex items-center justify-end">
                    <div className="common-module-list-row-avatars mr-[16px] flex items-center justify-center">
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar-count border-2 [border-style:solid] [border-color:#fff] rounded-[100%] bg-[#EBEEF1]">
                        <div className="w-[32px] h-[32px] flex items-center justify-center font-[inter-bold] text-[13px] tracking-[0.53px]">+2</div>
                      </div>
                    </div>
                    <div className="common-module-list-row-created-by bg-[#EBEEF1] rounded-[4px] font-[inter-medium] text-[12px] leading-[20px] text-[#545969] px-[7px] mr-[16px]">
                      Owned by me
                    </div>                    
                    <div className="common-module-list-row-dots-action w-[32px] h-[32px] cursor-pointer">
                      <DotsDropdown options={dotoptions} iconSrc={iconSrc} />
                    </div>
                  </div>
                </div>
                <div className="common-module-list-row mb-[12px] border [border-style:solid] [border-color:#DCE4E8] rounded-[12px] px-[24px] py-[18px] flex items-center justify-between">
                  <div className="common-module-list-row-left flex iterms-center justify-start">
                    <div className="common-module-list-row-icon">
                      <img src="../../images/gmail-icon.png" alt="gmail-icon" width={36} />
                    </div>
                    <div className="common-module-list-row-left-wrap ml-[15px]">
                      <div className="common-module-list-row-title font-[inter-bold] text-[14px] leading-[16px] text-[#1A1C1E] mb-[8px]">Add label to message</div>
                      <div className="common-module-list-row-desc font-[inter-medium] text-[12px] leading-[14px] text-[#6C7278]">Last updated 4 hours ago by <Link to="/" className="underline" >Debasis</Link> • Created 29 August</div>
                    </div>
                  </div>
                  <div className="common-module-list-row-right flex items-center justify-end">
                    <div className="common-module-list-row-avatars mr-[16px] flex items-center justify-center">
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar-count border-2 [border-style:solid] [border-color:#fff] rounded-[100%] bg-[#EBEEF1]">
                        <div className="w-[32px] h-[32px] flex items-center justify-center font-[inter-bold] text-[13px] tracking-[0.53px]">+2</div>
                      </div>
                    </div>
                    <div className="common-module-list-row-created-by bg-[#EBEEF1] rounded-[4px] font-[inter-medium] text-[12px] leading-[20px] text-[#545969] px-[7px] mr-[16px]">
                      Owned by me
                    </div>                    
                    <div className="common-module-list-row-dots-action w-[32px] h-[32px] cursor-pointer">
                      <DotsDropdown options={dotoptions} iconSrc={iconSrc} />
                    </div>
                  </div>
                </div>
                <div className="common-module-list-row mb-[12px] border [border-style:solid] [border-color:#DCE4E8] rounded-[12px] px-[24px] py-[18px] flex items-center justify-between">
                  <div className="common-module-list-row-left flex iterms-center justify-start">
                    <div className="common-module-list-row-icon">
                      <img src="../../images/gmail-icon.png" alt="gmail-icon" width={36} />
                    </div>
                    <div className="common-module-list-row-left-wrap ml-[15px]">
                      <div className="common-module-list-row-title font-[inter-bold] text-[14px] leading-[16px] text-[#1A1C1E] mb-[8px]">Add label to message</div>
                      <div className="common-module-list-row-desc font-[inter-medium] text-[12px] leading-[14px] text-[#6C7278]">Last updated 4 hours ago by <Link to="/" className="underline" >Debasis</Link> • Created 29 August</div>
                    </div>
                  </div>
                  <div className="common-module-list-row-right flex items-center justify-end">
                    <div className="common-module-list-row-avatars mr-[16px] flex items-center justify-center">
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar-count border-2 [border-style:solid] [border-color:#fff] rounded-[100%] bg-[#EBEEF1]">
                        <div className="w-[32px] h-[32px] flex items-center justify-center font-[inter-bold] text-[13px] tracking-[0.53px]">+2</div>
                      </div>
                    </div>
                    <div className="common-module-list-row-created-by bg-[#EBEEF1] rounded-[4px] font-[inter-medium] text-[12px] leading-[20px] text-[#545969] px-[7px] mr-[16px]">
                      Owned by me
                    </div>                    
                    <div className="common-module-list-row-dots-action w-[32px] h-[32px] cursor-pointer">
                      <DotsDropdown options={dotoptions} iconSrc={iconSrc} />
                    </div>
                  </div>
                </div>
                <div className="common-module-list-row mb-[12px] border [border-style:solid] [border-color:#DCE4E8] rounded-[12px] px-[24px] py-[18px] flex items-center justify-between">
                  <div className="common-module-list-row-left flex iterms-center justify-start">
                    <div className="common-module-list-row-icon">
                      <img src="../../images/gmail-icon.png" alt="gmail-icon" width={36} />
                    </div>
                    <div className="common-module-list-row-left-wrap ml-[15px]">
                      <div className="common-module-list-row-title font-[inter-bold] text-[14px] leading-[16px] text-[#1A1C1E] mb-[8px]">Add label to message</div>
                      <div className="common-module-list-row-desc font-[inter-medium] text-[12px] leading-[14px] text-[#6C7278]">Last updated 4 hours ago by <Link to="/" className="underline" >Debasis</Link> • Created 29 August</div>
                    </div>
                  </div>
                  <div className="common-module-list-row-right flex items-center justify-end">
                    <div className="common-module-list-row-avatars mr-[16px] flex items-center justify-center">
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[10px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar-count border-2 [border-style:solid] [border-color:#fff] rounded-[100%] bg-[#EBEEF1]">
                        <div className="w-[32px] h-[32px] flex items-center justify-center font-[inter-bold] text-[13px] tracking-[0.53px]">+2</div>
                      </div>
                    </div>
                    <div className="common-module-list-row-created-by bg-[#EBEEF1] rounded-[4px] font-[inter-medium] text-[12px] leading-[20px] text-[#545969] px-[7px] mr-[16px]">
                      Owned by me
                    </div>                    
                    <div className="common-module-list-row-dots-action w-[32px] h-[32px] cursor-pointer">
                      <DotsDropdown options={dotoptions} iconSrc={iconSrc} />
                    </div>
                  </div>
                </div>
              </div>
            </div>           
          </div>
        </div>
        {/* <div className="workflow-listing max-w-[896px] mx-auto">
          <div className="workflow-listing-head flex items-center justify-between py-[48px]">
            <div className="workflow-listing-head-title text-[#494949] font-['inter-medium'] text-[32px] not-italic font-medium leading-[normal]">
              Credentials
            </div>
            <button
              onClick={togglePopup}
              className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-80 flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#006DE0] text-[#FFF] font-[inter-semibold] text-[14px] not-italic font-semibold leading-[normal] !py-0 px-[15px] rounded-[8px]"
            >
              <img
                src="../../images/add-node-plus.svg"
                alt="add-node-plus"
                className="mr-[6px]"
              />
              <span>Add Credentials</span>
            </button>
          </div>
          <div className="workflow-listing-body">
           { credentials.map((val,key)=>{
              const dotoptions = [
                {
                  label: "Open",
                  onClick: () => { 
                    handleOpenCredential(val)
                  },
                },
                {
                  label: "Delete",
                  onClick: () =>{
                    handleDeleteClick(val)
                  },
                },
              ];
           return (<div className="workflow-listing-items" key={key}>
              <div className="workflow-listing-item flex items-start justify-between [box-shadow:0px_8px_10.1px_rgba(191,_204,_217,_0.46)] mb-[16px] bg-[#fff] rounded-[6px] px-[30px] pt-[22px] pb-[18px]">
                <div className="workflow-listing-item-text">
                  <div className="workflow-listing-item-text-title text-[#60809F] font-[inter-regular] text-[20px] not-italic font-normal leading-[normal] mb-[9px]">
                    {val.name}
                  </div>
                  <div className="workflow-listing-item-text-desc text-[#60809F] font-[inter-regular] text-[15px] not-italic font-normal leading-[normal] opacity-70">
                    Last updated {formatDistanceToNow(val.updated_at, { addSuffix: true })} | Created {format(val.created_at, 'd MMMM')}
                  </div>
                </div>
                <div className="workflow-listing-item-action flex items-center justify-end">
                  <div className="workflow-listing--switch node-comm-switch relative flex items-center justify-center mr-[20px]">
                    <input
                      type="checkbox"
                      id="send-query-parameters-switch"
                      className="h-[0] w-[0] invisible"
                      onClick={()=>handelCredStatus(val.id)}
                    />
                    <label
                      className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative"
                      htmlFor="send-query-parameters-switch"
                    >
                      Toggle
                    </label>
                  </div>
                  <DotsDropdown options={dotoptions} iconSrc={iconSrc} />
                </div>
              </div>
            </div>)})}
          </div>
        </div> */}
      </div>
      {isConfirmationPopupOpen && (
        <div className="confirmation-popup  z-[100] fixed top-[0] left-[0] w-full h-full bg-[rgba(0,_0,_0,_0.5)] flex justify-center items-center">
          <div className="w-[350px] bg-[#fff] rounded-[6px] relative">
          <div className="popup-header rounded-tl-[14px] rounded-tr-[14px] bg-[#F5F9FF] px-[29px] flex items-center justify-between h-[57px]">
          <div className="text-[#1C1E21] font-['inter-semibold'] text-[18px] not-italic font-medium leading-[28px] -tracking-[0.34px]">Confirm Action</div>
          <button
            className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50"
            onClick={cancelDelete}
          >
            <img src="../../images/close-popup.svg" alt="popup-close" />
          </button>
          
          </div>
          <div className="p-[30px]">
            <p className="text-[#000] font-['inter-regular'] text-[14px] not-italic font-normal leading-[normal] mb-[20px]">Are you sure you want to delete this item?</p>
            <div className="flex align-start justify-start">
            <button className="min-w-[80px] [transition:0.3s_opacity_ease] cursor-pointer opacity-100 hover:opacity-80 flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#006DE0] text-[#FFF] font-[inter-semibold] text-[14px] not-italic font-semibold leading-[normal] !py-0 px-[15px] rounded-[6px]" onClick={confirmDelete}>Yes</button>
            <button className="ml-[10px] min-w-[80px] [transition:0.3s_all_ease] flex items-center justify-center border-0 h-[40px] [box-shadow:none] border-[1px] border-[solid] bg-transparent hover:bg-[#006DE0] border-[#006DE0] text-[#006DE0] hover:text-[#FFF] font-[inter-regular] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px]" onClick={cancelDelete}>Cancel</button> 
            </div>
            </div>
          </div>
        </div>
      )}
    </CommonLayout>
  );
};
export default Credentials;
