import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import createAPI from "../../api";
import { Link } from 'react-router-dom';

const Signup = () => {
  const [buttonText, setButtonText] = useState("Sign up");
  const navigate = useNavigate();
  const API = createAPI();
  const formik = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      fname: Yup.string()
        .min(2, "First name must be at least 2 characters")
        .required("First name is required"),
      lname: Yup.string()
        .min(2, "Last name must be at least 2 characters")
        .required("Last name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setButtonText("Submitting....");
        const response = await API.post("/auth/register", values);
        console.log("Signup successful", response.data);
        toast(response.data.serverResponse.message, {
          position: "top-right",
          // autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          // progress: undefined,
          theme: "light",
        });
        // Handle success, e.g., redirect to a new page
        navigate("/auth/signin");
      } catch (error) {
        setButtonText("Sign up"); 
        toast(error.response.data.serverResponse.message, {
          position: "top-right",
          // autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          // progress: undefined,
          theme: "light",
        });
        console.error(
          "Signup failed",
          error.response.data.serverResponse.message
        );
        // Handle error, e.g., show an error message
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <div className="auth-main min-h-screen relative bg-[#F6F8FA]">
      <div className="auth-bg-block bg-[#E8F0FC] w-full h-[390px] absolute left-[0] top-[0] z-[0]"></div>
      <div className="auth-bg-icon auth-bg-icon-left block absolute left-[0] bottom-[0] z-[0]">
        <img src="/images/auth_left_watermark.svg" alt="auth_left_watermark" />
      </div>
      <div className="auth-bg-icon auth-bg-icon-right block absolute right-[0] top-[470px] z-[0]">
        <img src="/images/auth_right_watermark.svg" alt="auth_right_watermark" />
      </div>
      <div className="auth-inn max-w-[552px] mx-auto relative z-[0] pb-[100px]">
        <div className="auth-logo flex items-end justify-center pt-[100px] pb-[90px]">
          <img src="/images/auth_logo.svg" alt="auth_logo" width={52} />
          <span className="ml-[8px] text-[#1A1C1E] font-[plus-jakarta-sans-extrabold] text-[32px] leading-[48px]">WORKFLOW</span>
        </div>
        <div className="auth-msg text-center text-[#30313D] font-[inter-bold] text-[24px] leading-[28px] mb-[35px]">Sign up</div>
          <div className="auth-frame bg-[#fff] rounded-[8px] p-[40px]">
          <div className="auth-head mb-[30px]">
            <div className="auth-head-title text-[#30313D] font-[inter-bold] text-[18px] leading-[25px] mb-[5px]">Sign up</div>
            <div className="auth-head-subtitle text-[#595A64] font-[inter-medium] text-[14px] leading-[19.6px] ">Sign up sub heading text goes here.</div>
          </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="midform-field mb-[25px] relative">
                <label className="midform-field-label block text-[#595A64] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                  First Name <span className="text-[#df1941]">*</span>
                </label>
                <input
                  className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                  type="text"
                  name="fname"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fname}
                />
                {formik.touched.fname && formik.errors.fname ? (
                  <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                    {formik.errors.fname}
                  </div>
                ) : null}
              </div>
              <div className="midform-field mb-[25px] relative">
                <label className="midform-field-label block text-[#595A64] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                  Last Name <span className="text-[#df1941]">*</span>
                </label>
                <input
                  className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                  type="text"
                  name="lname"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lname}
                />
                {formik.touched.lname && formik.errors.lname ? (
                  <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                    {formik.errors.lname}
                  </div>
                ) : null}
              </div>
              <div className="midform-field mb-[25px] relative">
                <label className="midform-field-label block text-[#595A64] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                  Email <span className="text-[#df1941]">*</span>
                </label>
                <input
                  className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                  type="text"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className="midform-field mb-[25px] relative">
                <label className="midform-field-label block text-[#595A64] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                  Password <span className="text-[#df1941]">*</span>
                </label>
                <input
                  className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                  type="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="mt-[32px] w-full mx-auto [transition:0.3s_opacity_ease] opacity-100 hover:opacity-80 flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#006DE0] text-[#FFF] font-[inter-medium] text-[14px] not-italic leading-[normal] !py-0 px-[15px] rounded-[6px]"
              >
                <span>{buttonText}</span>
              </button>
              <div className="seperation-or relative flex align-center justify-center my-[10px]">
                <span className="seperation-or-text bg-[#fff] text-[#666] text-[12px] relative z-[1] px-[20px] font-[inter-regular] font-normal leading-[normal]">
                  Or
                </span>
                <span className="seperation-or-rod block bg-[#006DE0] w-full h-[1px] absolute m-[auto] top-0 bottom-0 z-0 opacity-30"></span>
              </div>
              <Link
                to="/auth/signin"
                className="w-full mx-auto [transition:0.3s_all_ease] flex items-center justify-center border-0 h-[40px] [box-shadow:none] border-[1px] border-[solid] bg-transparent hover:bg-[#006DE0] border-[#006DE0] text-[#006DE0] hover:text-[#FFF] font-[inter-regular] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px]"
              >
                Sign in
              </Link>
            </form>
          </div>
          <div className="auth_support_text mt-[30px] text-center text-[#595A64] font-[inter-medium] text-[14px] leading-[19.6px]">
          If you encounter any issues while creating your password, feel free to contact our <a href="#" className="text-[#006DE0] underline">customer support team</a>.
        </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
