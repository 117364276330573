import React, { useEffect, useState } from "react";
import { format, formatDistanceToNow } from 'date-fns';
import DotsDropdown from "../../components/DotsDropdown";
import PopupCred from "../../components/cred-popup";
import * as Yup from "yup";
import DynamicFormPopup from "../../components/DynamicFormPopup";
import { formConfigurations } from "../../config/formField";
import createAPI from "../../api";
import { toast } from "react-toastify";
import CommonHeader from "../../components/view/common-header";
import CommonLayout from "../../components/view/common-layout";
import { Link } from "react-router-dom";

const CustomNode = () => {
  const API = createAPI();
  const iconSrc = "../../images/workflow-dots.svg";

  const [isFirstPopupOpen, setFirstPopupOpen] = useState(false);
  const [isSecondPopupOpen, setSecondPopupOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [credentials, setCredentials] = useState([]);
  const [buttonText, setButtonText] = useState("Submit");
  const [formConfig, setFormConfig] = useState(null);
  const [isConfirmationPopupOpen, setConfirmationPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const items = [
    { value: "mysql", label: "MySQL" },
    { value: "whatsAppBusiness", label: "WhatsApp Business" },
    { value: "telegram", label: "Telegram" },
    { value: "slack", label: "Slack" },
    { value: "msTeams", label: "MS Teams" },
    { value: "intercom", label: "Intercom" },
    { value: "freshworkscrmaccount", label: "Freshworks CRM account" },
    { value: "freshdesk", label: "Freshdesk" },
    { value: "wordpress", label: "Wordpress" },
    { value: "googleDoc", label: "Google Doc" },
    { value: "googleSheets", label: "Google Sheets" },
    { value: "notion", label: "Notion" },
    { value: "youTube", label: "YouTube" },
    // Add more items as needed
  ];
  const handleOpenCredential = (credential) => {
    let selectedConfig = formConfigurations[credential.credType]; 
    setFormConfig(selectedConfig); 
let formFields=selectedConfig.fields
formFields=formFields.map(val=>{
  val['defaultValue']=credential.credFields[val.name]
  return val
}) 
  
    setSecondPopupOpen(true);
  };
  const handleContinue = (selectedOption) => {
    const selectedConfig = formConfigurations[selectedOption.value];
    console.log(selectedOption.value);
    setFormConfig(selectedConfig);
    setSelectedOption(selectedOption.value);
    setFirstPopupOpen(false);
    setSecondPopupOpen(true);
  };
  const handleFormSubmit = async (values) => {
    // API call to submit the form data
    setButtonText("Submiting....");
    await API.post(
      "mysql/create-credential",
      {
        credFields: values,
        name: selectedOption,
        credType: selectedOption,
      },
      {
        headers: {
          "Requires-Auth": true, // This tells the interceptor to add the auth token
        },
      }
    );
   await getCerd()
    setButtonText("Submit");
    setSecondPopupOpen(false);
  };
  async function  getCerd(){
    API.get("mysql/get-credential", {
      headers: {
        "Requires-Auth": true, // This tells the interceptor to add the auth token
      },
    }).then((res) => {
      console.log(res.data);
      setCredentials(res.data.credentals)
    });
  }
  useEffect(() => {
    API.get("mysql/get-credential", {
      headers: {
        "Requires-Auth": true, // This tells the interceptor to add the auth token
      },
    }).then((res) => {
      console.log(res.data);
      setCredentials(res.data.credentals)
    });
  }, []);
  const togglePopup = () => {
    // API call to submit the form data
    setFirstPopupOpen((prev) => !prev);
  };
  const formatDates = (dateString) => {
    const date = new Date(dateString);
  
    // "Last updated 29 minutes ago"
    const lastUpdated = `Last updated ${formatDistanceToNow(date, { addSuffix: true })}`;
  
    // "Created 23 April"
    const createdDate = `Created ${format(date, 'd MMMM')}`;
  
    return { lastUpdated, createdDate };
  };
  function handelCredStatus(id){
    setConfirmationPopupOpen(true);
  }
  const handleDeleteClick = (item) => {
    setSelectedItem(item);
    setConfirmationPopupOpen(true); // Show the confirmation popup
  };

  const confirmDelete = async () => {
    try {
      // Call the delete API here with the selectedItem
      await API.delete(`mysql/delete/${selectedItem.id}`,{
        headers: {
          "Requires-Auth": true, // This tells the interceptor to add the auth token
        },
      });
      toast.success('Item deleted successfully!');
      await getCerd()
      // Optionally remove the item from the list or refresh the data
    } catch (error) {
      toast.error('Failed to delete the item.');
    } finally {
      setConfirmationPopupOpen(false); // Close the confirmation popup
    }
  };

  const cancelDelete = () => {
    setConfirmationPopupOpen(false); // Close the confirmation popup
  };
  return (
    <CommonLayout>
      {isFirstPopupOpen && (
        <PopupCred
          isOpen={isFirstPopupOpen}
          onClose={() => setFirstPopupOpen(false)}
          items={items}
          onContinue={handleContinue}
        />
      )}
      {formConfig && (
        <DynamicFormPopup
          isOpen={isSecondPopupOpen}
          onClose={() => setSecondPopupOpen(false)}
          config={formConfig}
          onSubmit={handleFormSubmit}
          buttonText={buttonText}
        />
      )}
      
      <div className="workflow-listing w-[calc(100%_-_80px)] h-screen ml-auto bg-[#F2F5F7]">
        <CommonHeader />
        <div className="common-module-list px-[40px]">
          <div className="common-module-list-head flex items-center justify-between py-[25px]">
            <div className="workflow-listing-head-title text-[#1C1E21] font-['inter-bold'] text-[20px] not-italic font-normal leading-[normal]">
              My custom node
            </div>
            <Link
              to="/home/workflow/new"
              className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-80 flex items-center justify-center border-0 w-[206px] h-[48px] [box-shadow:none] bg-[#006DE0] text-[#FFF] font-[inter-semibold] text-[16px] not-italic font-semibold leading-[normal] !py-0 rounded-[4px]"
            >
              <span className="mr-[6px]">Create New</span>
              <img src="../../images/add-node-plus.svg" alt="add-node-plus" />
            </Link>
          </div>
          <div className="common-module-list-head-body bg-[#fff]">
            <div className="common-module-list-actions p-[18px] pb-0">
              <div className="common-module-list-actions-wrap border-b [border-bottom-style:solid] [border-bottom-color:#DCE4E8] flex items-start justify-between pb-[18px]">
                <div className="common-module-list-action-search flex align-center justify-start border [border-style:solid] [border-color:#DCE4E8] rounded-[6px] px-[14px] w-[267px]">
                  <img src="../../images/credentials-dropdown-search.svg" alt="credentials-dropdown-search" width={13} />
                  <input type="text" placeholder="Search custom nodes..." className="text-[#1C1E21] h-[32px] text-[12px] w-full pl-[10px] font-[inter-medium]" />
                </div>

                <div className="common-module-list-action-buttons flex items-start justify-end">
                <div className="common-module-list-action-button common-module-list-action-button-sortby cursor-pointer w-[92px] h-[32px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[8px]">
                    <img src="../../images/sort-by-icon.svg" alt="sort-by-icon" width={19} />
                    <span className="font-[inter-semibold] text-[11px] text-[#1C1E21] ml-[4px]">Category</span>
                  </div>
                  <div className="common-module-list-action-button common-module-list-action-button-filter  ml-[8px] cursor-pointer w-[92px] h-[32px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[8px]">
                    <img src="../../images/filter-button-icon.svg" alt="filter-button-icon" width={19} />
                    <span className="font-[inter-semibold] text-[11px] text-[#1C1E21] ml-[4px]">Filter</span>
                  </div>
                  <div className="common-module-list-action-button common-module-list-action-button-sortby ml-[8px] cursor-pointer w-[92px] h-[32px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[8px]">
                    <img src="../../images/sort-by-icon.svg" alt="sort-by-icon" width={19} />
                    <span className="font-[inter-semibold] text-[11px] text-[#1C1E21] ml-[4px]">Sort By</span>
                  </div>                  
                </div>
              </div>
            </div>
            <div className="common-module-list-rows p-[18px] pb-[4px] max-h-[calc(100vh_-_233px)] overflow-auto">
              <div className="common-module-list-rows-wrap ">
                <div class="common-module-list-row-custom-node-group">
                <div class="common-module-list-row-custom-node-group-title flex items-center justify-start mb-[20px]">
                  <img src="../../images/gmail-icon.png" alt="gmail-icon" width={20} />
                  <span className="ml-[10px] font-[inter-semibold] text-[16px] leading-[22.4px]">Gmail node</span>
                </div>
                <div className="common-module-list-row mb-[12px] border [border-style:solid] [border-color:#DCE4E8] rounded-[12px] px-[24px] py-[18px] flex items-center justify-between">
                  <div className="common-module-list-row-left flex iterms-center justify-start">
                    <div className="common-module-list-row-icon">
                      <img src="../../images/gmail-icon.png" alt="gmail-icon" width={36} />
                    </div>
                    <div className="common-module-list-row-left-wrap ml-[15px]">
                      <div className="common-module-list-row-title font-[inter-bold] text-[14px] leading-[16px] text-[#1A1C1E] mb-[8px]">Add label to message</div>
                      <div className="common-module-list-row-desc font-[inter-medium] text-[12px] leading-[14px] text-[#6C7278]">Last updated 4 hours ago by <Link to="/" className="underline" >Debasis</Link> • Created 29 August</div>
                    </div>
                  </div>
                  <div className="common-module-list-row-right flex items-center justify-end">                    
                    <div className="common-module-list-row-created-by bg-[#EBEEF1] rounded-[4px] font-[inter-medium] text-[12px] leading-[20px] text-[#545969] px-[7px] mr-[16px]">
                      Created by me
                    </div>      
                    <div className="workflow-switch node-comm-switch flex items-center justify-center mr-[16px]">
                      <input
                        type="checkbox"
                        id="workflow-switch"
                        className="h-[0] w-[0] invisible"
                      />
                      <label
                        className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative mr-[6px]"
                        htmlFor="workflow-switch"
                      >
                        Toggle
                      </label>
                      <div className="switch-spans flex items-center justify-center">
                        <span className="switch-span switch-span-1 text-[#6C7278] text-right font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]">
                          Inactive
                        </span>
                        <span className="switch-span switch-span-2 text-[#6C7278] text-right font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]">
                          Active
                        </span>
                      </div>
                    </div>              
                    <div className="common-module-list-action-button common-module-list-action-button-group mr-[15px] cursor-pointer h-[24px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[24px] px-[2px] bg-[#EAEAEC]">
                      <div className="common-module-list-action-button-group-block h-[20px] font-[inter-semibold] bg-[#6A737D] text-[12px] px-[12px] rounded-[20px] leading-[20px] text-[#fff] active">Public</div>
                      <div className="common-module-list-action-button-group-block h-[20px] font-[inter-regular] text-[12px] px-[12px] rounded-[20px] leading-[20px] text-[#1A1C1E]">Private</div>                    
                    </div>                  
                    <div className="common-module-list-row-dots-action w-[32px] h-[32px] cursor-pointer">
                      <img src="/images/common-module-dots.svg" alt="common-module-dots" />
                    </div>
                  </div>
                </div>
                <div className="common-module-list-row mb-[12px] border [border-style:solid] [border-color:#DCE4E8] rounded-[12px] px-[24px] py-[18px] flex items-center justify-between">
                  <div className="common-module-list-row-left flex iterms-center justify-start">
                    <div className="common-module-list-row-icon">
                      <img src="../../images/gmail-icon.png" alt="gmail-icon" width={36} />
                    </div>
                    <div className="common-module-list-row-left-wrap ml-[15px]">
                      <div className="common-module-list-row-title font-[inter-bold] text-[14px] leading-[16px] text-[#1A1C1E] mb-[8px]">Add label to message</div>
                      <div className="common-module-list-row-desc font-[inter-medium] text-[12px] leading-[14px] text-[#6C7278]">Last updated 4 hours ago by <Link to="/" className="underline" >Debasis</Link> • Created 29 August</div>
                    </div>
                  </div>
                  <div className="common-module-list-row-right flex items-center justify-end">                    
                    <div className="common-module-list-row-created-by bg-[#EBEEF1] rounded-[4px] font-[inter-medium] text-[12px] leading-[20px] text-[#545969] px-[7px] mr-[16px]">
                      Created by me
                    </div>      
                    <div className="workflow-switch node-comm-switch flex items-center justify-center mr-[16px]">
                      <input
                        type="checkbox"
                        id="workflow-switch"
                        className="h-[0] w-[0] invisible"
                      />
                      <label
                        className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative mr-[6px]"
                        htmlFor="workflow-switch"
                      >
                        Toggle
                      </label>
                      <div className="switch-spans flex items-center justify-center">
                        <span className="switch-span switch-span-1 text-[#6C7278] text-right font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]">
                          Inactive
                        </span>
                        <span className="switch-span switch-span-2 text-[#6C7278] text-right font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]">
                          Active
                        </span>
                      </div>
                    </div>              
                    <div className="common-module-list-action-button common-module-list-action-button-group mr-[15px] cursor-pointer h-[24px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[24px] px-[2px] bg-[#EAEAEC]">
                      <div className="common-module-list-action-button-group-block h-[20px] font-[inter-semibold] bg-[#6A737D] text-[12px] px-[12px] rounded-[20px] leading-[20px] text-[#fff] active">Public</div>
                      <div className="common-module-list-action-button-group-block h-[20px] font-[inter-regular] text-[12px] px-[12px] rounded-[20px] leading-[20px] text-[#1A1C1E]">Private</div>                    
                    </div>                  
                    <div className="common-module-list-row-dots-action w-[32px] h-[32px] cursor-pointer">
                      <img src="/images/common-module-dots.svg" alt="common-module-dots" />
                    </div>
                  </div>
                </div>
                <div className="common-module-list-row mb-[12px] border [border-style:solid] [border-color:#DCE4E8] rounded-[12px] px-[24px] py-[18px] flex items-center justify-between">
                  <div className="common-module-list-row-left flex iterms-center justify-start">
                    <div className="common-module-list-row-icon">
                      <img src="../../images/gmail-icon.png" alt="gmail-icon" width={36} />
                    </div>
                    <div className="common-module-list-row-left-wrap ml-[15px]">
                      <div className="common-module-list-row-title font-[inter-bold] text-[14px] leading-[16px] text-[#1A1C1E] mb-[8px]">Add label to message</div>
                      <div className="common-module-list-row-desc font-[inter-medium] text-[12px] leading-[14px] text-[#6C7278]">Last updated 4 hours ago by <Link to="/" className="underline" >Debasis</Link> • Created 29 August</div>
                    </div>
                  </div>
                  <div className="common-module-list-row-right flex items-center justify-end">                    
                    <div className="common-module-list-row-created-by bg-[#EBEEF1] rounded-[4px] font-[inter-medium] text-[12px] leading-[20px] text-[#545969] px-[7px] mr-[16px]">
                      Created by me
                    </div>      
                    <div className="workflow-switch node-comm-switch flex items-center justify-center mr-[16px]">
                      <input
                        type="checkbox"
                        id="workflow-switch"
                        className="h-[0] w-[0] invisible"
                      />
                      <label
                        className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative mr-[6px]"
                        htmlFor="workflow-switch"
                      >
                        Toggle
                      </label>
                      <div className="switch-spans flex items-center justify-center">
                        <span className="switch-span switch-span-1 text-[#6C7278] text-right font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]">
                          Inactive
                        </span>
                        <span className="switch-span switch-span-2 text-[#6C7278] text-right font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]">
                          Active
                        </span>
                      </div>
                    </div>              
                    <div className="common-module-list-action-button common-module-list-action-button-group mr-[15px] cursor-pointer h-[24px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[24px] px-[2px] bg-[#EAEAEC]">
                      <div className="common-module-list-action-button-group-block h-[20px] font-[inter-semibold] bg-[#6A737D] text-[12px] px-[12px] rounded-[20px] leading-[20px] text-[#fff] active">Public</div>
                      <div className="common-module-list-action-button-group-block h-[20px] font-[inter-regular] text-[12px] px-[12px] rounded-[20px] leading-[20px] text-[#1A1C1E]">Private</div>                    
                    </div>                  
                    <div className="common-module-list-row-dots-action w-[32px] h-[32px] cursor-pointer">
                      <img src="/images/common-module-dots.svg" alt="common-module-dots" />
                    </div>
                  </div>
                </div>
                </div>
                <div class="common-module-list-row-custom-node-group">
                <div class="common-module-list-row-custom-node-group-title flex items-center justify-start mb-[20px]">
                  <img src="../../images/gmail-icon.png" alt="gmail-icon" width={20} />
                  <span className="ml-[10px] font-[inter-semibold] text-[16px] leading-[22.4px]">Gmail node</span>
                </div>
                <div className="common-module-list-row mb-[12px] border [border-style:solid] [border-color:#DCE4E8] rounded-[12px] px-[24px] py-[18px] flex items-center justify-between">
                  <div className="common-module-list-row-left flex iterms-center justify-start">
                    <div className="common-module-list-row-icon">
                      <img src="../../images/gmail-icon.png" alt="gmail-icon" width={36} />
                    </div>
                    <div className="common-module-list-row-left-wrap ml-[15px]">
                      <div className="common-module-list-row-title font-[inter-bold] text-[14px] leading-[16px] text-[#1A1C1E] mb-[8px]">Add label to message</div>
                      <div className="common-module-list-row-desc font-[inter-medium] text-[12px] leading-[14px] text-[#6C7278]">Last updated 4 hours ago by <Link to="/" className="underline" >Debasis</Link> • Created 29 August</div>
                    </div>
                  </div>
                  <div className="common-module-list-row-right flex items-center justify-end">                    
                    <div className="common-module-list-row-created-by bg-[#EBEEF1] rounded-[4px] font-[inter-medium] text-[12px] leading-[20px] text-[#545969] px-[7px] mr-[16px]">
                      Created by me
                    </div>      
                    <div className="workflow-switch node-comm-switch flex items-center justify-center mr-[16px]">
                      <input
                        type="checkbox"
                        id="workflow-switch"
                        className="h-[0] w-[0] invisible"
                      />
                      <label
                        className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative mr-[6px]"
                        htmlFor="workflow-switch"
                      >
                        Toggle
                      </label>
                      <div className="switch-spans flex items-center justify-center">
                        <span className="switch-span switch-span-1 text-[#6C7278] text-right font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]">
                          Inactive
                        </span>
                        <span className="switch-span switch-span-2 text-[#6C7278] text-right font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]">
                          Active
                        </span>
                      </div>
                    </div>              
                    <div className="common-module-list-action-button common-module-list-action-button-group mr-[15px] cursor-pointer h-[24px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[24px] px-[2px] bg-[#EAEAEC]">
                      <div className="common-module-list-action-button-group-block h-[20px] font-[inter-semibold] bg-[#6A737D] text-[12px] px-[12px] rounded-[20px] leading-[20px] text-[#fff] active">Public</div>
                      <div className="common-module-list-action-button-group-block h-[20px] font-[inter-regular] text-[12px] px-[12px] rounded-[20px] leading-[20px] text-[#1A1C1E]">Private</div>                    
                    </div>                  
                    <div className="common-module-list-row-dots-action w-[32px] h-[32px] cursor-pointer">
                      <img src="/images/common-module-dots.svg" alt="common-module-dots" />
                    </div>
                  </div>
                </div>
                <div className="common-module-list-row mb-[12px] border [border-style:solid] [border-color:#DCE4E8] rounded-[12px] px-[24px] py-[18px] flex items-center justify-between">
                  <div className="common-module-list-row-left flex iterms-center justify-start">
                    <div className="common-module-list-row-icon">
                      <img src="../../images/gmail-icon.png" alt="gmail-icon" width={36} />
                    </div>
                    <div className="common-module-list-row-left-wrap ml-[15px]">
                      <div className="common-module-list-row-title font-[inter-bold] text-[14px] leading-[16px] text-[#1A1C1E] mb-[8px]">Add label to message</div>
                      <div className="common-module-list-row-desc font-[inter-medium] text-[12px] leading-[14px] text-[#6C7278]">Last updated 4 hours ago by <Link to="/" className="underline" >Debasis</Link> • Created 29 August</div>
                    </div>
                  </div>
                  <div className="common-module-list-row-right flex items-center justify-end">                    
                    <div className="common-module-list-row-created-by bg-[#EBEEF1] rounded-[4px] font-[inter-medium] text-[12px] leading-[20px] text-[#545969] px-[7px] mr-[16px]">
                      Created by me
                    </div>      
                    <div className="workflow-switch node-comm-switch flex items-center justify-center mr-[16px]">
                      <input
                        type="checkbox"
                        id="workflow-switch"
                        className="h-[0] w-[0] invisible"
                      />
                      <label
                        className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative mr-[6px]"
                        htmlFor="workflow-switch"
                      >
                        Toggle
                      </label>
                      <div className="switch-spans flex items-center justify-center">
                        <span className="switch-span switch-span-1 text-[#6C7278] text-right font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]">
                          Inactive
                        </span>
                        <span className="switch-span switch-span-2 text-[#6C7278] text-right font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]">
                          Active
                        </span>
                      </div>
                    </div>              
                    <div className="common-module-list-action-button common-module-list-action-button-group mr-[15px] cursor-pointer h-[24px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[24px] px-[2px] bg-[#EAEAEC]">
                      <div className="common-module-list-action-button-group-block h-[20px] font-[inter-semibold] bg-[#6A737D] text-[12px] px-[12px] rounded-[20px] leading-[20px] text-[#fff] active">Public</div>
                      <div className="common-module-list-action-button-group-block h-[20px] font-[inter-regular] text-[12px] px-[12px] rounded-[20px] leading-[20px] text-[#1A1C1E]">Private</div>                    
                    </div>                  
                    <div className="common-module-list-row-dots-action w-[32px] h-[32px] cursor-pointer">
                      <img src="/images/common-module-dots.svg" alt="common-module-dots" />
                    </div>
                  </div>
                </div>
                <div className="common-module-list-row mb-[12px] border [border-style:solid] [border-color:#DCE4E8] rounded-[12px] px-[24px] py-[18px] flex items-center justify-between">
                  <div className="common-module-list-row-left flex iterms-center justify-start">
                    <div className="common-module-list-row-icon">
                      <img src="../../images/gmail-icon.png" alt="gmail-icon" width={36} />
                    </div>
                    <div className="common-module-list-row-left-wrap ml-[15px]">
                      <div className="common-module-list-row-title font-[inter-bold] text-[14px] leading-[16px] text-[#1A1C1E] mb-[8px]">Add label to message</div>
                      <div className="common-module-list-row-desc font-[inter-medium] text-[12px] leading-[14px] text-[#6C7278]">Last updated 4 hours ago by <Link to="/" className="underline" >Debasis</Link> • Created 29 August</div>
                    </div>
                  </div>
                  <div className="common-module-list-row-right flex items-center justify-end">                    
                    <div className="common-module-list-row-created-by bg-[#EBEEF1] rounded-[4px] font-[inter-medium] text-[12px] leading-[20px] text-[#545969] px-[7px] mr-[16px]">
                      Created by me
                    </div>      
                    <div className="workflow-switch node-comm-switch flex items-center justify-center mr-[16px]">
                      <input
                        type="checkbox"
                        id="workflow-switch"
                        className="h-[0] w-[0] invisible"
                      />
                      <label
                        className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative mr-[6px]"
                        htmlFor="workflow-switch"
                      >
                        Toggle
                      </label>
                      <div className="switch-spans flex items-center justify-center">
                        <span className="switch-span switch-span-1 text-[#6C7278] text-right font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]">
                          Inactive
                        </span>
                        <span className="switch-span switch-span-2 text-[#6C7278] text-right font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]">
                          Active
                        </span>
                      </div>
                    </div>              
                    <div className="common-module-list-action-button common-module-list-action-button-group mr-[15px] cursor-pointer h-[24px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[24px] px-[2px] bg-[#EAEAEC]">
                      <div className="common-module-list-action-button-group-block h-[20px] font-[inter-semibold] bg-[#6A737D] text-[12px] px-[12px] rounded-[20px] leading-[20px] text-[#fff] active">Public</div>
                      <div className="common-module-list-action-button-group-block h-[20px] font-[inter-regular] text-[12px] px-[12px] rounded-[20px] leading-[20px] text-[#1A1C1E]">Private</div>                    
                    </div>                  
                    <div className="common-module-list-row-dots-action w-[32px] h-[32px] cursor-pointer">
                      <img src="/images/common-module-dots.svg" alt="common-module-dots" />
                    </div>
                  </div>
                </div>                
              </div>
              </div>
            </div>           
          </div>
        </div>       
      </div>
      {isConfirmationPopupOpen && (
        <div className="confirmation-popup  z-[100] fixed top-[0] left-[0] w-full h-full bg-[rgba(0,_0,_0,_0.5)] flex justify-center items-center">
          <div className="w-[350px] bg-[#fff] rounded-[6px] relative">
          <div className="popup-header rounded-tl-[14px] rounded-tr-[14px] bg-[#F5F9FF] px-[29px] flex items-center justify-between h-[57px]">
          <div className="text-[#1C1E21] font-['inter-semibold'] text-[18px] not-italic font-medium leading-[28px] -tracking-[0.34px]">Confirm Action</div>
          <button
            className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50"
            onClick={cancelDelete}
          >
            <img src="../../images/close-popup.svg" alt="popup-close" />
          </button>
          
          </div>
          <div className="p-[30px]">
            <p className="text-[#000] font-['inter-regular'] text-[14px] not-italic font-normal leading-[normal] mb-[20px]">Are you sure you want to delete this item?</p>
            <div className="flex align-start justify-start">
            <button className="min-w-[80px] [transition:0.3s_opacity_ease] cursor-pointer opacity-100 hover:opacity-80 flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#006DE0] text-[#FFF] font-[inter-semibold] text-[14px] not-italic font-semibold leading-[normal] !py-0 px-[15px] rounded-[6px]" onClick={confirmDelete}>Yes</button>
            <button className="ml-[10px] min-w-[80px] [transition:0.3s_all_ease] flex items-center justify-center border-0 h-[40px] [box-shadow:none] border-[1px] border-[solid] bg-transparent hover:bg-[#006DE0] border-[#006DE0] text-[#006DE0] hover:text-[#FFF] font-[inter-regular] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px]" onClick={cancelDelete}>Cancel</button> 
            </div>
            </div>
          </div>
        </div>
      )}
    </CommonLayout>
  );
};
export default CustomNode;
