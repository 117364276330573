import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';  
import DynamicFieldForCommandDelete from './dynamicInputFieldCommand';
import DyanmicValueToSend from './valuesTosendInputs';

const ValueToSendDynamicCommandInput = ({ onDataChanged, setFieldValue, fieldName,inputData,formik }) => {
    const [inputFields, setInputFields] = useState([{ column: '',value: '' }]);
    const [tab, setTab] = useState("fixed");
  
    useEffect(() => {
      if (onDataChanged) {
        validateArrayDetailed(inputFields);
        onDataChanged(inputFields);
        setFieldValue(fieldName, inputFields);
      }
    }, [inputFields]);
  
    const handleAddField = (event) => {
      event.stopPropagation();
      const newFields = [...inputFields, { column: '',value: '' }];
      setInputFields(newFields);
    };
  
    const handleRemoveField = (index) => {
      const newFields = [...inputFields];
      newFields.splice(index, 1);
      setInputFields(newFields);
    };
  
    const handleChangeInput = (index, event,isEditor=false,name='') => {

      if(isEditor){ 
        const newFields = [...inputFields];
        newFields[index][name] = event;
        setInputFields(newFields);
      }else{
        const newFields = [...inputFields];
        newFields[index][event.target.name] = event.target.value;
        setInputFields(newFields);
      }
      /* 
      const newFields = [...inputFields];
      newFields[index][event.target.name] = event.target.value;
      setInputFields(newFields); */
    };
  
    /* const handleDrop = (item, index, targetType) => {
      const newFields = [...inputFields];
      newFields[index][targetType] = `{{ inputData["${item.fieldKey}"] }}`;
      setInputFields(newFields);
      setTab("expression");
    }; */
    
    const handleDrop = (item, index, targetType) => {
      console.log('here i,')
      const newFields = [...inputFields];
      const fieldKey = item.fieldKey;
      const formattedFieldKey =
      fieldKey.includes(" ") || fieldKey.includes("-")
        ? `["${fieldKey}"]`
        : `.${fieldKey}`; 
      const newValue = ` {{ $json${formattedFieldKey} }}`;
      newFields[index][targetType] = newValue
      setInputFields(newFields);
      setTab("expression");
    };
  
    function validateArrayDetailed(arr) {
      for (let i = 0; i < arr.length; i++) {
        const obj = arr[i];
        if (obj.key === '' || obj.value === '' || obj.type === '') {
          console.log(`Validation failed for object at index ${i}:`, obj);
          return false;
        }
      }
      return true;
    }
  
    return (
      <DndProvider backend={HTML5Backend}>
        <div className="midform-draggable-fields_stack">
          <div className="midform-draggable-fields_stack-items">
            {inputFields.map((inputField, index) => (
              <DyanmicValueToSend
                key={index}
                index={index}
                inputField={inputField}
                handleChangeInput={handleChangeInput}
                handleRemoveField={handleRemoveField}
                tab={tab}
                setTab={setTab}
                handleDrop={handleDrop}
                inputFields={inputFields}
                inputData={inputData}
                formik={formik}
              />
            ))}
          </div>
          <div className="midform-draggable-fields_stack-drag-input ml-[30px] rounded-[6px] border border-dashed border-[rgba(0,0,0,0.2)] bg-[#f7f7f7] flex items-center justify-center py-[17px]">
            <div className="midform-draggable-fields_initial-drag-input-action flex items-center justify-center"> 
              <button className="midform-draggable-fields_initial-drag-input-btn text-center opacity-80 text-[#006DE0] font-['inter-regular'] text-[12px] not-italic font-normal leading-[19.2px] hover:underline" onClick={handleAddField}>
                Add Field
              </button>
            </div>
          </div>
        </div>
      </DndProvider>
    );
};

export default ValueToSendDynamicCommandInput;
