import * as React from "react";
import styled, { css } from "styled-components";
import { ClassicScheme, RenderEmit, Presets } from "rete-react-plugin";
import { Drag } from "rete-react-plugin";
import Popup from "reactjs-popup";
import { Schemes } from "../rete/types";
import { JsonView } from "../editor";
import Codenodes from "../customization/codeNodes";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import CodeEditor from "./codeiditor";
import { getInputConnectedNodes, isValidJsonCustom } from "../utils";
import DynamicTable from "../components/Dynamictable";
import RecursiveComponent from "../components/RecursiveComponent";
import { useFormik } from "formik";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
const { RefSocket, RefControl } = Presets.classic;
export const $nodewidth = 200;
export const $socketmargin = 6;
export const $socketsize = 16;

type NodeExtraData = { width?: number; height?: number };
type Props<S extends ClassicScheme> = {
  data: Codenodes & NodeExtraData;
  emit: RenderEmit<S>;
};
export const NodeStyles = styled.div<
  NodeExtraData & { selected: boolean; styles?: (props: any) => any }
>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 2px solid #d2dbe4;
  border-radius: 40px;
  border-radius: 40px;
  cursor: pointer;
  box-sizing: border-box;
  width: ${(props) =>
    Number.isFinite(props.width) ? `${props.width}px` : `${$nodewidth}px`};
  height: ${(props) =>
    Number.isFinite(props.height) ? `${props.height}px` : "auto"};
  padding-bottom: 6px;
  position: relative;
  user-select: none;
  &:focus {
    outline: 8px solid #dfe5ec;
  }
  ${(props) =>
    props.selected &&
    css`
      outline: 8px solid #dfe5ec;
    `}
  .output {
    text-align: right;
  }
  .input {
    text-align: left;
  }
  .output-socket {
    display: block;
    position: absolute;
    right: -24px;
    height: 24px;
    width: 24px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    border-radius: 100%;
    background: #a5adba;
  }
  .input-socket {
    display: block;
    position: absolute;
    left: -13px;
    height: 24px;
    width: 11px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    background: #a5adba;
  }
  .input-title,
  .output-title {
    vertical-align: middle;
    color: red;
    display: none;
    font-family: sans-serif;
    font-size: 14px;
    margin: ${$socketmargin}px;
    line-height: ${$socketsize}px;
  }
  .ant-typography pre {
    color: #fff;
  }
  .input-control {
    z-index: 1;
    width: calc(100% - ${$socketsize + 2 * $socketmargin}px);
    vertical-align: middle;
    display: inline-block;
  }
  .control {
    display: block;
    padding: ${$socketmargin}px ${$socketsize / 2 + $socketmargin}px;
  }
  ${(props) => props.styles && props.styles(props)}
`;

export function CodesNodeUI(props: Props<Schemes>) {
  const { id, width, height } = props.data;
  const [codesData, setCodesData] = React.useState({});
  const selected = props.data.selected || false;
  const inputs = Object.entries(props.data.inputs);
  const outputs = Object.entries(props.data.outputs);
  const [showpopUp, setShowpopUp] = React.useState(false);
  const [inputOption, setInputOption] = React.useState([]);
  const [inputData, setInputData] = React.useState([]);
  const [outPutData, setOutputData] = React.useState([]);
  const [editOutputJson, setEditOutputJson] = React.useState(false);
  const [isValidJson, setIsValidJson] = React.useState(true);
  const [tabIndexOutput, setTabIndexOutput] = React.useState(0);
  React.useEffect(() => {
    setInterval(() => {
      setCodesData(props.data.controls.dataCode.value || {});
    }, 1000);
  }, [props.data.controls.dataCode.value]);
  const closeModal = () => {
    setShowpopUp(false);
    toggleVisibility(false);
  };
  const toggleVisibility = (show) => {
    const actions = document.querySelector(
      ".workflow-view-actions-inn"
    ) as HTMLElement | null;
    const sidebar = document.querySelector(
      ".workflow-sidebar"
    ) as HTMLElement | null;
    if (show) {
      if (actions) {
        actions.style.display = actions.style.display === "none" ? "" : "none";
      }
      if (sidebar) {
        sidebar.style.display = sidebar.style.display === "none" ? "" : "none";
      }
    } else if (!show) {
      if (actions) {
        actions.style.display = "";
      }
      if (sidebar) {
        sidebar.style.display = "";
      }
    }
  };
  const options = [{ value: "Javascript", label: "Javascript" }];
  React.useEffect(() => {
    if (props.data?.editor) {
      let inputOption = getInputConnectedNodes(props.data?.editor);
      console.log(inputOption);
      inputOption = inputOption.map((val) => ({
        value: val.id,
        label: val.label,
      }));
      setInputOption(inputOption);
      const inputs = props.data.dataflow
        .fetchInputs(props.data.id)
        .then((val) => {
          console.log(val);
          if (isValidJsonCustom(val?.input)) {
            setInputData(JSON.parse(val?.input));
            setCodesData(inputData);
          }
        });
    }
  }, [showpopUp]);
  const handleDrop = (item) => {
    console.log("Dropped item:", item);
  };
  const formik = useFormik({
    initialValues: {
      languge: "Javascript",
      code: {},
    },
    onSubmit: async (values) => {
      try {
        console.log(values);
        let execute = executeCode(values.code);
        if (!Array.isArray(execute)) {
          props.data.log("Returned data should be an array.");
          return;
        }
        setOutputData(execute);
        props.data.httpData = JSON.stringify(values);
        //props.data.execute("exec", () => {});
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    },
  });
  const executeCode = (formInputData) => {
    try {
      const globalVarStr = JSON.stringify(inputData);

      const fullCode = `
            const inputdata = ${globalVarStr};
            ${formInputData}
        `;
      const func = new Function(fullCode);

      return func();
    } catch (error) {
      console.log(error);
    }
  };
  function handelSaveOutputJson() {
    if (isValidJson) {
      props.data.httpData = outPutData;
      setEditOutputJson(false);
    }
  }
  function handelJsonedit() {
    setEditOutputJson((prev) => !prev);
    setTabIndexOutput(1);
  }
  const handleJsonChange = (data) => {
    if (data.jsObject) {
      setOutputData(data.jsObject);
      setIsValidJson(true);
    } else {
      // If data.jsObject is undefined, the JSON is invalid
      setIsValidJson(false);
    }
  };
  function HandelOnselectTab(index) {
    setTabIndexOutput(index);
    setEditOutputJson(false);
  }
  const [isLableEdit, setIsLableEdit] = React.useState(false);
  const [label, setLabel] = React.useState(props.data.label);
  function handelLableEdit() {
    setIsLableEdit((prv) => !prv);
  }

  function handelSaveLableEdit() {
    setIsLableEdit(false);
  }
  const handleChangeLable = (e) => {
    setLabel(e.target.value);
    // If you need to update the parent component or props.data, you should call a function passed as a prop
    props.data.label = e.target.value;
  };
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Popup
          open={showpopUp}
          className="code-node-popup"
          onClose={closeModal}
        >
          <div className="modal">
            <div className="node-popup webhook-node-popup fixed top-[70px] left-[0] w-full bg-[#F4F1FA] h-[calc(100%_-_70px)] z-[11]">
              <div className="node-popup-inn">
                <div className="node-popup-back border-b [border-bottom-style:solid] [border-bottom-color:#BECBD8] h-[50px] bg-[#eee] px-[29px] py-[0] flex items-center justify-start">
                  <button
                    onClick={closeModal}
                    className="flex items-center justify-center border-[0] p-0 h-auto rounded-none bg-transparent [box-shadow:none]"
                  >
                    <img
                      src="../../images/node-popup-back.svg"
                      alt="node-popup-back"
                    />
                    <span className="text-[#000] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] ml-[4px]">
                      Back to Canvas
                    </span>
                  </button>
                </div>
                <div className="node-popup-views flex h-[calc(100vh_-_120px)]">
                  <div className="node-popup-view node-popup-input-view [flex-basis:33.33%] w-1/3">
                    <div className="node-popup-output-view-inn px-[30px] py-[26px]">
                      <div className="node-popup-output-tabs">
                        <Tabs>
                          <div className="node-popup-output-tabs-wrap flex items-center justify-between mb-[26px]">
                            <div className="node-popup-output-head flex items-center justify-start">
                              <div className="node-popup-output-title mr-[16px] text-[#006DE0] font-['inter-semibold'] text-[16px] not-italic font-semibold leading-[normal] tracking-[1.92px] uppercase">
                                Input
                              </div>
                              <Select
                                className="midform-select-main"
                                classNamePrefix="midform-select"
                                options={inputOption}
                              />
                            </div>
                            <TabList className="flex items-center justify-end">
                              <div className="node-popup-output-tabs-inn flex items-center justify-end rounded-[4px] bg-[#fff] p-[5px]">
                                <Tab className="[transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                  Table
                                </Tab>
                                <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                  JSON
                                </Tab>
                                <Tab className="ml-[5px] [transition:0.3s_all_ease]cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                  Schema
                                </Tab>
                              </div>
                              {/* <button className="edit-view-btn flex items-center justify-center ml-[8px] rounded-[4px] bg-[#fff] w-[36px] h-[36px]">
                              <img
                                src="../../images/node-popup-edit.svg"
                                alt="node-popup-edit"
                              />
                            </button> */}
                            </TabList>
                          </div>
                          <div className="node-popup-output-tab-panels">
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_235px)]">
                                  {(inputData != null ||
                                    inputData != undefined) && (
                                    <DynamicTable data={inputData} />
                                  )}
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-json-view node-json-view-readonly webhook-node-json-view h-[calc(100vh_-_235px)]">
                              {(inputData != null ||
                                inputData != undefined) && (
                                <JSONInput
                                  id="webhook-json"
                                  placeholder={inputData}
                                  locale={locale}
                                  theme="light_mitsuketa_tribute"
                                  colors={{
                                    default: "#39ADB5",
                                    keys: "#E53935",
                                    string: "#91B859",
                                    number: "#F76D47",
                                    background: "#F7F7F7",
                                  }}
                                  width={"100%"}
                                  height={"100%"}
                                  style={{
                                    outerBox: {
                                      borderRadius: "6px",
                                      border: "1px solid #CCC",
                                      overflow: "hidden",
                                      background: "#F7F7F7",
                                    },
                                  }}
                                />
                              )}
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-schema-view webhook-node-schema-view">
                              <div className="node-schema-tree-view webhook-node-schema-tree-view">
                                {(inputData != null ||
                                  inputData != undefined) &&
                                  inputData.map((item, index) => (
                                    <RecursiveComponent
                                      key={index}
                                      item={item}
                                      onFieldDrop={handleDrop}
                                      draggable={true}
                                    />
                                  ))}
                              </div>
                            </TabPanel>
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                  <div className="node-popup-view node-popup-fields-view [flex-basis:33.33%] w-1/3 bg-[#fff]">
                    <div className="node-popup-midform">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="midform-head p-[22px] pt-[13px]">
                          <div className="midform-head-wrap relative">
                            {isLableEdit && (
                              <div className="edit-comm-name absolute z-[3] top-2/4 left-[200px] w-[200px] -translate-y-1/2 bg-[#fff] rounded-[4px] [box-shadow:0_0_15px_-5px_rgba(0,_0,_0,_0.25)] p-[10px]">
                                <>
                                  <div className="edit-comm-name-title font-[inter-medium] text-[14px] leading-[normal] text-[rgba(0,0,0,0.8)] font-medium mb-[10px]">
                                    Rename node
                                  </div>
                                  <input
                                    type="text"
                                    onChange={handleChangeLable}
                                    value={label}
                                    className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                  />
                                  <div className="flex items-center justify-end mt-[10px]">
                                    <button
                                      className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                      onClick={handelLableEdit}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#006DE0] rounded-[4px]"
                                      onClick={handelSaveLableEdit}
                                    >
                                      Save
                                    </button>
                                  </div>
                                  <span className="edit-comm-name-diamond w-[10px] h-[10px] bg-[#fff] rotate-45 absolute top-[0] bottom-[0] m-auto -left-[5px]"></span>
                                </>
                              </div>
                            )}
                            <div className="midform-head-inn relative flex items-center justify-between px-[19px] py-[15px]">
                              <div className="midform-head-title relative z-[2] flex items-center justify-start">
                                <img
                                  src="../../images/node-popup-code.svg"
                                  alt="node-popup-code"
                                />
                                <span
                                  onClick={handelLableEdit}
                                  className="ml-[8px] text-[#60809F] font-['inter-semibold'] text-[16px] not-italic font-semibold leading-[normal]"
                                  title={props.data.label}
                                >
                                  {props.data.label}
                                </span>
                              </div>
                              <button
                                type="submit"
                                className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 relative z-[1] [box-shadow:none] h-[34px] rounded-[4px] bg-[#006DE0] border-0 !px-[12px] !py-[0] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                              >
                                Test Workflow
                              </button>
                              <div className="midform-head-bg absolute w-full h-full top-[0] left-[0] z-0 bg-[linear-gradient(172deg,_rgba(30,_7,_95,_0.70)_6.32%,_#006DE0_93.51%)] rounded-[10px] opacity-[0.09]"></div>
                            </div>
                          </div>
                        </div>
                        <div className="midform-fields">
                          <div className="midform-field midform-field-select relative mb-[30px] px-[45px]">
                            <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                              Language
                            </label>
                            <Select
                              className="midform-select-main"
                              classNamePrefix="midform-select"
                              options={options}
                              name="languge"
                              value={options.find(
                                (option) => option.value === "Javascript"
                              )}
                            />
                          </div>
                          <div className="midform-field midform-code-editor relative mb-[30px] px-[45px]">
                            <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                              JavaScript
                            </label>
                            <CodeEditor
                              fieldName="code"
                              formik={formik}
                              codeval={inputData}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="node-popup-view node-popup-output-view [flex-basis:33.33%] w-1/3">
                    <div className="node-popup-output-view-inn px-[30px] py-[26px]">
                      <div className="node-popup-output-tabs">
                        <Tabs
                          selectedIndex={tabIndexOutput}
                          onSelect={(index) => HandelOnselectTab(index)}
                        >
                          <div className="node-popup-output-tabs-wrap flex items-center justify-between mb-[26px]">
                            <div className="node-popup-output-title text-[#006DE0] font-['inter-semibold'] text-[16px] not-italic font-semibold leading-[normal] tracking-[1.92px] uppercase">
                              Output
                            </div>
                            <TabList className="flex items-center justify-end">
                              {!editOutputJson ? (
                                <>
                                  <div className="node-popup-output-tabs-inn flex items-center justify-end rounded-[4px] bg-[#fff] p-[5px]">
                                    <Tab className="[transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                      Table
                                    </Tab>
                                    <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                      JSON
                                    </Tab>
                                    <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                      Schema
                                    </Tab>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                    onClick={handelJsonedit}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#006DE0] rounded-[4px]"
                                    onClick={handelSaveOutputJson}
                                    disabled={!isValidJson}
                                  >
                                    Save
                                  </button>
                                </>
                              )}
                              {!editOutputJson && (
                                <button
                                  className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center ml-[8px] rounded-[4px] bg-[#fff] w-[36px] h-[36px]"
                                  onClick={handelJsonedit}
                                >
                                  <img
                                    src="../../images/node-popup-edit.svg"
                                    alt="node-popup-edit"
                                  />
                                </button>
                              )}
                            </TabList>
                          </div>
                          <div className="node-popup-output-tab-panels">
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_235px)]">
                                  {(outPutData != null ||
                                    outPutData != undefined) && (
                                    <DynamicTable data={outPutData} />
                                  )}
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel
                              className={`node-popup-output-tab-panel node-json-view ${
                                editOutputJson ? "" : "node-json-view-readonly"
                              } webhook-node-json-view h-[calc(100vh_-_235px)]`}
                            >
                              <JSONInput
                                id="webhook-json"
                                placeholder={outPutData}
                                locale={locale}
                                theme="light_mitsuketa_tribute"
                                colors={{
                                  default: "#39ADB5",
                                  keys: "#E53935",
                                  string: "#91B859",
                                  number: "#F76D47",
                                  background: "#F7F7F7",
                                }}
                                width={"100%"}
                                height={"100%"}
                                style={{
                                  outerBox: {
                                    borderRadius: "6px",
                                    border: "1px solid #CCC",
                                    overflow: "hidden",
                                    background: "#F7F7F7",
                                  },
                                }}
                                viewOnly={!editOutputJson}
                                onChange={handleJsonChange}
                              />
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-schema-view webhook-node-schema-view">
                              <div className="node-schema-tree-view webhook-node-schema-tree-view">
                                {(outPutData != null ||
                                  outPutData != undefined) &&
                                  outPutData.map((item, index) => (
                                    <RecursiveComponent
                                      key={index}
                                      item={item}
                                      onFieldDrop={handleDrop}
                                      draggable={false}
                                    />
                                  ))}
                              </div>
                            </TabPanel>
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </DndProvider>
      <NodeStyles
        selected={selected}
        width={166}
        height={166}
        className="nodeWrap node-comm node-code"
        data-testid="node"
      >
        <div
          onPointerDown={(e) => {
            e.stopPropagation();
          }}
          onDoubleClick={(e) => {
            setShowpopUp((pop) => !pop);
            toggleVisibility(true);
            e.stopPropagation();
          }}
          className="title"
          data-testid="title"
        >
          {/* {label} */}
          <div className="node-block-parent">
            <div className="node-block-plus-actions flex items-center justify-center absolute left-[185px] top-[0] bottom-[0] m-auto flex items-center justify-start h-[34px] leading-0">
              <div className="node-block-plus-actions-arrow flex items-center justify-center relative">
                <div className="node-block-plus-actions-arrow-line bg-[#919394] h-[2px] w-[105px]"></div>
                <div className="node-block-plus-actions-arrow-text text-[#60809F] text-center font-[inter-regular] text-[14px] not-italic font-normal leading-[normal] bg-[#F2F5F7] absolute left-[0] right-[0] m-auto block w-[40px]">
                  GET
                </div>
              </div>
              <button className="node-block-plus-actions-btn w-[34px] h-[34px]">
                <img
                  src="../../images/plus-icon-node.svg"
                  alt="plus-icon-node"
                />
              </button>
            </div>
            <div className="node-block flex items-center justify-center flex-col">
              <div className="node-block-actions absolute -top-[39px] right-[0] flex items-center justify-end z-[1] opacity-0 scale-0 w-[0] h-[0] [transition:0.3s_opacity_ease] pb-[14px]">
                <button className="cursor-pointer mr-[16px] opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]">
                  <img
                    src="../../images/node-action-power.svg"
                    alt="node-action-power"
                  />
                </button>
                <button className="cursor-pointer mr-[16px] opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]">
                  <img
                    src="../../images/node-action-copy.svg"
                    alt="node-action-copy"
                  />
                </button>
                <button className="cursor-pointer opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]">
                  <img
                    src="../../images/node-action-delete.svg"
                    alt="node-action-delete"
                  />
                </button>
              </div>
              <div className="node-block-icon-parent w-[70px] h-[70px] flex items-end justify-center">
                <img
                  className="node-block-icon"
                  src="../../images/code-node-icon.svg"
                  alt="code-node-icon"
                />
              </div>
              <span
                className="node-block-title mt-[11px] text-[#60809F] text-center font-[inter-medium] text-[16px] not-italic font-medium leading-[normal]"
                title={props.data.label}
              >
                {props.data.label}
              </span>
            </div>
          </div>
        </div>
        {outputs.map(
          ([key, output]) =>
            output && (
              <div className="output" key={key} data-testid={`output-${key}`}>
                <div className="output-title" data-testid="output-title">
                  {output?.label}
                </div>
                <RefSocket
                  name="output-socket"
                  side="output"
                  emit={props.emit}
                  socketKey={key}
                  nodeId={id}
                  payload={output.socket}
                />
              </div>
            )
        )}
        <div>{/* <CodeEditor codeval={codesData} /> */}</div>
        {inputs.map(
          ([key, input]) =>
            input && (
              <div className="input" key={key} data-testid={`input-${key}`}>
                <RefSocket
                  name="input-socket"
                  emit={props.emit}
                  side="input"
                  socketKey={key}
                  nodeId={id}
                  payload={input.socket}
                />
                {input && (!input.control || !input.showControl) && (
                  <div className="input-title" data-testid="input-title">
                    {input?.label}
                  </div>
                )}
                {input?.control && input?.showControl && (
                  <span className="input-control">
                    <RefControl
                      key={key}
                      name="input-control"
                      emit={props.emit}
                      payload={input.control}
                    />
                  </span>
                )}
              </div>
            )
        )}
      </NodeStyles>
    </>
  );
}
