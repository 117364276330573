// src/services/authService.js

import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

// Custom hook to return the auth functions and navigate

export const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
};

export const getToken = () => {
    return localStorage.getItem('token');
};

export const saveToken = (newToken) => {
    localStorage.setItem('token', newToken);
};
export const clearAuthData = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('refreshToken'); // If you are storing the refresh token
};