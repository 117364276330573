import React, { useState, useRef, useEffect } from 'react';
import Editor, { useMonaco } from '@monaco-editor/react';

function handleEditorWillMount(monaco) {
    monaco.languages.typescript.javascriptDefaults.addExtraLib(`
        declare var inputdata: {};
    `, 'filename/fake.d.ts');
}

function CodeEditor({ codeval, fieldName, formik }) {
    const [code, setCode] = useState('// Write your JavaScript code here\n console.log(inputdata);');
    const outputRef = useRef(null);
    const editorRef = useRef(null);
    const containerRef = useRef(null);
    const monaco = useMonaco();

    const handleEditorChange = (value, event) => {
        setCode(value);
        formik.setFieldValue(fieldName, value);
    };

    const handleEditorDidMount = (editor) => {
        editorRef.current = editor;

        const resizeObserver = new ResizeObserver(() => {
            editor.layout();
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    };

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.layout(); // Ensure layout is updated initially
        }
    }, []);

    const executeCode = () => {
        try {
            const globalVarStr = JSON.stringify(codeval);

            const fullCode = `
                const inputdata = ${globalVarStr};
                ${code}
            `;
            const func = new Function(fullCode);
            outputRef.current.textContent = 'Output:\n' + func();
        } catch (error) {
            outputRef.current.textContent = 'Error:\n' + error.toString();
        }
    };

    return (
        <div ref={containerRef}>
            <div className="monaco_editor_parent">
            <Editor
                height="300px"
                defaultLanguage="javascript"
                value={code} // Use value instead of defaultValue to make it controlled
                theme="light"
                beforeMount={handleEditorWillMount}
                onMount={handleEditorDidMount}
                onChange={handleEditorChange}
                options={{
                    minimap: { enabled: false },
                    automaticLayout: true, // Enable automatic layout
                }}
            />
            </div>
            <button className='Run-button' onPointerDown={(e) => e.stopPropagation()}
                onDoubleClick={(e) => e.stopPropagation()} onClick={executeCode}>Run Code</button>
            <pre ref={outputRef} style={{ background: "#eee", padding: "10px" }}></pre>
        </div>
    );
}

export default CodeEditor;
