import * as React from "react";
import styled, { css } from "styled-components";
import { ClassicScheme, RenderEmit, Presets } from "rete-react-plugin";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import { Schemes } from "../rete/types";
import DynamicKeyValueInput from "./dynamicInput";
import Popup from "reactjs-popup";
import { useFormik } from "formik";
import DynamicTable from "../components/Dynamictable";
import {
  getDropValue,
  getInputConnectedNodes,
  isExpression,
  isValidJsonCustom,
} from "../utils";
import JSONInputWrapper from "../components/JSONInputWrapper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import RecursiveComponent from "../components/RecursiveComponent";
import FixedExpressionTabs from "../components/FixedExpressionTabs";
import DroppableSelect from "../components/DroppableSelect";
import axios from "axios";
import {
  copyNodeWithConnections,
  copyNodeWithConnectionsCopy,
  deleteNodes,
} from "../editor";
import MySqlNode from "../customization/mysqlNode";
import MySQLPopup from "../components/mysqlPopup";
import createAPI from "../api";
import CustomSelectSubtitle from "../components/CustomSelectSubtitle";
import AceEditorFormik from "../components/mysql/AceEditorFormik";
import DynamicCommandInput from "../components/CommandInput";
import ValueToSendDynamicCommandInput from "../components/ValueToSendInputCommand";
import DroppableInput from "../components/DroppableInput";
import SortInoutDynamicInput from "../components/sortInputCommand";
import DynamicFormPopup from "../components/DynamicFormPopup";
import { formConfigurations } from "../config/formField";

const { RefSocket, RefControl } = Presets.classic;
export const $nodewidth = 200;
export const $socketmargin = 6;
export const $socketsize = 16;

type NodeExtraData = { width?: number; height?: number };
type Props<S extends ClassicScheme> = {
  data: MySqlNode & NodeExtraData;
  emit: RenderEmit<S>;
};

export const NodeStyles = styled.div<
  NodeExtraData & { selected: boolean; styles?: (props: any) => any }
>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 2px solid #d2dbe4;
  border-radius: 40px;
  cursor: pointer;
  box-sizing: border-box;
  // width: ${(props) =>
    Number.isFinite(props.width) ? `${props.width}px` : `${$nodewidth}px`};
  width: 166px;
  height: 166px;
  padding-bottom: 6px;
  position: relative;
  user-select: none;
  // &:hover {
  //   background: #333;
  // }
  &:focus {
    outline: 8px solid #dfe5ec;
  }
  ${(props) =>
    props.selected &&
    css`
      outline: 8px solid #dfe5ec;
    `}
  .output {
    text-align: right;
  }
  .input {
    text-align: left;
  }
  .output-socket {
    display: block;
    position: absolute;
    right: -24px;
    height: 24px;
    width: 24px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    border-radius: 100%;
    background: #a5adba;
  }
  .input-socket {
    display: block;
    position: absolute;
    left: -13px;
    height: 24px;
    width: 11px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    background: #a5adba;
  }
  .input-title,
  .output-title {
    vertical-align: middle;
    color: white;
    display: none;
    font-family: sans-serif;
    font-size: 14px;
    margin: ${$socketmargin}px;
    line-height: ${$socketsize}px;
  }
  .input-control {
    z-index: 1;
    width: calc(100% - ${$socketsize + 2 * $socketmargin}px);
    vertical-align: middle;
    display: inline-block;
  }
  .sc-jsJBEP.ciTnqI {
    height: auto !important;
    width: 250px !important;
  }
  .control {
    display: block;
    padding: ${$socketmargin}px ${$socketsize / 2 + $socketmargin}px;
  }

  div[title="socket"] {
    display: none;
  }
  ${(props) => props.styles && props.styles(props)}
`;

export function MySqlNodeComponent(props: Props<Schemes>) {
  const API = createAPI();
  const [options, setOptions] = React.useState([
    { value: "create_new", label: "Create New Credential" },
  ]);
  const [tables, setTables] = React.useState([]);
  const [nodeData, setNodeData] = React.useState(null);
  const [isNodeEnable, setIsNodeEnable] = React.useState(true);
  const [outPutData, setOutputData] = React.useState([
    {
      page: 1,
      per_page: 6,
      total: 12,
      total_pages: 2,
      data: [
        {
          id: 1,
          email: "george.bluth@reqres.in",
          first_name: "George",
          last_name: "Bluth",
          avatar: "https://reqres.in/img/faces/1-image.jpg",
        },
        {
          id: 2,
          email: "janet.weaver@reqres.in",
          first_name: "Janet",
          last_name: "Weaver",
          avatar: "https://reqres.in/img/faces/2-image.jpg",
        },
        {
          id: 3,
          email: "emma.wong@reqres.in",
          first_name: "Emma",
          last_name: "Wong",
          avatar: "https://reqres.in/img/faces/3-image.jpg",
        },
        {
          id: 4,
          email: "eve.holt@reqres.in",
          first_name: "Eve",
          last_name: "Holt",
          avatar: "https://reqres.in/img/faces/4-image.jpg",
        },
        {
          id: 5,
          email: "charles.morris@reqres.in",
          first_name: "Charles",
          last_name: "Morris",
          avatar: "https://reqres.in/img/faces/5-image.jpg",
        },
        {
          id: 6,
          email: "tracey.ramos@reqres.in",
          first_name: "Tracey",
          last_name: "Ramos",
          avatar: "https://reqres.in/img/faces/6-image.jpg",
        },
      ],
      support: {
        url: "https://reqres.in/#support-heading",
        text: "To keep ReqRes free, contributions towards server costs are appreciated!",
      },
    },
  ]);
  const [showpopUp, setShowpopUp] = React.useState(false);
  const [inputOption, setInputOption] = React.useState([]);
  const [isValidJson, setIsValidJson] = React.useState(true);
  const [editOutputJson, setEditOutputJson] = React.useState(false);
  const [tabIndexOutput, setTabIndexOutput] = React.useState(0);
  const [inputData, setInputData] = React.useState([
    {
      page: 1,
      per_page: 6,
      total: 12,
      total_pages: 2,
      data: [
        {
          id: 1,
          email: "george.bluth@reqres.in",
          first_name: "George",
          last_name: "Bluth",
          avatar: "https://reqres.in/img/faces/1-image.jpg",
        },
        {
          id: 2,
          email: "janet.weaver@reqres.in",
          first_name: "Janet",
          last_name: "Weaver",
          avatar: "https://reqres.in/img/faces/2-image.jpg",
        },
        {
          id: 3,
          email: "emma.wong@reqres.in",
          first_name: "Emma",
          last_name: "Wong",
          avatar: "https://reqres.in/img/faces/3-image.jpg",
        },
        {
          id: 4,
          email: "eve.holt@reqres.in",
          first_name: "Eve",
          last_name: "Holt",
          avatar: "https://reqres.in/img/faces/4-image.jpg",
        },
        {
          id: 5,
          email: "charles.morris@reqres.in",
          first_name: "Charles",
          last_name: "Morris",
          avatar: "https://reqres.in/img/faces/5-image.jpg",
        },
        {
          id: 6,
          email: "tracey.ramos@reqres.in",
          first_name: "Tracey",
          last_name: "Ramos",
          avatar: "https://reqres.in/img/faces/6-image.jpg",
        },
      ],
      support: {
        url: "https://reqres.in/#support-heading",
        text: "To keep ReqRes free, contributions towards server costs are appreciated!",
      },
    },
  ]);
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const [colunms, setColunms] = React.useState([]);
  const [formConfig, setFormConfig] = React.useState(formConfigurations['mysql']);
  const [buttonText, setButtonText] = React.useState("Submit");
  const selected = props.data.selected || false;
  const inputs = Object.entries(props.data.inputs);
  const outputs = Object.entries(props.data.outputs);
  const controls = Object.entries(props.data.controls);
  const { id, width, height } = props.data;

  const handleExpressionChange = (field, value) => {
    formik.setFieldValue(field, value);
  };

  React.useEffect(() => {
    console.log("Updated outPutData:", outPutData);
  }, [outPutData]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.post("mysql/get-credential",
          {
            type:'mysql'
          },
          {
          headers: {
            "Requires-Auth": true, // This tells the interceptor to add the auth token
          }
        });

        console.log(response.data);

        const transformedOptions = response.data.credentals.map((item) => ({
          value: item.id, // Adjust this based on your data structure
          label: item.name, // Adjust this based on your data structure
        }));
        console.log(transformedOptions);
        setOptions((prevOptions) => [...prevOptions, ...transformedOptions]);
        //setOptions(response.data);
        console.log(options);
      } catch (error) {
        //setError(error);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, []);

  const closeModal = () => {
    setShowpopUp(false);
    toggleVisibility(false);
  };

  const toggleVisibility = (show) => {
    const actions = document.querySelector(
      ".workflow-view-actions-inn"
    ) as HTMLElement | null;
    const sidebar = document.querySelector(
      ".workflow-sidebar"
    ) as HTMLElement | null;
    if (show) {
      if (actions) {
        actions.style.display = actions.style.display === "none" ? "" : "none";
      }
      if (sidebar) {
        sidebar.style.display = sidebar.style.display === "none" ? "" : "none";
      }
    } else if (!show) {
      if (actions) {
        actions.style.display = "";
      }
      if (sidebar) {
        sidebar.style.display = "";
      }
    }
  };

  const handleJsonChange = (data) => {
    if (data.jsObject) {
      setOutputData(data.jsObject);
      setIsValidJson(true);
    } else {
      // If data.jsObject is undefined, the JSON is invalid
      setIsValidJson(false);
    }
  };

  const operation = [
    {
      value: "delete",
      label: "Delete",
      subtitle: "Delete an entire table or rows in a table",
    },
    {
      value: "executeQuery",
      label: "Execute SQL",
      subtitle: "Execute an SQL query",
    },
    {
      value: "insert",
      label: "Insert",
      subtitle: "Insert rows in a table",
    },
    {
      value: "insertOrUpdate",
      label: "Insert or Update",
      subtitle: "Insert or update rows in a table",
    },
    {
      value: "select",
      label: "select",
      subtitle: "Select rows from a table",
    },
    {
      value: "update",
      label: "Update",
      subtitle: "Update rows in a table",
    },
  ];

  const deleteCommand = [
    {
      value: "truncate",
      label: "Truncate",
      subtitle:
        "Only removes the table's data and preserves the table's structure",
    },
    {
      value: "delete",
      label: "Delete",
      subtitle:
        "Delete the rows that match the 'Select Rows' conditions below. If no selection is made, all rows in the table are deleted.",
    },
    {
      value: "drop",
      label: "Drop",
      subtitle:
        "Deletes the table's data and also the table's structure permanently",
    },
  ];

  const deleteCombineConditions = [
    {
      value: "AND",
      label: "AND",
      subtitle: "Only rows that meet all the conditions are selected",
    },
    {
      value: "OR",
      label: "OR",
      subtitle: "Rows that meet at least one condition are selected",
    },
  ];

  React.useEffect(() => {
    console.log(props.data?.editor);
    if (props.data?.editor) {
      let inputOption = getInputConnectedNodes(props.data?.editor);
      inputOption = inputOption.map((val) => ({
        value: val.id,
        label: val.label,
      }));
      setInputOption(inputOption);
      console.log(props.data.dataflow.fetchInputs(props.data.id));
      const inputs = props.data.dataflow
        .fetchInputs(props.data.id)
        .then((val) => {
          console.log(val?.input);
          if (isValidJsonCustom(val?.input)) {
            console.log("valid");
            console.log(val?.input);
            setInputData(JSON.parse(val?.input));
          } else if (typeof val?.input == "object") {
            console.log("here is");
            setInputData(val?.input);
          }
        });
    }
  }, [showpopUp]);

  // Custom validation function
  let errors = {};
  const validateForm = (values) => {
    // Credential validation
    if (!values.credential) {
      errors["credential"] = "Credential is required";
    }

    // Operation validation
    if (!values.operation) {
      errors["operation"] = "Operation is required";
    }

    // Query validation for 'executeQuery' operation
    if (values.operation === "executeQuery") {
      if (!values.query) {
        errors["query"] = "Query is required for Execute SQL operation";
      } else if (values.query.trim() === "") {
        errors["query"] = "Query cannot be empty";
      }
    }

    // Table validation for operations other than 'executeQuery'
    if (values.operation !== "executeQuery") {
      if (!values.table) {
        errors["table"] = "Table is required for this operation";
      }
    }

    // Delete command validation
    if (values.operation === "delete") {
      if (!values.deleteCommand) {
        errors["deleteCommand"] = "Delete command is required";
      }
      if (values.deleteCommand === "delete" && values.deleterows.length === 0) {
        errors["deleterows"] =
          "At least one row selection is required for delete operation";
      }
    }

    // Insert operation validation
    if (values.operation === "insert") {
      if (values.valuestoSend.length === 0) {
        errors["valuestoSend"] = "At least one value is required to insert";
      }
    }

    // Update or InsertOrUpdate operation validation
    if (
      values.operation === "update" ||
      values.operation === "insertOrUpdate"
    ) {
      if (!values.columntoMatchOn) {
        errors["columntoMatchOn"] = "Column to match on is required";
      }
      if (!values.valueofColumntoMatchOn) {
        errors["valueofColumntoMatchOn"] =
          "Value of column to match on is required";
      }
      if (values.valuestoSend.length === 0) {
        errors["valuestoSend"] = "At least one value is required to update";
      }
    }

    // Select operation validation
    if (values.operation === "select" && !values.returnAll) {
      if (!values.limit || isNaN(values.limit) || values.limit <= 0) {
        errors["limit"] = "Valid limit is required";
      }
      if (values.selectRows.length === 0) {
        errors["selectRows"] = "At least one row selection is required";
      }
      if (!values.combineConditions) {
        errors["combineConditions"] = "Combine condition is required";
      }
      if (values.sort.length === 0) {
        errors["sort"] = "At least one sort criteria is required";
      }
    }

    // Column validation
    if (values.columns && values.columns.trim() === "") {
      errors["columns"] = "Columns cannot be empty if specified";
    }

    // Condition validation
    if (values.condition && values.condition.trim() === "") {
      errors["condition"] = "Condition cannot be empty if specified";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      credential: "",
      operation: "executeQuery",
      query: "",
      table: "",
      columns: "",
      values: "",
      condition: "",
      deleteCommand: "",
      deleterows: [],
      deleteCombineConditions: "AND",
      valuestoSend: [],
      returnAll: false,
      limit: 50,
      selectRows: [],
      combineConditions: "AND",
      sort: [],
      columntoMatchOn: "",
      valueofColumntoMatchOn: "",
    },
    validate: validateForm, // Use the custom validation function
    onSubmit: async (values) => {
      const errors = validateForm(values);

      if (Object.keys(errors).length === 0) {
        try {
          console.log(JSON.stringify(values));
          //props.data.validateData(values);
          props.data.controls.dataJson.value = JSON.stringify(values);
          let response = await API.post("mysql/excute", values);
          console.log(response);
          setOutputData(response?.data.results);
          props.data.httpData = JSON.stringify(response?.data.results);
        } catch (error) {
          console.error("Error submitting the form:", error);
        }
      } else {
        formik.setErrors(errors);
      }
    },
  });

  const fetchTableColumnData = async (tableName) => {
    try {
      if (formik.values["credential"] != "") {
        const response = await API.post("mysql/table-column", {
          credential: formik.values["credential"],
          tableName: tableName,
        });

        console.log(response.data);
        setColunms(response.data.results);
      }
    } catch (error) {
      //setError(error);
    } finally {
      // setLoading(false);
    }
  };

  const handleDrop = (item) => {
    console.log("Dropped item:", item);
  };

  function handelJsonedit() {
    setEditOutputJson((prev) => !prev);
    setTabIndexOutput(1);
  }

  function handelSaveOutputJson() {
    if (isValidJson) {
      props.data.httpData = outPutData;
      setEditOutputJson(false);
    }
  }

  function HandelOnselectTab(index) {
    setTabIndexOutput(index);
    setEditOutputJson(false);
  }

  function handelDeleteNode() {
    deleteNodes(props.data.id);
  }

  function handelEnableDisableNode() {
    console.log("enable disable");
    setIsNodeEnable((prv) => !prv);
    props.data.isNodeEnable = isNodeEnable;
  }

  function handelCopyNode() {
    copyNodeWithConnectionsCopy(props.data.id, props.data.editor);
  }

  const [isLableEdit, setIsLableEdit] = React.useState(false);
  const [label, setLabel] = React.useState(props.data.label);
  function handelLableEdit() {
    setIsLableEdit((prv) => !prv);
    setLabel(props.data.label);
  }

  function handelSaveLableEdit() {
    setIsLableEdit(false);
    props.data.label = label;
  }
  const handleChangeLable = (e) => {
    setLabel(e.target.value);
    // If you need to update the parent component or props.data, you should call a function passed as a prop
  };
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  async function getTables() {
    let credential = formik.values["credential"];
    if (credential != "") {
      const responseTable = await API.post("mysql/tables", {
        credential: credential,
      });
      console.log(responseTable.data);
      let tablesOption = responseTable.data.results.map((e) => {
        const value = Object.values(e)[0];
        console.log(e);
        return {
          value: value,
          label: value,
        };
      });
      setTables(tablesOption);
    }
  }
  const handleFormSubmit = async (values) => {
    // API call to submit the form data
    setButtonText("Submiting....");
    await API.post(
      "mysql/create-credential",
      {
        credFields: values,
        name: 'mysql',
        credType: 'mysql',
      },
      {
        headers: {
          "Requires-Auth": true, // This tells the interceptor to add the auth token
        },
      }
    ); 
    setButtonText("Submit"); 
  };
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Popup
          open={showpopUp}
          className="code-node-popup"
          onClose={closeModal}
        >
          <div className="modal">
            <MySQLPopup isOpen={isPopupOpen} onClose={togglePopup} />
            {isPopupOpen && formConfig && (
              <DynamicFormPopup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                config={formConfig}
                onSubmit={handleFormSubmit}
                buttonText={buttonText}
              />
            )}
            <div className="node-popup webhook-node-popup fixed top-[70px] left-[0] w-full bg-[#F4F1FA] h-[calc(100%_-_70px)] z-[11]">
              <div className="node-popup-inn">
                <div className="node-popup-back border-b border-solid border-[#BECBD8] h-[50px] bg-[#eee] px-[29px] py-[0] flex items-center justify-start">
                  <button
                    onClick={closeModal}
                    className="flex items-center justify-center border-0 p-0 h-auto rounded-none bg-transparent [box-shadow:none]"
                  >
                    <img
                      src="../../images/node-popup-back.svg"
                      alt="node-popup-back"
                    />
                    <span className="text-[#000] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] ml-[4px]">
                      Back to Canvas
                    </span>
                  </button>
                </div>
                <div className="node-popup-views flex h-[calc(100vh_-_120px)]">
                  <div className="node-popup-view node-popup-input-view [flex-basis:33.33%] w-1/3">
                    <div className="node-popup-output-view-inn px-[30px] py-[26px]">
                      <div className="node-popup-output-tabs">
                        <Tabs>
                          <div className="node-popup-output-tabs-wrap flex items-center justify-between mb-[26px]">
                            <div className="node-popup-output-head flex items-center justify-start">
                              <div className="node-popup-output-title mr-[16px] text-[#006DE0] font-['inter-semibold'] text-[16px] not-italic font-semibold leading-[normal] tracking-[1.92px] uppercase">
                                Input
                              </div>
                              <Select
                                className="midform-select-main"
                                classNamePrefix="midform-select"
                                options={inputOption}
                                defaultValue={inputOption}
                              />
                            </div>
                            <TabList className="flex items-center justify-end">
                              <div className="node-popup-output-tabs-inn flex items-center justify-end rounded-[4px] bg-[#fff] p-[5px]">
                                <Tab className="[transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                  Table
                                </Tab>
                                <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                  JSON
                                </Tab>
                                <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                  Schema
                                </Tab>
                              </div>
                            </TabList>
                          </div>
                          <div className="node-popup-output-tab-panels">
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_235px)]">
                                  <DynamicTable data={inputData} />
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-json-view node-json-view-readonly webhook-node-json-view h-[calc(100vh_-_235px)]">
                              <JSONInput
                                id="webhook-json"
                                placeholder={inputData}
                                locale={locale}
                                theme="light_mitsuketa_tribute"
                                colors={{
                                  default: "#39ADB5",
                                  keys: "#E53935",
                                  string: "#91B859",
                                  number: "#F76D47",
                                  background: "#F7F7F7",
                                }}
                                width={"100%"}
                                height={"100%"}
                                style={{
                                  outerBox: {
                                    borderRadius: "6px",
                                    border: "1px solid #CCC",
                                    overflow: "hidden",
                                    background: "#F7F7F7",
                                  },
                                }}
                              />
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-schema-view webhook-node-schema-view">
                              <div className="node-schema-tree-view webhook-node-schema-tree-view h-[calc(100vh_-_235px)] overflow-auto">
                                {inputData.map((item, index) => (
                                  <RecursiveComponent
                                    key={index}
                                    item={item}
                                    onFieldDrop={handleDrop}
                                    draggable={true}
                                  />
                                ))}
                              </div>
                            </TabPanel>
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                  <div className="node-popup-view node-popup-fields-view [flex-basis:33.33%] w-1/3 bg-[#fff]">
                    <div className="node-popup-midform">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="midform-head p-[22px] pt-[13px]">
                          <div className="midform-head-wrap relative">
                            <div className="midform-head-inn relative flex items-center justify-between px-[19px] py-[15px]">
                              <div className="midform-head-title relative z-[2] flex items-center justify-start">
                                <img
                                  src="../../images/mysql-inn-icon.svg"
                                  alt="node-popup-edit-http"
                                  width={20}
                                />
                                <span
                                  onClick={handelLableEdit}
                                  className="ml-[8px] text-[#60809F] font-['inter-semibold'] text-[16px] not-italic font-semibold leading-[normal]"
                                  title={props.data.label}
                                >
                                  {props.data.label}
                                </span>
                              </div>
                              <button
                                type="submit"
                                className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 relative z-[1] [box-shadow:none] h-[34px] rounded-[4px] bg-[#006DE0] border-0 !px-[12px] !py-[0] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                              >
                                Test Workflow
                              </button>
                              <div className="midform-head-bg absolute w-full h-full top-[0] left-[0] z-0 bg-[linear-gradient(172deg,_rgba(30,_7,_95,_0.70)_6.32%,_#006DE0_93.51%)] rounded-[10px] opacity-[0.09]"></div>
                            </div>
                          </div>
                        </div>
                        <div className="midform-fields">
                          <div className="midform-field midform-field-has-dots-drop midform-field-select midform-select-subtitle relative mb-[23px] px-[45px] pt-[6px] z-[11]">
                            <div className="midform-field-dots-drop-wrap relative">
                              <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                Credential to connect with
                              </label>
                              <Select
                                className="midform-select-main"
                                classNamePrefix="midform-select"
                                value={options.find(
                                  (option) =>
                                    option.value === formik.values["credential"]
                                )}
                                onChange={(e) => {
                                  formik.setFieldValue("credential", e.value);
                                  if (e.value === "create_new") {
                                    setIsPopupOpen(true);
                                  }
                                }}
                                options={options}
                                name={"credential"}
                                placeholder="Credential to connect with"
                              />
                              {formik.touched.credential &&
                                typeof formik.errors.credential ===
                                  "string" && (
                                  <div className="text-red-600 text-sm">
                                    {formik.errors.credential}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="midform-field midform-field-select midform-select-subtitle relative mb-[23px] px-[45px] z-[10]">
                            <div className="midform-field-dots-drop-wrap relative">
                              <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                Operation
                              </label>
                              <Select
                                className="midform-select-main"
                                classNamePrefix="midform-select"
                                options={operation}
                                name={"operation"}
                                onChange={(e) => {
                                  formik.setFieldValue("operation", e.value);
                                  getTables();
                                }}
                                value={operation.find(
                                  (option) =>
                                    option.value === formik.values["operation"]
                                )}
                                components={{ Option: CustomSelectSubtitle }}
                              />
                              {formik.touched.operation &&
                                typeof formik.errors.operation === "string" && (
                                  <div className="text-red-600 text-sm">
                                    {formik.errors.operation}
                                  </div>
                                )}
                            </div>
                          </div>
                          {formik.values.operation === "executeQuery" && (
                            <div className="midform-field midform-field-select midform-select-subtitle relative mb-[23px] px-[45px]">
                              <div className="midform-field-dots-drop-wrap relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Query
                                </label>
                                <AceEditorFormik
                                  formik={formik}
                                  fieldName="query"
                                />
                                {formik.touched.query &&
                                  typeof formik.errors.query === "string" && (
                                    <div className="text-red-600 text-sm">
                                      {formik.errors.query}
                                    </div>
                                  )}
                              </div>
                            </div>
                          )}
                          {formik.values.operation !== "executeQuery" && (
                            <div className="midform-field midform-field-select midform-select-subtitle relative mb-[23px] px-[45px]">
                              <div className="midform-field-dots-drop-wrap relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Table
                                </label>
                                <Select
                                  className="midform-select-main"
                                  classNamePrefix="midform-select"
                                  options={tables}
                                  name={"table"}
                                  onChange={(e) => {
                                    formik.setFieldValue("table", e.value);
                                    fetchTableColumnData(e.value);
                                  }}
                                  value={tables.find(
                                    (option) =>
                                      option.value === formik.values["table"]
                                  )}
                                />
                                {formik.touched.table &&
                                  typeof formik.errors.table === "string" && (
                                    <div className="text-red-600 text-sm">
                                      {formik.errors.table}
                                    </div>
                                  )}
                              </div>
                            </div>
                          )}
                          {formik.values.operation === "delete" && (
                            <>
                              <div className="midform-field midform-field-select midform-select-subtitle relative mb-[23px] px-[45px]">
                                <div className="midform-field-dots-drop-wrap relative">
                                  <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                    Command
                                  </label>
                                  <Select
                                    className="midform-select-main"
                                    classNamePrefix="midform-select"
                                    options={deleteCommand}
                                    name={"deleteCommand"}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "deleteCommand",
                                        e.value
                                      );
                                      getTables();
                                    }}
                                    value={operation.find(
                                      (option) =>
                                        option.value ===
                                        formik.values["deleteCommand"]
                                    )}
                                    components={{
                                      Option: CustomSelectSubtitle,
                                    }}
                                  />
                                  {formik.touched.deleteCommand &&
                                    typeof formik.errors.deleteCommand ===
                                      "string" && (
                                      <div className="text-red-600 text-sm">
                                        {formik.errors.deleteCommand}
                                      </div>
                                    )}
                                </div>
                              </div>

                              {formik.values.deleteCommand &&
                                formik.values.deleteCommand == "delete" && (
                                  <>
                                    <div className="text-[#000] font-['inter-medium'] text-[12px] not-italic font-medium leading-[normal] opacity-50 px-[45px] pt-[6px]">
                                      Header Parameters
                                    </div>
                                    <div className="px-[45px] pt-[5px] pb-[23px]">
                                      <div className="midform-field-dots-drop-wrap relative">
                                        <DynamicCommandInput
                                          inputData={inputData}
                                          fieldName={"deleterows"}
                                          setFieldValue={formik.setFieldValue}
                                          onDataChanged={(e) => {}}
                                          formik={formik}
                                        />
                                        {formik.touched.deleterows &&
                                          typeof formik.errors.deleterows ===
                                            "string" && (
                                            <div className="text-red-600 text-sm">
                                              {formik.errors.deleterows}
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                    <div className="midform-field midform-field-select midform-select-subtitle relative mb-[23px] px-[45px]">
                                      <div className="midform-field-dots-drop-wrap relative">
                                        <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                          Combine Conditions
                                        </label>
                                        <Select
                                          className="midform-select-main"
                                          classNamePrefix="midform-select"
                                          options={deleteCombineConditions}
                                          name={"deleteCombineConditions"}
                                          onChange={(e) => {
                                            formik.setFieldValue(
                                              "deleteCombineConditions",
                                              e.value
                                            );
                                          }}
                                          value={deleteCombineConditions.find(
                                            (option) =>
                                              option.value ===
                                              formik.values[
                                                "deleteCombineConditions"
                                              ]
                                          )}
                                          components={{
                                            Option: CustomSelectSubtitle,
                                          }}
                                        />
                                        {formik.touched
                                          .deleteCombineConditions &&
                                          typeof formik.errors
                                            .deleteCombineConditions ===
                                            "string" && (
                                            <div className="text-red-600 text-sm">
                                              {
                                                formik.errors
                                                  .deleteCombineConditions
                                              }
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </>
                                )}
                            </>
                          )}
                          {formik.values.operation === "insert" &&
                            formik.values.table !== "" && (
                              <>
                                <div className="text-[#000] font-['inter-medium'] text-[12px] not-italic font-medium leading-[normal] opacity-50 px-[45px] pt-[6px]">
                                  Values to Send
                                </div>
                                <div className="px-[45px] pt-[5px] pb-[23px]">
                                  <div className="midform-field-dots-drop-wrap relative">
                                    <ValueToSendDynamicCommandInput
                                      inputData={inputData}
                                      fieldName={"valuestoSend"}
                                      setFieldValue={formik.setFieldValue}
                                      onDataChanged={(e) => {}}
                                      formik={formik}
                                    />
                                    {formik.touched.valuestoSend &&
                                      typeof formik.errors.valuestoSend ===
                                        "string" && (
                                        <div className="text-red-600 text-sm">
                                          {formik.errors.valuestoSend}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </>
                            )}
                          {formik.values.operation === "select" &&
                            formik.values.table !== "" && (
                              <>
                                <div className="midform-input-fields-switch node-comm-switch relative px-[45px] py-[23px] flex items-center justify-start">
                                  <div className="text-[#000] font-['inter-medium'] text-[12px] not-italic font-medium leading-[normal] opacity-80 mr-[11px]">
                                    Return All
                                  </div>
                                  <div className="node-comm-real-switch relative">
                                    <input
                                      type="checkbox"
                                      id="send-query-parameters-switch"
                                      className="absolute w-full h-full opacity-0 cursor-pointer top-[0] left-[0] z-[1]"
                                      name="returnAll"
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          "returnAll",
                                          !formik.values.returnAll
                                        );
                                      }}
                                    />
                                    <label
                                      className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative"
                                      htmlFor="send-query-parameters-switch"
                                    >
                                      Toggle
                                    </label>
                                  </div>
                                </div>
                                {!formik.values.returnAll && (
                                  <>
                                    <div className="midform-field midform-field-has-dots-drop mb-[23px] px-[45px] pt-[6px]">
                                      <div className="midform-field-dots-drop-wrap relative">
                                        <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                          Limit
                                        </label>
                                        <input
                                          type="text"
                                          className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#30313D] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]"
                                          name={"limit"}
                                          value={formik.values["limit"]}
                                          onChange={formik.handleChange}
                                        />
                                        {formik.touched.limit &&
                                          typeof formik.errors.limit ===
                                            "string" && (
                                            <div className="text-red-600 text-sm">
                                              {formik.errors.limit}
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                    <div className="text-[#000] font-['inter-medium'] text-[12px] not-italic font-medium leading-[normal] opacity-50 px-[45px] pt-[6px]">
                                      Select Rows
                                    </div>
                                    <div className="px-[45px] pt-[5px] pb-[23px]">
                                      <div className="midform-field-dots-drop-wrap relative">
                                        <DynamicCommandInput
                                          inputData={inputData}
                                          fieldName={"selectRows"}
                                          setFieldValue={formik.setFieldValue}
                                          onDataChanged={(e) => {}}
                                          formik={formik}
                                        />
                                        {formik.touched.selectRows &&
                                          typeof formik.errors.selectRows ===
                                            "string" && (
                                            <div className="text-red-600 text-sm">
                                              {formik.errors.selectRows}
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                    <div className="midform-field midform-field-select midform-select-subtitle relative mb-[23px] px-[45px]">
                                      <div className="midform-field-dots-drop-wrap relative">
                                        <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                          Combine Conditions
                                        </label>
                                        <Select
                                          className="midform-select-main"
                                          classNamePrefix="midform-select"
                                          options={deleteCombineConditions}
                                          name={"combineConditions"}
                                          onChange={(e) => {
                                            formik.setFieldValue(
                                              "combineConditions",
                                              e.value
                                            );
                                          }}
                                          value={deleteCombineConditions.find(
                                            (option) =>
                                              option.value ===
                                              formik.values["combineConditions"]
                                          )}
                                          components={{
                                            Option: CustomSelectSubtitle,
                                          }}
                                        />
                                        {formik.touched.combineConditions &&
                                          typeof formik.errors
                                            .combineConditions === "string" && (
                                            <div className="text-red-600 text-sm">
                                              {formik.errors.combineConditions}
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                    <div className="text-[#000] font-['inter-medium'] text-[12px] not-italic font-medium leading-[normal] opacity-50 px-[45px] pt-[6px]">
                                      Sort
                                    </div>
                                    <div className="px-[45px] pt-[5px] pb-[23px]">
                                      <div className="midform-field-dots-drop-wrap relative">
                                        <SortInoutDynamicInput
                                          inputData={inputData}
                                          fieldName={"sort"}
                                          setFieldValue={formik.setFieldValue}
                                          onDataChanged={(e) => {}}
                                          formik={formik}
                                        />
                                        {formik.touched.sort &&
                                          typeof formik.errors.sort ===
                                            "string" && (
                                            <div className="text-red-600 text-sm">
                                              {formik.errors.sort}
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}

                          {(formik.values.operation === "update" ||
                            formik.values.operation == "insertOrUpdate") &&
                            formik.values.table !== "" && (
                              <>
                                <div className="midform-field midform-field-select midform-select-subtitle relative mb-[23px] px-[45px]">
                                  <div className="midform-field-dots-drop-wrap relative">
                                    <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                      Column to Match On
                                    </label>
                                    <Select
                                      className="midform-select-main"
                                      classNamePrefix="midform-select"
                                      options={colunms}
                                      name={"columntoMatchOn"}
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          "columntoMatchOn",
                                          e.value
                                        );
                                      }}
                                      value={colunms.find(
                                        (option) =>
                                          option.value ===
                                          formik.values["columntoMatchOn"]
                                      )}
                                      components={{
                                        Option: CustomSelectSubtitle,
                                      }}
                                    />
                                    {formik.touched.columntoMatchOn &&
                                      typeof formik.errors.columntoMatchOn ===
                                        "string" && (
                                        <div className="text-red-600 text-sm">
                                          {formik.errors.columntoMatchOn}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="midform-field midform-field-has-dots-drop mb-[23px] px-[45px] pt-[6px]">
                                  <div className="midform-field-dots-drop-wrap relative">
                                    <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                      Value of Column to Match On
                                    </label>
                                    <input
                                      type="text"
                                      className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#30313D] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]"
                                      name={"valueofColumntoMatchOn"}
                                      value={
                                        formik.values["valueofColumntoMatchOn"]
                                      }
                                      onChange={formik.handleChange}
                                    />
                                    {formik.touched.valueofColumntoMatchOn &&
                                      typeof formik.errors
                                        .valueofColumntoMatchOn ===
                                        "string" && (
                                        <div className="text-red-600 text-sm">
                                          {formik.errors.valueofColumntoMatchOn}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="text-[#000] font-['inter-medium'] text-[12px] not-italic font-medium leading-[normal] opacity-50 px-[45px] pt-[6px]">
                                  Values to Send
                                </div>
                                <div className="px-[45px] pt-[5px] pb-[23px]">
                                  <div className="midform-field-dots-drop-wrap relative">
                                    <ValueToSendDynamicCommandInput
                                      inputData={inputData}
                                      fieldName={"valuestoSend"}
                                      setFieldValue={formik.setFieldValue}
                                      onDataChanged={(e) => {}}
                                      formik={formik}
                                    />
                                    {formik.touched.valuestoSend &&
                                      typeof formik.errors.valuestoSend ===
                                        "string" && (
                                        <div className="text-red-600 text-sm">
                                          {formik.errors.valuestoSend}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </>
                            )}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="node-popup-view node-popup-output-view [flex-basis:33.33%] w-1/3">
                    <div className="node-popup-output-view-inn px-[30px] py-[26px]">
                      <div className="node-popup-output-tabs">
                        <Tabs
                          selectedIndex={tabIndexOutput}
                          onSelect={(index) => HandelOnselectTab(index)}
                        >
                          <div className="node-popup-output-tabs-wrap flex items-center justify-between mb-[26px]">
                            <div className="node-popup-output-title text-[#006DE0] font-['inter-semibold'] text-[16px] not-italic font-semibold leading-[normal] tracking-[1.92px] uppercase">
                              Output
                            </div>
                            <TabList className="flex items-center justify-end">
                              {!editOutputJson ? (
                                <>
                                  <div className="node-popup-output-tabs-inn flex items-center justify-end rounded-[4px] bg-[#fff] p-[5px]">
                                    <Tab className="[transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                      Table
                                    </Tab>
                                    <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                      JSON
                                    </Tab>
                                    <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                      Schema
                                    </Tab>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                    onClick={handelJsonedit}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#006DE0] rounded-[4px]"
                                    onClick={handelSaveOutputJson}
                                    disabled={!isValidJson}
                                  >
                                    Save
                                  </button>
                                </>
                              )}

                              {!editOutputJson && (
                                <button
                                  className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center ml-[8px] rounded-[4px] bg-[#fff] w-[36px] h-[36px]"
                                  onClick={handelJsonedit}
                                >
                                  <img
                                    src="../../images/node-popup-edit.svg"
                                    alt="node-popup-edit"
                                  />
                                </button>
                              )}
                            </TabList>
                          </div>
                          <div className="node-popup-output-tab-panels">
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_235px)]">
                                  {outPutData != null ? (
                                    <DynamicTable data={outPutData} />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel
                              className={`node-popup-output-tab-panel node-json-view ${
                                editOutputJson ? "" : "node-json-view-readonly"
                              } webhook-node-json-view h-[calc(100vh_-_235px)]`}
                            >
                              <JSONInput
                                id="webhook-json"
                                placeholder={outPutData}
                                locale={locale}
                                theme="light_mitsuketa_tribute"
                                  colors={{
                                  default: "#39ADB5",
                                  keys: "#E53935",
                                  string: "#91B859",
                                  number: "#F76D47",
                                  background: "#F7F7F7",
                                }}
                                width={"100%"}
                                height={"100%"}
                                style={{
                                  outerBox: {
                                    borderRadius: "6px",
                                    border: "1px solid #CCC",
                                    overflow: "hidden",
                                    background: "#F7F7F7",
                                  },
                                }}
                                viewOnly={!editOutputJson}
                                onChange={handleJsonChange}
                              />
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-schema-view webhook-node-schema-view">
                              <div className="node-schema-tree-view webhook-node-schema-tree-view h-[calc(100vh_-_235px)] overflow-auto">
                                {outPutData != null &&
                                  outPutData.map((item, index) => (
                                    <RecursiveComponent
                                      key={index}
                                      item={item}
                                      onFieldDrop={handleDrop}
                                      draggable={false}
                                    />
                                  ))}
                              </div>
                            </TabPanel>
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>
        <NodeStyles
          selected={selected}
          width={166}
          height={166}
          className="nodeWrap node-comm node-http"
          data-testid="node"
        >
          <div
            className="node-block-parent"
            onPointerDown={(e) => {
              e.stopPropagation();
            }}
            onDoubleClick={(e) => {
              setShowpopUp((pop) => !pop);
              toggleVisibility(true);
              e.stopPropagation();
            }}
          >
            <div className="node-block-plus-actions flex items-center justify-center absolute left-[185px] top-[0] bottom-[0] m-auto flex items-center justify-start h-[34px] leading-0">
              <div className="node-block-plus-actions-arrow flex items-center justify-center relative">
                <div className="node-block-plus-actions-arrow-line bg-[#919394] h-[2px] w-[105px]"></div>
                <div className="node-block-plus-actions-arrow-text text-[#60809F] text-center font-[inter-regular] text-[14px] not-italic font-normal leading-[normal] bg-[#F2F5F7] absolute left-[0] right-[0] m-auto block w-[40px]">
                  GET
                </div>
              </div>
              <button className="node-block-plus-actions-btn w-[34px] h-[34px]">
                <img src="../../images/plus-icon-node.svg" alt="plus-icon-node" />
              </button>
            </div>
            <div className="node-block flex items-center justify-center flex-col">
              <div className="node-block-actions absolute -top-[39px] right-[0] flex items-center justify-end z-[1] opacity-0 scale-0 w-[0] h-[0] [transition:0.3s_opacity_ease] pb-[14px]">
                <button
                  onClick={handelEnableDisableNode}
                  className="cursor-pointer mr-[16px] opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]"
                >
                  <img
                    src="../../images/node-action-power.svg"
                    alt="node-action-power"
                  />
                </button>
                <button
                  onClick={handelCopyNode}
                  className="cursor-pointer mr-[16px] opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]"
                >
                  <img
                    src="../../images/node-action-copy.svg"
                    alt="node-action-copy"
                  />
                </button>
                <button
                  onClick={handelDeleteNode}
                  className="cursor-pointer opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]"
                >
                  <img
                    src="../../images/node-action-delete.svg"
                    alt="node-action-delete"
                  />
                </button>
              </div>
              <div className="node-block-icon-parent w-[70px] h-[70px] flex items-end justify-center">
                <img
                  className="node-block-icon"
                  src="../../images/mysql.svg"
                  alt="mysql-node-icon"
                />
              </div>
              <span
                className="node-block-title mt-[11px] text-[#60809F] text-center font-[inter-medium] text-[16px] not-italic font-medium leading-[normal]"
                title={props.data.label}
              >
                {props.data.label}
              </span>
            </div>
          </div>
          <div
            onPointerDown={(e) => {
              e.stopPropagation();
            }}
            onDoubleClick={(e) => e.stopPropagation()}
            className="title hidden"
            data-testid="title"
          >
            {props.data.label}
          </div>
          {outputs.map(
            ([key, output]) =>
              output && (
                <div className="output" key={key} data-testid={`output-${key}`}>
                  <div className="output-title" data-testid="output-title">
                    {output?.label}
                  </div>
                  <RefSocket
                    name="output-socket"
                    side="output"
                    emit={props.emit}
                    socketKey={key}
                    nodeId={id}
                    payload={output.socket}
                  />
                </div>
              )
          )}

          {inputs.map(
            ([key, input]) =>
              input && (
                <div className="input" key={key} data-testid={`input-${key}`}>
                  <RefSocket
                    name="input-socket"
                    emit={props.emit}
                    side="input"
                    socketKey={key}
                    nodeId={id}
                    payload={input.socket}
                  />
                  {input && (!input.control || !input.showControl) && (
                    <div className="input-title" data-testid="input-title">
                      {input?.label}
                    </div>
                  )}
                  {input?.control && input?.showControl && (
                    <span className="input-control">
                      <RefControl
                        key={key}
                        name="input-control"
                        emit={props.emit}
                        payload={input.control}
                      />
                    </span>
                  )}
                </div>
              )
          )}
        </NodeStyles>
      </DndProvider>
    </>
  );
}
