import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom'; 
import createAPI from '../../api';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';

const Reset = () => {
  const [buttonText,setButtonText]=useState('Submit')
  const location = useLocation(); 
  const navigate = useNavigate();
  const API = createAPI();
  // Extract token from URL
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    }),
    onSubmit: async (values) => {
      try {
        setButtonText('Submitting....')
        // Assuming you have an API endpoint to handle password reset
        let val={
          password:values.password,
          token
        }
        const response = await API.post("/auth/reset-password", val);
        toast.success(response.data.serverResponse.message, {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        navigate("/auth/signin/");
      } catch (error) {
        console.error('An error occurred:', error);
        setButtonText('Submit')
        toast.error(
          error.response?.data?.serverResponse?.message ||
            "Failed to send reset password email. Please try again.",
          {
            position: "top-right",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          }
        );
      }
    },
  });
  const [showpopUp, setShowpopUp] = useState(false);
  const closeModal = () => {
      setShowpopUp(false);
      // toggleVisibility(false);
    };
  return (
    <div className="auth-main min-h-screen relative bg-[#F6F8FA]">
      <Popup open={showpopUp} className="global-popup" onClose={closeModal}>
          <div className="global-popup-main w-[520px] bg-[#fff] rounded-[6px] overflow-hidden relative">
            <div className="global-popup-inn">
            <button
                  className="global-popup-head-close [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 absolute top-[35px] right-[35px]"
                  onClick={closeModal}
                >
                  <img src="../../images/close-popup.svg" className="popup-close" />
                </button>
              <div className="global-popup-body p-[30px] text-center pt-[60px]">
                <img src="../../images/success_popup_icon.svg" alt="success_popup_icon" className="success_popup_icon mx-auto mb-[35px]" />
                <div className="success_popup_title text-[#30313D] font-['inter-bold'] text-[18px] leading-[25.2px] mb-[40px]">Your successfully changed <br/>your password</div>
                <button className="global-popup-body-submit w-full px-[18px] py-[0] h-[40px] rounded-[4px] bg-[#006DE0] text-[#FFF] font-['inter-medium'] text-[14px] not-italic font-normal leading-[normal] [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50">
                  Action
                </button>
              </div>
            </div>
          </div>
        </Popup>
      <div className="auth-bg-block bg-[#E8F0FC] w-full h-[390px] absolute left-[0] top-[0] z-[0]"></div>
      <div className="auth-bg-icon auth-bg-icon-left block absolute left-[0] bottom-[0] z-[0]">
        <img src="/images/auth_left_watermark.svg" alt="auth_left_watermark" />
      </div>
      <div className="auth-bg-icon auth-bg-icon-right block absolute right-[0] top-[470px] z-[0]">
        <img src="/images/auth_right_watermark.svg" alt="auth_right_watermark" />
      </div>
      <div className="auth-inn max-w-[552px] mx-auto relative z-[0] pb-[100px]">
        <div className="auth-logo flex items-end justify-center pt-[100px] pb-[90px]">
          <img src="/images/auth_logo.svg" alt="auth_logo" width={52} />
          <span className="ml-[8px] text-[#1A1C1E] font-[plus-jakarta-sans-extrabold] text-[32px] leading-[48px]">WORKFLOW</span>
        </div>
        <div className="auth-msg text-center text-[#30313D] font-[inter-bold] text-[24px] leading-[28px] mb-[35px]">👋 &nbsp;Hey, welcome back!</div>
        <div className="auth-frame bg-[#fff] rounded-[8px] p-[40px]">
          <div className="auth-head mb-[30px]">
            <div className="auth-head-title text-[#30313D] font-[inter-bold] text-[18px] leading-[25px] mb-[5px]">Create new password</div>
            <div className="auth-head-subtitle text-[#595A64] font-[inter-medium] text-[14px] leading-[19.6px] ">Your password must be at least 8 characters long and include one uppercase letter and one number.</div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="midform-field mb-[25px] relative">
              <button className="auth_eye_action absolute right-[12px] top-[32px] cursor-pointer z-0">
                <img src="/images/auth_eye.svg" alt="auth_eye" />
              </button>
              <label className="midform-field-label block text-[#595A64] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                Enter New Password <span className="text-[#df1941]">*</span>
              </label>
              <input
                className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                type="password"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="error text-[11px] text-[red] absolute top-full left-[0]">{formik.errors.password}</div>
              ) : null}
            </div>
            <div className="midform-field mb-[25px] relative">
              <button className="auth_eye_action absolute right-[12px] top-[32px] cursor-pointer z-0">
                <img src="/images/auth_eye.svg" alt="auth_eye" />
              </button>
              <label className="midform-field-label block text-[#595A64] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                Confirm Password <span className="text-[#df1941]">*</span>
              </label>
              <input
                className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                type="password"
                name="confirmPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                <div className="error text-[11px] text-[red] absolute top-full left-[0]">{formik.errors.confirmPassword}</div>
              ) : null}
            </div>
            <button
            onClick={(e) => {
              setShowpopUp((pop) => !pop);
              // toggleVisibility(true);
              e.stopPropagation();
            }}
              type="submit"
              className="mt-[32px] w-full mx-auto [transition:0.3s_opacity_ease] opacity-100 hover:opacity-80 flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#006DE0] text-[#FFF] font-[inter-medium] text-[14px] not-italic leading-[normal] !py-0 px-[15px] rounded-[6px]"
            >
              <span>Create and login</span>
            </button>
          </form>          
        </div>
        <div className="auth_support_text mt-[30px] text-center text-[#595A64] font-[inter-medium] text-[14px] leading-[19.6px]">
          If you encounter any issues while creating your password, feel free to contact our <a href="#" className="text-[#006DE0] underline">customer support team</a>.
        </div>
      </div>
    </div>
  );
};

export default Reset;
