import React, { useEffect, useState, useRef } from 'react';
import { useDrop } from 'react-dnd';
import Editor from '@monaco-editor/react';

const ItemType = {
  FIELD: 'field',
};

const DroppableInput = ({ fieldName, formik, tab, setTab, placeholder = '', inputData }) => {
  const [inputValues, setInputValues] = useState('');
  const [editorValue, setEditorValue] = useState(formik.values[fieldName] || '');
  const editorRef = useRef(null);
  const [editorReady, setEditorReady] = useState(false);
  const [droppedValue, setDroppedValue] = useState(null);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemType.FIELD,
    drop: (item) => handleDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const handleDrop = (item) => {
    setTab('expression');
    const fieldKey = item.fieldKey;
    const formattedFieldKey =
      fieldKey.includes(" ") || fieldKey.includes("-")
        ? `["${fieldKey}"]`
        : `.${fieldKey}`;
    const newValue = ` {{ $json${formattedFieldKey} }}`;
    setDroppedValue(newValue);
  };

  useEffect(() => {
    if (editorReady && droppedValue !== null) {
      const currentEditor = editorRef.current;
      const selection = currentEditor.getSelection();
      currentEditor.executeEdits('', [{
        range: selection,
        text: droppedValue,
        forceMoveMarkers: true
      }]);
      const updatedValue = currentEditor.getValue();
      setEditorValue(updatedValue);
      formik.setFieldValue(fieldName, updatedValue);
      updateInputValues(updatedValue);
      setDroppedValue(null); // Clear the dropped value after processing
    }
  }, [editorReady, droppedValue]); 

  const updateInputValues = (expression) => {
    const regex = /\{\{(.*?)\}\}/g;

    console.log(expression);

    const result = expression.replace(regex, (match, expression) => {
      const modifiedExpression = expression.replace(/\$json/g, "inputData[0]");

      try {
        const result = eval(modifiedExpression);
        console.log("jsdbvjhvbe", result !== expression);
        return result !== undefined && result !== expression ? String(result) : "Invalid syntax";
      } catch (error) {
        console.error(`Error evaluating expression: ${expression}`, error);
        return "Invalid syntax";
      }
    });
    console.log("result>>>>", result);
    setInputValues(result);
  }; 

  useEffect(() => {
    updateInputValues(editorValue);
  }, [editorValue]);

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
    setEditorReady(true);
  }; 
  const handleEditorChange = (value) => {
    setEditorValue(value);
    formik.setFieldValue(fieldName, value);
    updateInputValues(value);
  }; 
  return (
    <div ref={drop} style={{ position: 'relative' }}> 
      {tab === 'expression' ? (
        <div className="monaco_editor_parent">
        <Editor
          height="150px"
          defaultLanguage="javascript"
          value={editorValue}
          theme="light"
          onChange={handleEditorChange}
          onMount={handleEditorDidMount}
          options={{
            automaticLayout: true,
            suggestOnTriggerCharacters: true,
            acceptSuggestionOnEnter: 'on',
            minimap: { enabled: false },
          }}
        />
        </div>
      ) : (
        <input
          type="text"
          className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#30313D] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]"
          name={fieldName}
          placeholder={placeholder}
          value={formik.values[fieldName]}
          onChange={(e) => {
            const value = e.target.value;
            setEditorValue(value);
            formik.handleChange(e);
            updateInputValues(value);
          }}
        />
      )}
      {inputValues && tab === 'expression'  && (
        <div className="post-drag-value pb-[5px] text-[#000] font-[inter-regular] text-[12px] not-italic font-medium leading-[normal] opacity-50 mt-[3px] mb-[3px]" title={inputValues}>
          {inputValues}
        </div>
      )}
      {canDrop && isOver && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            zIndex: 1,
          }}
        />
      )}
    </div>
  );
};

export default DroppableInput;
