import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null);

  const login = (userData, token) => {
    localStorage.setItem('token', token.accessToken);
    localStorage.setItem('user', JSON.stringify(userData)); // Store user data as a JSON string
    localStorage.setItem('refreshToken', token.refreshToken); // Store user data as a JSON string
    setUser(userData);
  };

  const logout = () => {
    clearAuthData(); // Clear all authentication data
  };

  const isAuthenticated = !!user;

  // Function to get the current token from localStorage
  const getToken = () => {
    return localStorage.getItem('token');
  };

  // Function to get the refresh token (this example assumes it's stored in localStorage)
  const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
  };

  // Function to save the new access token
  const saveToken = (newToken) => {
    localStorage.setItem('token', newToken);
  };

  // Function to clear all authentication data (useful for logout or token expiration)
  const clearAuthData = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('refreshToken'); // If you are storing the refresh token
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated, getToken, getRefreshToken, saveToken, clearAuthData }}>
      {children}
    </AuthContext.Provider>
  );
};
