import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import { useDrop } from 'react-dnd';
import Editor from '@monaco-editor/react';

const ItemType = {
  FIELD: 'field',
};

const DroppableSelect = ({ fieldName, formik, tab, setTab, options, components = {}, inputData }) => {
  const [inputValues, setInputValues] = useState('');
  const [editorValue, setEditorValue] = useState(formik.values[fieldName] || '');
  const editorRef = useRef(null);
  const [editorReady, setEditorReady] = useState(false);
  const [droppedValue, setDroppedValue] = useState(null);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemType.FIELD,
    drop: (item) => handleDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const handleDrop = (item) => {
    setTab('expression');
    const fieldKey = item.fieldKey;
    const formattedFieldKey =
      fieldKey.includes(' ') || fieldKey.includes('-') ? `inputData["${fieldKey}"]` : `inputData.${fieldKey}`;
    const newValue = `{{ ${formattedFieldKey} }}`;
    setDroppedValue(newValue);
  };

  useEffect(() => {
    if (editorReady && droppedValue !== null) {
      const currentEditor = editorRef.current;
      const selection = currentEditor.getSelection();
      currentEditor.executeEdits('', [{
        range: selection,
        text: droppedValue,
        forceMoveMarkers: true
      }]);
      const updatedValue = currentEditor.getValue();
      setEditorValue(updatedValue);
      formik.setFieldValue(fieldName, updatedValue);
      updateInputValues(updatedValue);
      setDroppedValue(null); // Clear the dropped value after processing
    }
  }, [editorReady, droppedValue]);

  const extractKeysFromExpression = (expression) => {
    const regex = /{{\s*inputData\["(.*?)"\]\s*}}/g;
    let matches;
    const keys = [];
    while ((matches = regex.exec(expression)) !== null) {
      keys.push(matches[1]);
    }
    return keys;
  };

  const getValueFromKey = (key, data) => {
    if (!key) return '';

    const keys = key.split(/\.|\[|\]/).filter((k) => k); // Handle dot notation and array indices
    let value = data;

    for (const k of keys) {
      if (value[k] !== undefined) {
        value = value[k];
      } else {
        return '';
      }
    }

    return value;
  };

  const updateInputValues = (expression) => {
    const regex = /\{\{(.*?)\}\}/g;

    const result = expression.replace(regex, (match, expression) => {
      const modifiedExpression = expression.replace(/inputData/g, "inputData[0]");

      try {
        const result = eval(modifiedExpression);
        return result !== undefined && result !== expression ? String(result) : "Invalid syntax";
      } catch (error) {
        console.error(`Error evaluating expression: ${expression}`, error);
        return "Invalid syntax";
      }
    });

    setInputValues(result);
  };

  useEffect(() => {
    updateInputValues(editorValue);
  }, [editorValue]);

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
    setEditorReady(true);
  };

  const handleEditorChange = (value) => {
    setEditorValue(value);
    formik.setFieldValue(fieldName, value);
    updateInputValues(value);
  };

  return (
    <div ref={drop} style={{ position: 'relative' }}>
      {tab === 'fixed' && (
        <Select
          className="midform-select-main"
          classNamePrefix="midform-select"
          options={options}
          name={fieldName}
          onChange={(e) => formik.setFieldValue(fieldName, e.value)}
          value={options.find((option) => option.value === formik.values[fieldName])}
          components={components}
        />
      )}
      {tab === 'expression' && (
        <div className="monaco_editor_parent">
        <Editor
          height="150px"
          defaultLanguage="javascript"
          value={editorValue}
          theme="light"
          onChange={handleEditorChange}
          onMount={handleEditorDidMount}
          options={{
            automaticLayout: true,
            suggestOnTriggerCharacters: true,
            acceptSuggestionOnEnter: 'on',
            minimap: { enabled: false },
          }}
        />
        </div>
      )}
      {inputValues && tab === 'expression' && (
        <div className="post-drag-value pb-[5px] text-[#000] font-[inter-regular] text-[12px] not-italic font-medium leading-[normal] opacity-50 mt-[3px] mb-[3px]" title={inputValues}>
          {inputValues}
        </div>
      )}
      {canDrop && isOver && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            zIndex: 1,
          }}
        />
      )}
    </div>
  );
};

export default DroppableSelect;
