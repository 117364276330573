import { ClassicPreset } from "rete";
import { DataflowEngine, DataflowNode } from "rete-engine";
import axios from "axios";
import { Schemes } from "../rete/types";
import { TextSocket } from "../editor";
import { getDropValue, isExpression, isValidJsonCustom } from "../utils";
import { toast } from "react-toastify";

const socket = new ClassicPreset.Socket("socket");
class HttpNode
  extends ClassicPreset.Node<
    {
      input: ClassicPreset.Socket;
    },
    {
      output: ClassicPreset.Socket;
    },
    {
      url: ClassicPreset.InputControl<"text">;
      method: ClassicPreset.InputControl<"text">;
      dataJson: ClassicPreset.InputControl<"text">;
    }
  >
  implements DataflowNode
{
  [x: string]: any;
  width = 600;
  height = 600;
  httpData: any;
  response: any;
  isNodeEnable = true;
  nodeData = {
    imgSrc: "../../images/http-node-icon.svg",
    name: "HTTP Request",
    description: "HTTP Request",
  };
  constructor(
    public dataflow: DataflowEngine<Schemes>,
    public onClick: (jsonData: any) => void,
    private log: (text: string) => void,
    public editor
  ) {
    super("HTTP Request");

    this.addControl(
      "url",
      new ClassicPreset.InputControl("text", {
        initial: "Enter Webhook url",
      })
    );

    this.addControl("dataJson", new ClassicPreset.InputControl("text", {}));

    const control2 = new ClassicPreset.InputControl("text", {
      initial: "GET",
      change(value) {
        console.log(value);
      },
    });

    this.addInput("input", new ClassicPreset.Input(new TextSocket(), "input"));
    this.addControl("method", control2);
    this.addOutput(
      "output",
      new ClassicPreset.Output(new TextSocket(), "Output")
    );
  }
  worker(node: any, inputs: any) {
    console.log("worker", inputs);
    console.log(inputs);
  }
  data(): { output: any } {
    
    return {
      output: this.httpData,
    };
  }

  validateRequestConfig(config) {
    const errors = [];

    // Validate non-empty strings
    ["url", "methord"].forEach((key) => {
      if (typeof config[key] !== "string" || config[key].trim() === "") {
        errors.push(`Invalid or missing '${key}'.`);
      }
    });
    return errors.length > 0 ? errors : null;
  }
  validateData(data: any) {
    const requiredFields = ["methord", "url"];

    // Check if required fields are present and not empty
    for (const field of requiredFields) {
      if (!data[field] || data[field].trim() === "") {
        toast(`Validation Error: ${field} is required and cannot be empty.`);
        //this.log(`Validation Error: ${field} is required and cannot be empty.`);
        return false;
      }
    }

    // Check if query parameters are valid
    if (data.sendQueryParameter && data.queryparameter) {
      for (const param of data.queryparameter) {
        if (!param.key || !param.value) {
          toast(
            "Validation Error: Query parameters must have both key and value."
          );
          //  this.log('Validation Error: Query parameters must have both key and value.');
          return false;
        }
      }
    }

    // Check if headers are valid
    if (data.sendHeaders && data.specifyHeaders) {
      for (const header of data.specifyHeaders) {
        if (!header.key || !header.value) {
          toast("Validation Error: Headers must have both key and value.");
          // this.log('Validation Error: Headers must have both key and value.');
          return false;
        }
      }
    }

    // Method-specific validation
    const method = data.methord.toLowerCase();
    if (method === "post" || method === "put" || method === "patch") {
      if (
        data.sendBody &&
        (!data.body || Object.keys(data.body).length === 0)
      ) {
        toast(
          "Validation Error: Body is required for POST, PUT, and PATCH requests when sendBody is true."
        );
        //  this.log('Validation Error: Body is required for POST, PUT, and PATCH requests when sendBody is true.');
        return false;
      }
    } else if (
      method === "get" ||
      method === "delete" ||
      method === "head" ||
      method === "options"
    ) {
      if (data.sendBody) {
        toast(
          `Warning: ${method.toUpperCase()} requests should not have a body. Ignoring sendBody.`
        );
        //  this.log(`Warning: ${method.toUpperCase()} requests should not have a body. Ignoring sendBody.`);
      }
    }

    return true;
  }

  async execute(
    input: "input",
    forward: (output: "output", data?: any) => void
  ) {
    console.log("Http excute");
    let formData = this.controls.dataJson.value
      ? JSON.parse(this.controls.dataJson.value as string)
      : [];
    console.log("formData>>>>>>>>>>>>>>>>>>>>>>>..");
    console.log(formData);
    let inputData;
    console.log(this.dataflow);
    const inputs = this.dataflow.fetchInputs(this.id).then((val) => {
      console.log(val);
      if (isValidJsonCustom(val?.input)) {
        inputData = JSON.parse(val?.input);
      } else if (typeof val?.input == "object") {
        inputData = val?.input;
      }
    });
    console.log(this.validateData(formData));
    console.log(formData);
    if (this.validateData(formData)) {
      // Configure the request based on the parsed data
      const config = {
        method: isExpression(formData.methord)
          ? getDropValue(formData.methord, inputData)
          : formData.methord,
        url: isExpression(formData.url)
          ? getDropValue(formData.url, inputData)
          : formData.url,
        params: formData.sendQueryParameter
          ? formData.queryparameter.reduce((acc, item) => {
              acc[item.key] = isExpression(item.value)
                ? getDropValue(item.value, inputData)
                : item.value;
              return acc;
            }, {})
          : {},
        headers: formData.sendHeaders
          ? formData.specifyHeaders.reduce((acc, item) => {
              acc[item.key] = isExpression(item.value)
                ? getDropValue(item.value, inputData)
                : item.value;
              return acc;
            }, {})
          : {},
        data:
          formData.sendBody &&
          !["get", "delete", "head", "options"].includes(
            formData.methord.toLowerCase()
          )
            ? formData.body
            : null,
      };

      // Make the Axios API call
      axios(config)
        .then((response) => {
          const data = response;
          this.onClick(data);
          console.log(JSON.stringify(data));
          this.httpData = JSON.stringify(data);
          this.response = data;
          this.editor.emit("update-output-data", this.id, data);
          forward(
            "output",
            new ClassicPreset.Output(new TextSocket(), JSON.stringify(data))
          );
          console.log("Response:", response);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

    try {
    } catch (error) {
      console.error("Error fetching data:", error);
      forward("output", "null"); // Forward null or some error indicator as needed
    }
  }
}

export default HttpNode;
