import React, { useState } from 'react';
import styled, { css } from "styled-components";
import { ClassicScheme, RenderEmit, Presets } from "rete-react-plugin";
import { Drag } from "rete-react-plugin";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import CodeEditor from "./codeiditor";
import Switch from "react-switch";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import HttpNode from "../customization/Httpnode";
import { Schemes } from "../rete/types";
import DynamicKeyValueInput from "./dynamicInput";
import DynamicKeyValueInputPagenation from "./dynamicinputPagination";
import Popup from "reactjs-popup";
import { useFormik } from "formik";
import * as yup from "yup";
import DynamicTable from "../components/Dynamictable";
import {
  getDropValue,
  getInputConnectedNodes,
  isExpression,
  isValidJsonCustom,
} from "../utils";
import JSONInputWrapper from "../components/JSONInputWrapper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import RecursiveComponent from "../components/RecursiveComponent";
import DroppableForm from "../components/DroppableForm";
import DroppableInput from "../components/DroppableInput";
import FixedExpressionTabs from "../components/FixedExpressionTabs";
import DroppableSelect from "../components/DroppableSelect";
import axios from "axios";
import {
  copyNodeWithConnections,
  copyNodeWithConnectionsCopy,
  deleteNodes,
} from "../editor";
import { toast } from "react-toastify";
const { RefSocket, RefControl } = Presets.classic;
export const $nodewidth = 200;
export const $socketmargin = 6;
export const $socketsize = 16;

type NodeExtraData = { width?: number; height?: number };
type Props<S extends ClassicScheme> = {
  data: HttpNode & NodeExtraData;
  emit: RenderEmit<S>;
};
export const NodeStyles = styled.div<
  NodeExtraData & { selected: boolean; styles?: (props: any) => any }
>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 2px solid #d2dbe4;
  border-radius: 40px;
  border-radius: 40px;
  cursor: pointer;
  box-sizing: border-box;
  // width: ${(props) =>
    Number.isFinite(props.width) ? `${props.width}px` : `${$nodewidth}px`};
  width: 166px;
  height: 166px;
  padding-bottom: 6px;
  position: relative;
  user-select: none;
  // &:hover {
  //   background: #333;
  // }
  &:focus {
    outline: 8px solid #dfe5ec;
  }
  ${(props) =>
    props.selected &&
    css`
      outline: 8px solid #dfe5ec;
    `}
  .output {
    text-align: right;
  }
  .input {
    text-align: left;
  }
  .output-socket {
    display: block;
    position: absolute;
    right: -24px;
    height: 24px;
    width: 24px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    border-radius: 100%;
    background: #a5adba;
  }
  .input-socket {
    display: block;
    position: absolute;
    left: -13px;
    height: 24px;
    width: 11px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    background: #a5adba;
  }
  .input-title,
  .output-title {
    vertical-align: middle;
    color: white;
    display: none;
    font-family: sans-serif;
    font-size: 14px;
    margin: ${$socketmargin}px;
    line-height: ${$socketsize}px;
  }
  .input-control {
    z-index: 1;
    width: calc(100% - ${$socketsize + 2 * $socketmargin}px);
    vertical-align: middle;
    display: inline-block;
  }
  .sc-jsJBEP.ciTnqI {
    height: auto !important;
    width: 250px !important;
  }
  .control {
    display: block;
    padding: ${$socketmargin}px ${$socketsize / 2 + $socketmargin}px;
  }

  div[title="socket"] {
    display: none;
  }
  ${(props) => props.styles && props.styles(props)}
`;
export function HttpNodeComponent(props: Props<Schemes>) {
  const [codesData, setCodesData] = React.useState({});
  const [url, setUrl] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [nodeData, setNodeData] = React.useState(null);
  const [isNodeEnable, setIsNodeEnable] = React.useState(true);
  const [outPutData, setOutputData] = React.useState([]);
  const [showpopUp, setShowpopUp] = React.useState(false);
  const [inputOption, setInputOption] = React.useState([]);
  const [isValidJson, setIsValidJson] = React.useState(true);
  const [editOutputJson, setEditOutputJson] = React.useState(false);
  const [tabIndexOutput, setTabIndexOutput] = React.useState(0);
  const [inputData, setInputData] = React.useState([]);

  const selected = props.data.selected || false;
  const inputs = Object.entries(props.data.inputs);
  const outputs = Object.entries(props.data.outputs);
  const controls = Object.entries(props.data.controls);
  const { id, width, height } = props.data;

  React.useEffect(() => {
    console.log("Updated outPutData:", outPutData);
  }, [outPutData]);
  React.useEffect(() => { 
    console.log(props.data.response?.data)
    setOutputData([props.data.response?.data]) 
  }, [props.data.response]);
  function handelInputChange(key, value) {
    /* nodeData[key] = value;
    setNodeData(nodeData);
    props.data.controls.dataJson.value = JSON.stringify(nodeData); */
  }

  const closeModal = () => {
    setShowpopUp(false);
    toggleVisibility(false);
  };

  const toggleVisibility = (show) => {
    const actions = document.querySelector(
      ".workflow-view-actions-inn"
    ) as HTMLElement | null;
    const sidebar = document.querySelector(
      ".workflow-sidebar"
    ) as HTMLElement | null;
    if (show) {
      if (actions) {
        actions.style.display = actions.style.display === "none" ? "" : "none";
      }
      if (sidebar) {
        sidebar.style.display = sidebar.style.display === "none" ? "" : "none";
      }
    } else if (!show) {
      if (actions) {
        actions.style.display = "";
      }
      if (sidebar) {
        sidebar.style.display = "";
      }
    }
  };
  const handleJsonChange = (data) => {
    if (data.jsObject) {
      setOutputData(data.jsObject);
      setIsValidJson(true);
    } else {
      // If data.jsObject is undefined, the JSON is invalid
      setIsValidJson(false);
    }
  };
  const options2 = [
    { value: "delete", label: "DELETE", no: 1 },
    { value: "get", label: "GET", no: 1 },
    { value: "head", label: "HEAD", no: 1 },
    { value: "patch", label: "PATCH", no: 1 },
    { value: "post", label: "POST", no: 1 },
    { value: "put", label: "PUT", no: 1 },
  ];

  const options3 = [
    { value: "Using Fields Below", label: "Using Fields Below", no: 1 },
    { value: "Using JSON", label: "Using JSON", no: 1 },
  ];
 
  React.useEffect(() => {
    console.log(props.data?.editor);
    if (props.data?.editor) {
      let inputOption = getInputConnectedNodes(props.data?.editor);
      inputOption = inputOption.map((val) => ({
        value: val.id,
        label: val.label,
      }));
      setInputOption(inputOption);
      console.log(props.data.dataflow)
      const inputs = props.data.dataflow
        .fetchInputs(props.data.id)
        .then((val) => {
          console.log(val);
          if (isValidJsonCustom(val?.input)) {
            console.log("valid");
            console.log(val?.input);
            console.log(JSON.parse(val?.input));
            setInputData(JSON.parse(val?.input));
          } else if (typeof val?.input == "object") {
            setInputData(val?.input);
          }
        });
    }
  }, [showpopUp]);

  const formik = useFormik({
    initialValues: {
      methord: "get",
      url: "",
      sendQueryParameter: false,
      specifyQueryParameters: "Using Fields Below",
      queryparameter: [],
      specifyBody: "Using Fields Below",
      sendHeaders: false,
      specifyHeadersType: "Using Fields Below",
      specifyHeaders: [],
      sendBody: false,
      body: [],
    },
    onSubmit: async (values) => {
      try {
        console.log(JSON.stringify(values));
        props.data.validateData(values);
        props.data.controls.dataJson.value = JSON.stringify(values);

        if (props.data.validateData(values)) {
          const config = {
            method: isExpression(values.methord)
              ? getDropValue(values.methord, inputData)
              : values.methord,
            url: isExpression(values.url)
              ? getDropValue(values.methord, inputData)
              : values.url,
            params: values.sendQueryParameter
              ? values.queryparameter.reduce((acc, item) => {
                  acc[item.key] = isExpression(item.value)
                    ? getDropValue(item.value, inputData)
                    : item.value;
                  return acc;
                }, {})
              : {},
            headers: values.sendHeaders
              ? values.specifyHeaders.reduce((acc, item) => {
                  acc[item.key] = isExpression(item.value)
                    ? getDropValue(item.value, inputData)
                    : item.value;
                  return acc;
                }, {})
              : {},
            data:
              values.sendBody &&
              !["get", "delete", "head", "options"].includes(
                values.methord.toLowerCase()
              )
                ? values.body.reduce((acc, item) => {
                    acc[item.key] = isExpression(item.value)
                      ? getDropValue(item.value, inputData)
                      : item.value;
                    return acc;
                  }, {})
                : null,
          };

          // Make the Axios API call
          axios(config)
            .then((response) => {
              console.log(response);
              props.data.httpData = JSON.stringify(response.data);
              setOutputData([response.data]); // Ensure only the response data is stored
            })
            .catch((error) => {
              toast("Error submitting the form:", error.message);
              //  console.error("Error:", error);
            });
        }
      } catch (error) {
        toast("Error submitting the form:", error.message);
        console.error("Error submitting the form:", error);
      }
    },
  });
  React.useEffect(() => {
    //setAllFormValues(formik.values); // Assign all form values to a variable
    props.data.controls.dataJson.value=JSON.stringify(formik.values)
  }, [formik.values]);
  const handleDrop = (item) => {
    console.log("Dropped item:", item);
  };

  const handleExpressionChange = (field, value) => {
    formik.setFieldValue(field, value);
  };
  function handelJsonedit() {
    setEditOutputJson((prev) => !prev);
    setTabIndexOutput(1);
  }
  function handelSaveOutputJson() {
    if (isValidJson) {
      props.data.httpData = outPutData;
      setEditOutputJson(false);
    }
  }
  function HandelOnselectTab(index) {
    setTabIndexOutput(index);
    setEditOutputJson(false);
  }
  function handelDeleteNode() {
    deleteNodes(props.data.id);
  }
  function handelEnableDisableNode() {
    console.log("enable disable");
    setIsNodeEnable((prv) => !prv);
    props.data.isNodeEnable = isNodeEnable;
  }
  function handelCopyNode() {
    copyNodeWithConnectionsCopy(props.data.id, props.data.editor);
  }
  const [isLableEdit, setIsLableEdit] = React.useState(false);
  const [label, setLabel] = React.useState(props.data.label);
  function handelLableEdit() {
    setIsLableEdit((prv) => !prv);
    setLabel(props.data.label);
  }

  function handelSaveLableEdit() {
    setIsLableEdit(false);
    props.data.label = label;
  }
  const handleChangeLable = (e) => {
    setLabel(e.target.value);
    // If you need to update the parent component or props.data, you should call a function passed as a prop
  };

  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFile(URL.createObjectURL(file));
    } else {
      setFileName('');
    }
  };

  const handleFileRemove = () => {
    setFileName('');
    setFile(null);
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Popup
          open={showpopUp}
          className="code-node-popup"
          onClose={closeModal}
        >
          <div className="modal">
            <div className="node-popup webhook-node-popup fixed w-[calc(100%_-_80px)] h-[calc(100%_-_125px)] left-[0] right-[0] top-[60px] m-auto bg-[#FFF] z-[11] rounded-[8px]">
              <div className="node-popup-inn">
                <div className="node-popup-actions back node-popup-back border-b [border-bottom-style:solid] [border-bottom-color:#BECBD8] h-[56px] bg-[#2C2D38] flex items-center justify-between rounded-tl-[8px] rounded-tr-[8px]">
                <div className="node-popup-actions-left">
                  <button
                    onClick={closeModal}
                    className="flex items-center justify-center border-[0] p-0 h-auto rounded-none bg-transparent [box-shadow:none]"
                  >
                    <span className="flex h-[56px] items-center justify-center w-[56px] rounded-tl-[8px] bg-[#146DE0]">
                      <img
                        src="../../images/node_inn_back-square.svg"
                        alt="node_inn_back-square"
                      />
                    </span>
                    <span className="text-[#fff] font-['inter-semibold'] text-[16px] not-italic font-normal leading-[24px] ml-[16px]">
                      Back
                    </span>
                  </button>
                  </div>
                  <div className="node-popup-actions-right flex items-center justify-end pr-[16px]">
                  <div className="node-popup-autosave-label text-[#fff] font-['inter-semibold'] text-[12px] not-italic font-semibold leading-[15px] opacity-50 mr-[16px]">Autosaved</div>  
                  <button
                                type="submit"
                                className="flex items-center justify-center [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 relative z-[1] [box-shadow:none] h-[32px] rounded-[4px] bg-[#146DE0] border-0 !px-[12px] !py-[0] text-[#fff] font-[inter-medium] text-[14px] not-italic font-normal leading-[19.6px] mr-[16px]"
                              >
                                <span className="mr-[8px]">Test Step</span>
                                <img src="../../images/node_inn_flask.svg" alt="node_inn_flask" />
                              </button>
                <button>
                  <img src="../../images/node_inn_delete.svg" alt="node_inn_delete" />  
                </button>                             
                              </div>
                </div>
                <div className="node-popup-views flex h-[calc(100vh_-_120px)]">
                  <div className="node-popup-view node-popup-input-view [flex-basis:33.33%] w-1/3">
                    <div className="node-popup-output-view-inn">
                      <div className="node-popup-output-tabs">
                        <Tabs>
                          <div className="node-popup-output-tabs-wrap flex items-center justify-between bg-[#F9FAFB] border-b [border-bottom-style:solid] [border-bottom-color:#E3E3E8] h-[65px] px-[24px]">
                            <div className="node-popup-output-head flex items-center justify-start">
                              <div className="node-popup-output-title mr-[16px] text-[#6C7278] font-['inter-bold'] text-[16px] not-italic font-semibold leading-[28px] tracking-[3px] uppercase">
                                Input
                              </div>
                              {/* <Select
                                className="midform-select-main"
                                classNamePrefix="midform-select"
                                options={inputOption}
                                defaultValue={inputOption}
                              /> */}
                            </div>
                            <TabList className="flex items-center justify-end">
                              <div className="node-popup-output-tabs-inn flex items-center justify-end rounded-[4px] bg-[#fff] p-[5px]">
                                <Tab className="[transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                  Table
                                </Tab>
                                <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                  JSON
                                </Tab>
                                <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                  Schema
                                </Tab>
                              </div>
                            </TabList>
                          </div>
                          <div className="node-popup-output-tab-panels px-[30px] py-[26px]">
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view">
                              { !inputData.length &&(
                                <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_298px)]">
                                  <DynamicTable data={inputData} />
                                  <div className="node_inn_blank_input_state text-center absolute top-2/4 left-[0] right-[0] m-auto -translate-y-1/2">
                                    <div className="node_inn_blank_input_state-title font-['inter-semibold'] text-[16px] leading-[22.4px] text-[#30313D] mb-[16px]">No Input data yet</div>
                                    <button className="h-[36px] rounded-[6px] bg-[#E8F0FC] font-['inter-medium'] text-[14px] leading-[19.6px] text-[#146DE0] px-[20px] mb-[16px]">Execute previous node</button>
                                    <div className="node_inn_blank_input_state-subtitle font-['inter-medium'] text-[14px] leading-[19.6px] text-[#74757D]">(From the earliest node that has no <br/>output data yet)</div>
                                  </div>
                                </div>
                              </div>)}
                              {inputData.length && (
                                <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_235px)]">
                                  <DynamicTable data={inputData} />
                                </div>
                              </div> 
                              )}
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-json-view node-json-view-readonly webhook-node-json-view h-[calc(100vh_-_235px)]">
                              <JSONInput
                                id="webhook-json"
                                placeholder={inputData}
                                locale={locale}
                                theme="light_mitsuketa_tribute"
                                colors={{
                                  default: "#39ADB5",
                                  keys: "#E53935",
                                  string: "#91B859",
                                  number: "#F76D47",
                                  background: "#F7F7F7",
                                }}
                                width={"100%"}
                                height={"100%"}
                                style={{
                                  outerBox: {
                                    borderRadius: "6px",
                                    border: "1px solid #CCC",
                                    overflow: "hidden",
                                    background: "#F7F7F7",
                                  },
                                }}
                              />
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-schema-view webhook-node-schema-view">
                              <div className="node-schema-tree-view webhook-node-schema-tree-view h-[calc(100vh_-_235px)] overflow-auto">
                                {inputData.map((item, index) => (
                                  <RecursiveComponent
                                    key={index}
                                    item={item}
                                    onFieldDrop={handleDrop}
                                    draggable={true}
                                  />
                                ))}
                              </div>
                            </TabPanel>
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                  <div className="node-popup-view node-popup-fields-view [flex-basis:33.33%] w-1/3 relative -top-[56px] has-fixed-express-actions">
                    <div className="node-popup-midform">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="midform-head">
                          <div className="midform-head-wrap relative">
                            {isLableEdit && (
                              <div className="edit-comm-name absolute z-[3] top-2/4 left-[200px] w-[200px] -translate-y-1/2 bg-[#fff] rounded-[4px] [box-shadow:0_0_15px_-5px_rgba(0,_0,_0,_0.25)] p-[10px]">
                                <>
                                  <div className="edit-comm-name-title font-[inter-medium] text-[14px] leading-[normal] text-[rgba(0,0,0,0.8)] font-medium mb-[10px]">
                                    Rename node
                                  </div>
                                  <input
                                    type="text"
                                    onChange={handleChangeLable}
                                    value={label}
                                    className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                  />
                                  <div className="flex items-center justify-end mt-[10px]">
                                    <button
                                      className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                      onClick={handelLableEdit}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#006DE0] rounded-[4px]"
                                      onClick={handelSaveLableEdit}
                                    >
                                      Rename
                                    </button>
                                  </div>
                                  <span className="edit-comm-name-diamond w-[10px] h-[10px] bg-[#fff] rotate-45 absolute top-[0] bottom-[0] m-auto -left-[5px]"></span>
                                </>
                              </div>
                            )}
                            <div className="midform-dots absolute -top-[24px] left-[0] right-[0] m-auto flex items-center justify-center z-[1]">
                              <img
                                  src="../../images/node_inn_dots.svg"
                                  alt="node_inn_dots"
                               />
                            </div>
                            <div className="midform-head-inn relative flex items-center justify-between p-[20px]">
                              <div className="midform-head-title relative z-[2] flex items-center justify-start">
                                <img
                                  src="../../images/https-node-icon.svg"
                                  alt="https-node-icon"
                                />
                                <span
                                  onClick={handelLableEdit}
                                  className="ml-[8px] text-[#30313D] font-['inter-semibold'] text-[20px] not-italic font-semibold leading-[28px]"
                                  title={props.data.label}
                                >
                                  {props.data.label}
                                </span>
                              </div>                              
                              <div className="midform-head-bg absolute w-full h-full top-[0] left-[0] z-0 bg-[#E8F0FC] rounded-tl-[8px] rounded-tr-[8px]"></div>
                            </div>
                          </div>
                        </div>
                        <div className="midform-body rounded-bl-[8px] rounded-br-[8px] bg-[#fff]">
                        <Tabs>
                        <TabList className="flex items-center justify-end">
                              <div className="node-inn-main-acts-tabs flex items-center justify-center w-full bg-[#F2F7FD]">
                                <Tab className="relative text-center w-[33.33%] [transition:0.3s_all_ease] cursor-pointer py-[14px] font-['inter-medium'] text-[16px] not-italic font-normal leading-[22px] text-[#30313D] hover:text-[#146DE0]">
                                  Parameters
                                </Tab>
                                <Tab className="relative text-center w-[33.33%] [transition:0.3s_all_ease] cursor-pointer py-[14px] font-['inter-medium'] text-[16px] not-italic font-normal leading-[22px] text-[#30313D] hover:text-[#146DE0]">
                                  Settings
                                </Tab>
                                <Tab className="relative text-center w-[33.33%] [transition:0.3s_all_ease] cursor-pointer py-[14px] font-['inter-medium'] text-[16px] not-italic font-normal leading-[22px] text-[#30313D] hover:text-[#146DE0]">
                                  Save as...
                                </Tab>
                              </div>
                            </TabList>
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view border-[1px] border-[solid] border-[#DCE4E8] rounded-bl-[8px] rounded-br-[8px]">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full">
                                <div className="midform-fields">
                                  <button className="midform-curl-action bg-[#595A64] rounded-[4px] flex items-center justify-center w-[185px] h-[24px] mb-[20px]">
                                    <img src="../../images/curl_code.svg" alt="curl_code" />
                                    <span className="text-[#FFFFFF] font-[inter-medium] text-[12px] leading-[14.52px] mx-[3px]">Import cURL Command</span>
                                    <img src="../../images/curl_arrow.svg" alt="curl_arrow" />
                                  </button>
                          <div className="midform-field midform-field-has-dots-drop midform-field-select midform-select-subtitle relative mb-[23px] pt-[6px]">
                            <div className="midform-field-dots-drop-wrap relative">
                              <label className="midform-field-label block text-[#74757D] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                Method
                              </label>
                              <FixedExpressionTabs
                                onExpressionChange={(value) =>
                                  handleExpressionChange("methord", value)
                                }
                              >
                                <DroppableSelect
                                  fieldName="methord"
                                  formik={formik}
                                  options={options2}
                                  tab={undefined}
                                  setTab={undefined}
                                  inputData={inputData}
                                />
                              </FixedExpressionTabs>
                            </div>
                          </div>
                          <div className="midform-field midform-field-has-dots-drop mb-[23px] pt-[6px]">
                            <div className="midform-field-dots-drop-wrap relative">
                              <label className="midform-field-label block text-[#74757D] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                URL
                              </label>
                              <FixedExpressionTabs
                                onExpressionChange={(value) =>
                                  handleExpressionChange("url", value)
                                }
                              >
                                <DroppableInput
                                  fieldName="url"
                                  formik={formik}
                                  inputData={inputData}
                                  tab={undefined}
                                  setTab={undefined}
                                />
                              </FixedExpressionTabs>
                            </div>
                          </div>
                          <div className="midform-field border-t [border-top-style:dashed] [border-top-color:#DCE4E8]">
                            <div className="midform-input-fields-switch node-comm-switch relative py-[15px] flex items-center justify-start">
                              <div className="text-[#1A1C1E] font-['plus-jakarta-sans-bold'] text-[16px] not-italic font-medium leading-[22.4px] mr-[8px]">
                                Send Query Parameters
                              </div>
                              <div className="node-comm-real-switch relative">
                                <input
                                  type="checkbox"
                                  id="send-query-parameters-switch"
                                  className="absolute w-full h-full opacity-0 cursor-pointer top-[0] left-[0] z-[1]"
                                  name="sendQueryParameter"
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "sendQueryParameter",
                                      !formik.values.sendQueryParameter
                                    );
                                  }}
                                />
                                <label
                                  className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative"
                                  htmlFor="send-query-parameters-switch"
                                >
                                  Toggle
                                </label>
                              </div>
                            </div>
                            {formik.values.sendQueryParameter && (
                              <>
                                <div className="midform-nested-fields">
                                  <div className="midform-field midform-field-has-dots-drop midform-nested-field midform-field-select midform-select-subtitle relative mb-[23px]  pt-[6px]">
                                    <div className="midform-field-dots-drop-wrap relative">
                                      <label className="midform-field-label block text-[#74757D] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                        Specify Query Parameters
                                      </label>
                                      <FixedExpressionTabs
                                        onExpressionChange={(value) =>
                                          handleExpressionChange(
                                            "specifyQueryParameters",
                                            !formik.values
                                              .specifyQueryParameters
                                          )
                                        }
                                      >
                                        <DroppableSelect
                                          fieldName="specifyQueryParameters"
                                          formik={formik}
                                          options={options3}
                                          tab={undefined}
                                          setTab={undefined}
                                          inputData={inputData}
                                        />
                                      </FixedExpressionTabs>
                                    </div>
                                  </div>
                                  {formik.values.specifyQueryParameters ==
                                    "Using JSON" && (
                                    <div className="midform-field midform-nested-field midform-code-editor relative mb-[30px] ">
                                      <label className="midform-field-label block text-[#74757D] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                        JSON
                                      </label>
                                      <JSONInputWrapper
                                        fieldName={"queryparameter"}
                                        setFieldValue={formik.setFieldValue}
                                        jsonData={formik.values.queryparameter}
                                      />
                                    </div>
                                  )}
                                  {formik.values.specifyQueryParameters ==
                                    "Using Fields Below" && (
                                    <>
                                      <div className="text-[#000] font-['inter-medium'] text-[12px] not-italic font-medium leading-[normal] opacity-50 mr-[11px] ">
                                        Query Parameters
                                      </div>
                                      <div className="monaco_editor_width88  pt-[5px] pb-[23px]">
                                        <div className="midform-field-dots-drop-wrap relative">
                                          <DynamicKeyValueInput
                                            fieldName={"queryparameter"}
                                            inputData={inputData}
                                            setFieldValue={formik.setFieldValue}
                                            onDataChanged={(e) =>
                                              handelInputChange(
                                                "headerFields",
                                                e
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          <div className="midform-field border-t [border-top-style:dashed] [border-top-color:#DCE4E8]">
                            <div className="midform-input-fields-switch node-comm-switch relative py-[15px] flex items-center justify-start">
                              <div className="text-[#1A1C1E] font-['plus-jakarta-sans-bold'] text-[16px] not-italic font-medium leading-[22.4px] mr-[8px]">
                                Send Headers
                              </div>
                              <div className="node-comm-real-switch relative">
                                <input
                                  type="checkbox"
                                  id="send-headers-switch"
                                  className="absolute w-full h-full opacity-0 cursor-pointer top-[0] left-[0] z-[1]"
                                  name="sendHeaders"
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "sendHeaders",
                                      !formik.values.sendHeaders
                                    );
                                  }}
                                />
                                <label
                                  className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative"
                                  htmlFor="send-headers-switch"
                                >
                                  Toggle
                                </label>
                              </div>
                            </div>
                            {formik.values.sendHeaders && (
                              <div className="midform-field-dots-drop-wrap relative">
                                <div className="mb-[23px] ">
                                  <label className="midform-field-label block text-[#74757D] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                    Specify Header Parameters
                                  </label>
                                  <FixedExpressionTabs
                                    onExpressionChange={(value) =>
                                      handleExpressionChange(
                                        "specifyHeadersType",
                                        !formik.values.specifyHeadersType
                                      )
                                    }
                                  >
                                    <DroppableSelect
                                      fieldName="specifyHeadersType"
                                      formik={formik}
                                      options={options3}
                                      tab={undefined}
                                      setTab={undefined}
                                      inputData={inputData}
                                    />
                                  </FixedExpressionTabs>
                                </div>
                              </div>
                            )}
                            {formik.values.sendHeaders &&
                              formik.values.specifyHeadersType ==
                                "Using Fields Below" && (
                                <>
                                  <div className="text-[#000] font-['inter-medium'] text-[12px] not-italic font-medium leading-[normal] opacity-50  pt-[6px]">
                                    Header Parameters
                                  </div>
                                  <div className="monaco_editor_width88  pt-[5px] pb-[23px]">
                                    <div className="midform-field-dots-drop-wrap relative">
                                      <DynamicKeyValueInput
                                        inputData={inputData}
                                        fieldName={"specifyHeaders"}
                                        setFieldValue={formik.setFieldValue}
                                        onDataChanged={(e) =>
                                          handelInputChange("specifyHeaders", e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            {formik.values.sendHeaders &&
                              formik.values.specifyHeadersType ==
                                "Using JSON" && (
                                <>
                                  <div className="midform-field-label block text-[#74757D] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px] ">
                                    Header Parameters
                                  </div>
                                  <div className=" pb-[23px]">
                                    <JSONInputWrapper
                                      fieldName={"specifyHeaders"}
                                      setFieldValue={formik.setFieldValue}
                                      jsonData={formik.values.specifyHeaders}
                                    />
                                  </div>
                                </>
                              )}
                          </div>
                          <div className="midform-field border-t [border-top-style:dashed] [border-top-color:#DCE4E8]">
                            <div className="midform-input-fields-switch node-comm-switch relative py-[15px] flex items-center justify-start">
                              <div className="text-[#1A1C1E] font-['plus-jakarta-sans-bold'] text-[16px] not-italic font-medium leading-[22.4px] mr-[8px]">
                                Send Body
                              </div>

                              <div className="node-comm-real-switch relative">
                                <input
                                  type="checkbox"
                                  id="send-body-switch"
                                  className="absolute w-full h-full opacity-0 cursor-pointer top-[0] left-[0] z-[1]"
                                  name="sendBody"
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "sendBody",
                                      !formik.values.sendBody
                                    );
                                  }}
                                />
                                <label
                                  className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative"
                                  htmlFor="send-body-switch"
                                >
                                  Toggle
                                </label>
                              </div>
                            </div>
                            {formik.values.sendBody && (
                              <div className="midform-field-dots-drop-wrap relative">
                                <div className="mb-[23px] ">
                                  <label className="midform-field-label block text-[#74757D] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                    Specify Body Parameters
                                  </label>
                                  <FixedExpressionTabs
                                    onExpressionChange={(value) =>
                                      handleExpressionChange(
                                        "specifyBody",
                                        !formik.values.specifyBody
                                      )
                                    }
                                  >
                                    <DroppableSelect
                                      fieldName="specifyBody"
                                      formik={formik}
                                      options={options3}
                                      tab={undefined}
                                      inputData={inputData}
                                      setTab={undefined}
                                    />
                                  </FixedExpressionTabs>
                                </div>
                              </div>
                            )}
                            {formik.values.sendBody &&
                              formik.values.specifyBody == "Using JSON" && (
                                <>
                                  <div className="midform-field-label block text-[#74757D] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px] ">
                                    Body Parameters
                                  </div>
                                  <div className=" pb-[23px]">
                                    <JSONInputWrapper
                                      fieldName={"body"}
                                      setFieldValue={formik.setFieldValue}
                                      jsonData={formik.values.body}
                                    />
                                  </div>
                                </>
                              )}
                            {formik.values.sendBody &&
                              formik.values.specifyBody ==
                                "Using Fields Below" && (
                                <>
                                  <div className="text-[#000] font-['inter-medium'] text-[12px] not-italic font-medium leading-[normal] opacity-50 mr-[11px] ">
                                    Query Parameters
                                  </div>
                                  <div className="monaco_editor_width88  pt-[5px] pb-[23px]">
                                    <div className="midform-field-dots-drop-wrap relative">
                                      <DynamicKeyValueInput
                                        fieldName={"body"}
                                        inputData={inputData}
                                        setFieldValue={formik.setFieldValue}
                                        onDataChanged={(e) =>
                                          handelInputChange("body", e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                          </div>
                        </div>
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view border-[1px] border-[solid] border-[#DCE4E8] rounded-bl-[8px] rounded-br-[8px]">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full">
                                  Settings options goes here
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view border-[1px] border-[solid] border-[#DCE4E8] rounded-bl-[8px] rounded-br-[8px] h-[calc(100vh_-_231px)]">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full">
                                  <div className="save-as-form p-[22px]">
                                    <div className="save-as-form-field mb-[15px]">
                                      <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">Name of node</label>
                                      <input className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#30313D] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]" />            
                                    </div>
                                    <div className="midform-field midform-field-select relative mb-[15px]">
                                      <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">Select Category</label>
                                      <Select
                                        className="midform-select-main"
                                        classNamePrefix="midform-select"              
                                        placeholder="Select Category"         
                                        isSearchable={false}                   
                                      />          
                                    </div>
                                    <div className="midform-field relative mb-[15px]">                                      
                                      <div className="mb-[4px] flex items-center justify-between">
                                        <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px]">Description</label>
                                        <span className="save-as-form-textarea-count text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px]">0/100</span>
                                      </div>
                                      <textarea className="w-full p-[16px] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#020305] font-[inter-regular] text-[14px] not-italic font-normal leading-[19.6px]" value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                                    </div>
                                    <div className="midform-field midform-field-select relative mb-[15px]">
                                      <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">Node Icon</label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
                                      <input
                                        id="fileInput"
                                        type="file"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                      />
                                      {file ? (
                                        <img
                                          src={file}
                                          alt="File Icon"
                                          style={{ width: 24, height: 24, marginRight: 8 }}
                                        />
                                      ) : (
                                        <span style={{ display: 'inline-block', width: 24, height: 24, backgroundColor: '#eee', marginRight: 8 }}></span>
                                      )}
                                    </label>
                                    <div>
                                      {fileName || 'No file chosen'}
                                    </div>
                                    {fileName && (
                                      <button
                                        onClick={handleFileRemove}
                                        style={{
                                          marginLeft: 8,
                                          border: 'none',
                                          background: 'transparent',
                                          cursor: 'pointer',
                                          color: '#555',
                                        }}
                                      >
                                        <img src="../../images/delete_for_icon.svg" alt="delete_for_icon" />
                                      </button>
                                    )}
                                  </div>
                                  </div>
                                  </div>
                                  <div className="hidden save-as-node-error-state text-center absolute top-2/4 left-[0] right-[0] m-auto -translate-y-1/2">
                                    <img src="../../images/node_error_icon.svg" alt="node_error_icon" className="mx-auto" />
                                    <div className="save-as-node-error-state-title text-[#1A1C1E] font-[inter-semibold] text-[20px] leading-[28px] mt-[12px]">Oops!</div>
                                    <div className="save-as-node-error-state-subtitle">
                                      <p className="text-[#74757D] font-[inter-medium] text-[14px] leading-[19.6px] mt-[10px]">The configuration for this HTTP node does not <br/>seem to be correct.</p>
                                      <p className="text-[#74757D] font-[inter-medium] text-[14px] leading-[19.6px] mt-[10px]">It is recommended that you have positively tested <br/>the node before saving it as a template.</p>
                                    </div>
                                    <div className="save-as-node-error-state-actions">
                                      <button className="rounded-[6px] block w-full h-[36px] max-w-[268px] bg-[#146DE0] text-[#ffffff] font-[inter-medium] text-[14px] leading-[19.6px] mx-[auto] mt-[35px]">Continue to configure</button>
                                      <button className="rounded-[6px] block w-full h-[36px] max-w-[268px] bg-[#E8F0FC] text-[#146DE0] font-[inter-medium] text-[14px] leading-[19.6px] mx-[auto] mt-[12px]">Ignore, Save anyway!</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPanel>
                            </Tabs>                   
                        </div>     
                      </form>
                    </div>
                  </div>
                  <div className="node-popup-view node-popup-output-view [flex-basis:33.33%] w-1/3">
                    <div className="node-popup-output-view-inn">
                      <div className="node-popup-output-tabs">
                        <Tabs
                          selectedIndex={tabIndexOutput}
                          onSelect={(index) => HandelOnselectTab(index)}
                        >
                          <div className="node-popup-output-tabs-wrap flex items-center justify-between bg-[#F9FAFB] border-b [border-bottom-style:solid] [border-bottom-color:#E3E3E8] h-[65px] px-[24px]">
                            <div className="node-popup-output-title text-[#6C7278] font-['inter-bold'] text-[16px] not-italic font-semibold leading-[28px] tracking-[3px] uppercase">                            
                              Output
                            </div>
                            <TabList className="flex items-center justify-end">
                              {!editOutputJson ? (
                                <>
                                  <div className="node-popup-output-tabs-inn flex items-center justify-end rounded-[4px] bg-[#fff] p-[5px]">
                                    <Tab className="[transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                      Table
                                    </Tab>
                                    <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                      JSON
                                    </Tab>
                                    <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                      Schema
                                    </Tab>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                    onClick={handelJsonedit}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#1E075F] rounded-[4px]"
                                    onClick={handelSaveOutputJson}
                                    disabled={!isValidJson}
                                  >
                                    Save
                                  </button>
                                </>
                              )}

                              {!editOutputJson && (
                                <button
                                  className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center ml-[8px] rounded-[4px] bg-[#fff] w-[36px] h-[40px]"
                                  onClick={handelJsonedit}
                                >
                                  <img
                                    src="../../images/node-popup-edit.svg"
                                    alt="node-popup-edit"
                                  />
                                </button>
                              )}
                            </TabList>
                            <button
                                  className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center ml-[8px] rounded-[4px] bg-[#fff] w-[36px] h-[40px]"                                  
                                >
                                  <img
                                    src="../../images/node_inn_edit.svg"
                                    alt="node_inn_edit"
                                  />
                                </button>
                          </div>
                          <div className="node-popup-output-tab-panels px-[30px] py-[26px]">
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_298px)]">
                                  {outPutData != null ||
                                  outPutData != undefined ? (
                                    <DynamicTable data={outPutData} />
                                  ) : (
                                    ""
                                  )}
                                  { outPutData == null ||
                                  outPutData == undefined &&(<div className="node_inn_blank_output_state text-center absolute top-2/4 left-[0] right-[0] m-auto -translate-y-1/2">
                                    <div className="node_inn_blank_output_state-title font-['inter-medium'] text-[16px] leading-[22.4px] text-[#30313D]">Execute this node to view <br/>data or <button className="font-['inter-semibold'] text-[#146DE0] underline">Set mock data</button></div>                                    
                                  </div>)}
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel
                              className={`node-popup-output-tab-panel node-json-view ${
                                editOutputJson ? "" : "node-json-view-readonly"
                              } webhook-node-json-view h-[calc(100vh_-_235px)]`}
                            >
                              <JSONInput
                                id="webhook-json"
                                placeholder={outPutData}
                                locale={locale}
                                theme="light_mitsuketa_tribute"
                                colors={{
                                  default: "#39ADB5",
                                  keys: "#E53935",
                                  string: "#91B859",
                                  number: "#F76D47",
                                  background: "#F7F7F7",
                                }}
                                width={"100%"}
                                height={"100%"}
                                style={{
                                  outerBox: {
                                    borderRadius: "6px",
                                    border: "1px solid #CCC",
                                    overflow: "hidden",
                                    background: "#F7F7F7",
                                  },
                                }}
                                viewOnly={!editOutputJson}
                                onChange={handleJsonChange}
                              />
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-schema-view webhook-node-schema-view">
                              <div className="node-schema-tree-view webhook-node-schema-tree-view h-[calc(100vh_-_235px)] overflow-auto">
                                {outPutData != null || outPutData != undefined
                                  ? outPutData.map((item, index) => (
                                      <RecursiveComponent
                                        key={index}
                                        item={item}
                                        onFieldDrop={handleDrop}
                                        draggable={false}
                                      />
                                    ))
                                  : ""}
                              </div>
                            </TabPanel>
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>
        <NodeStyles
          selected={selected}
          width={166}
          height={166}
          className="nodeWrap node-comm node-http"
          data-testid="node"
        >
          <div
            className="node-block-parent"
            onPointerDown={(e) => {
              e.stopPropagation();
            }}
            onDoubleClick={(e) => {
              setShowpopUp((pop) => !pop);
              toggleVisibility(true);
              e.stopPropagation();
            }}
          >
            <div className="node-block-plus-actions flex items-center justify-center absolute left-[185px] top-[0] bottom-[0] m-auto flex items-center justify-start h-[34px] leading-0">
              <div className="node-block-plus-actions-arrow flex items-center justify-center relative">
                <div className="node-block-plus-actions-arrow-line bg-[#919394] h-[2px] w-[105px]"></div>
                <div className="node-block-plus-actions-arrow-text text-[#60809F] text-center font-[inter-regular] text-[14px] not-italic font-normal leading-[normal] bg-[#F2F5F7] absolute left-[0] right-[0] m-auto block w-[40px]">
                  GET
                </div>
              </div>
              <button className="node-block-plus-actions-btn w-[34px] h-[34px]">
                <img
                  src="../../images/plus-icon-node.svg"
                  alt="plus-icon-node"
                />
              </button>
            </div>
            <div className="node-block flex items-center justify-center flex-col">
              <div className="node-block-actions absolute -top-[39px] right-[0] flex items-center justify-end z-[1] opacity-0 scale-0 w-[0] h-[0] [transition:0.3s_opacity_ease] pb-[14px]">
                <button
                  onClick={handelEnableDisableNode}
                  className="cursor-pointer mr-[16px] opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]"
                >
                  <img
                    src="../../images/node-action-power.svg"
                    alt="node-action-power"
                  />
                </button>
                <button
                  onClick={handelCopyNode}
                  className="cursor-pointer mr-[16px] opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]"
                >
                  <img
                    src="../../images/node-action-copy.svg"
                    alt="node-action-copy"
                  />
                </button>
                <button
                  onClick={handelDeleteNode}
                  className="cursor-pointer opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]"
                >
                  <img
                    src="../../images/node-action-delete.svg"
                    alt="node-action-delete"
                  />
                </button>
              </div>
              <div className="node-block-icon-parent w-[70px] h-[70px] flex items-end justify-center">
                <img
                  className="node-block-icon"
                  src="../../images/http-node-icon.svg"
                  alt="http-node-icon"
                />
              </div>
              <span
                className="node-block-title mt-[11px] text-[#60809F] text-center font-[inter-medium] text-[16px] not-italic font-medium leading-[normal]"
                title={props.data.label}
              >
                {/* HTTP
                <br />
                Request */}
                {props.data.label}
              </span>
            </div>
          </div>
          <div
            onPointerDown={(e) => {
              e.stopPropagation();
            }}
            onDoubleClick={(e) => e.stopPropagation()}
            className="title hidden"
            data-testid="title"
          >
            {label}
          </div>
          {outputs.map(
            ([key, output]) =>
              output && (
                <div className="output" key={key} data-testid={`output-${key}`}>
                  <div className="output-title" data-testid="output-title">
                    {output?.label}
                  </div>
                  <RefSocket
                    name="output-socket"
                    side="output"
                    emit={props.emit}
                    socketKey={key}
                    nodeId={id}
                    payload={output.socket}
                  />
                </div>
              )
          )}

          <div className="hidden">
            {/* <Select
                placeholder="Select a methord"
                onChange={(e) => handelInputChange("methord", e)}
                value={nodeData?.['methord']}
              >
                <Select.Option value="GET">GET</Select.Option>
                <Select.Option value="POST">POST</Select.Option>
                <Select.Option value="PUT">PUT</Select.Option>
                <Select.Option value="PATCH">PATCH</Select.Option>
                <Select.Option value="DELETE">DELTE</Select.Option>
              </Select>
            
            <Input
              style={{ fontSize: "1.5em", height: "2em" }}
              onInput={(e) =>
                handelInputChange("url", (e.target as HTMLInputElement).value)
              }
              placeholder="Enter url"
            /> */}
          </div>

          {nodeData?.["methord"] != "" && nodeData?.["methord"] != "GET" ? (
            <div className="hidden">
              {/* <Input.TextArea
                rows={4}
                onChange={(e) => handelInputChange("body", e)}
                placeholder="Enter JSON here"
                status={error ? "error" : ""}
              />
              {error && (
                <Alert
                  message={error}
                  type="error"
                  showIcon
                  style={{ marginTop: "10px" }}
                />
              )} */}
            </div>
          ) : (
            ""
          )}
          <div className="hidden">
            {/* <span className="title">Send Headers</span>
            <Switch
              onChange={(e) => handelInputChange("sendHeaders", e)}
              checked={nodeData?.['sendHeaders']}
            />
        
            <span className="title">Specify Headers </span>
            <Select
              placeholder="Select a methord"
              onChange={(e) => handelInputChange("headerFieldstype", e)}
              value={nodeData?.['headerFieldstype']}
            >
              <Select.Option value="UsingFieldsBelow">
                Using fields below
              </Select.Option>
              <Select.Option value="UsingJson">Using Json</Select.Option>
            </Select> */}
            {nodeData?.["sendHeaders"] ? (
              <div className="hidden">
                {/* <h5 className="title">Header</h5>
              {nodeData?.['headerFieldstype'] == "UsingFieldsBelow" ? (
                <DynamicKeyValueInput
                  onDataChanged={(e) => handelInputChange("headerFields", e)}
                />
              ) : (
                <Input.TextArea
                  rows={4}
                  onChange={(e) =>
                    handelInputChange("headerFields", convertArrayToObject(e))
                  }
                  placeholder="Enter JSON here"
                  status={error ? "error" : ""}
                />
              )} */}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="hidden">
            {/* <br />
        
            <span className="title">Options</span>
            <Select placeholder="Add Options" onChange={handelOption}>
              {!options.includes("Pagination") ? (
                <Select.Option value="Pagination">Pagination</Select.Option>
              ) : (
                ""
              )}
              {!options.includes("Timeout") ? (
                <Select.Option value="Timeout">Timeout</Select.Option>
              ) : (
                ""
              )}
            </Select> */}

            {/* <div className="hidden">
          <br />
          <div>
            <span className="title">Pagination Mode</span>
          </div>
          {options.includes("Pagination") ? (
            <>
              <MinusCircleOutlined
                onPointerDown={(e) => e.stopPropagation()}
                onDoubleClick={(e) => e.stopPropagation()}
                onClick={() => handleRemoveOption("Pagination")}
              />
              <br />
              <div>
                <span className="title">Parameters</span>
                <DynamicKeyValueInputPagenation
                  onDataChanged={paginationChanges}
                />
                <br />
                <span className="title">Pagination Complete When</span>
                <Select
                  placeholder="Add Options"
                  onChange={handelPaginationCompete}
                >
                  <Select.Option value="Response Is Empty">
                    Response Is Empty
                  </Select.Option>
                  <Select.Option value="ReceiveSpecificStatusCode">
                    Receive Specific Status Code(s)
                  </Select.Option>
                  <Select.Option value="Other">Other</Select.Option>
                </Select>
                {paginationCompete == "ReceiveSpecificStatusCode" ? (
                  <>
                    <span className="title">Status Code(s) when Complete</span>
                    <Input
                      onChange={(e) => {
                        nodeData["statusCoddeWhenComplete"] = (
                          e.target as HTMLInputElement
                        ).value;
                        setNodeData(nodeData);
                      }}
                      style={{ fontSize: "1.5em", height: "2em" }}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
              <br />
              <div>
                <span className="title"> Limit Pages Fetched</span>
                <br />
                <Switch
                  onChange={handleLimitPagesFetched}
                  checked={limitPagesFetched}
                />
                <br />
                {limitPagesFetched ? (
                  <Input
                    type="number"
                    style={{ fontSize: "1.5em", height: "2em" }}
                    onInput={(e) => {
                      nodeData["limtPagination"] = parseInt(
                        (e.target as HTMLInputElement).value
                      );
                      setNodeData(nodeData);
                      setLimtPagination(
                        parseInt((e.target as HTMLInputElement).value)
                      );
                    }}
                  />
                ) : (
                  ""
                )}
                <br />
              </div>
              <br />
              <div>
                <span className="title"> Interval Between Requests (ms)</span>
                <br />
                <Input style={{ fontSize: "1.5em", height: "2em" }} />
              </div>
            </>
          ) : (
            ""
          )}
        </div> */}
            <div>
              {/* {options.includes("Timeout") ? (
            <>
              <div className="hidden">
                <MinusCircleOutlined
                  onPointerDown={(e) => e.stopPropagation()}
                  onDoubleClick={(e) => e.stopPropagation()}
                  onClick={() => handleRemoveOption("Timeout")}
                />
                <div>
                  <span className="title">Timeout</span>
                  <Input
                    type="number"
                    style={{ fontSize: "1.5em", height: "2em" }}
                    onInput={(e) => {
                      nodeData["timeLimitOption"] = parseInt(
                        (e.target as HTMLInputElement).value
                      );
                      setNodeData(nodeData);
                      setTimeLimitOption(
                        parseInt((e.target as HTMLInputElement).value)
                      );
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )} */}
            </div>
          </div>

          {inputs.map(
            ([key, input]) =>
              input && (
                <div className="input" key={key} data-testid={`input-${key}`}>
                  <RefSocket
                    name="input-socket"
                    emit={props.emit}
                    side="input"
                    socketKey={key}
                    nodeId={id}
                    payload={input.socket}
                  />
                  {input && (!input.control || !input.showControl) && (
                    <div className="input-title" data-testid="input-title">
                      {input?.label}
                    </div>
                  )}
                  {input?.control && input?.showControl && (
                    <span className="input-control">
                      <RefControl
                        key={key}
                        name="input-control"
                        emit={props.emit}
                        payload={input.control}
                      />
                    </span>
                  )}
                </div>
              )
          )}
        </NodeStyles>
      </DndProvider>
    </>
  );
}
