import { ClassicPreset } from "rete";
import { DataflowEngine, DataflowNode } from "rete-engine";
import axios from "axios";
import { Schemes } from "../rete/types";
import { TextSocket } from "../editor";

const socket = new ClassicPreset.Socket("socket");
class EditFields
  extends ClassicPreset.Node<
    {
      input: ClassicPreset.Socket;
    },
    {
      output: ClassicPreset.Socket;
    },
    {
      formData: ClassicPreset.InputControl<"text">; 
    }
  >
  implements DataflowNode
{
  [x: string]: any;
  width = 600;
  height = 600;
  httpData: any;
  response: any;
  isNodeEnable=true
  nodeData={
    imgSrc:"../../images/edit-node-icon.svg",
    name: "Edit Fields", 
    description:"Edit Fields"
  }
  constructor(
    public dataflow: DataflowEngine<Schemes>,
    public onClick: (jsonData: any) => void,
    private log: (text: string) => void,
    public editor
    
  ) {
    super("Edit Fields");

    this.addControl("formData", new ClassicPreset.InputControl("text"));
 

    this.addInput("input", new ClassicPreset.Input(new TextSocket(), "Input"));

    this.addOutput(
      "output",
      new ClassicPreset.Output(new TextSocket(), "Output")
    );
  }
  worker(node: any, inputs: any) {
    console.log("worker", inputs);
    console.log(inputs);
  }
  data(): { output: any } {
    return {
      output: this.httpData,
    };
  }
  validateRequestConfig(config) {
    const errors = [];

    // Validate non-empty strings
    ["url", "methord"].forEach((key) => {
      if (typeof config[key] !== "string" || config[key].trim() === "") {
        errors.push(`Invalid or missing '${key}'.`);
      }
    });

    return errors.length > 0 ? errors : null;
  }

  async execute(
    input: "exec",
    forward: (output: "output", data?: any) => void
  ) {
    // Add code here to make API call with the URL and method
    forward('output',new ClassicPreset.Output(new TextSocket(), JSON.stringify(this.httpData))) 
  }
}

export default EditFields;
