import React from 'react';
import { components } from 'react-select';

const CustomSelectIconOption = (props) => {
  return (
    <components.Option {...props}>
      <div className="midform-select-icon_option flex items-center justify-start">        
        <div className="midform-select-icon_option-img w-[32px] h-[32px] flex items-center justify-center mr-[8px]">        
            <img src={props.data.image} alt={props.data.label} />
        </div>
        <div className="midform-select-icon_option-text">
            {props.data.label}    
            <div className="midform-select-subtitle_subtitle">
              {props.data.subtitle}
            </div>    
        </div>   
      </div>
    </components.Option>
  );
};

export default CustomSelectIconOption;