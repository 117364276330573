import * as React from "react";
import styled, { css } from "styled-components";
import { ClassicScheme, RenderEmit, Presets } from "rete-react-plugin";
import { Schemes } from "../rete/types";
import ScheduleTrigger from "../customization/ScheduleTrigger";
import Popup from "reactjs-popup";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import DynamicTable from "../components/Dynamictable";
import RecursiveComponent from "../components/RecursiveComponent";
import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import "moment-timezone";
import * as Yup from "yup";
import { deleteNodes, runNodes } from "../editor";
import createAPI from "../api";
import { URLS } from "../utils";
import io from "socket.io-client";

const { RefSocket, RefControl } = Presets.classic;
export const $nodewidth = 200;
export const $socketmargin = 6;
export const $socketsize = 16;

type NodeExtraData = { width?: number; height?: number };
type Props<S extends ClassicScheme> = {
  data: ScheduleTrigger & NodeExtraData;
  emit: RenderEmit<S>;
};
export const NodeStyles = styled.div<
  NodeExtraData & { selected: boolean; styles?: (props: any) => any }
>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 2px solid #d2dbe4;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  cursor: pointer;
  box-sizing: border-box;
  width: ${(props) =>
    Number.isFinite(props.width) ? `${props.width}px` : `${$nodewidth}px`};
  height: 166px;
  padding-bottom: 6px;
  position: relative;
  user-select: none;
  &:focus {
    outline: 8px solid #dfe5ec;
  }
  ${(props) =>
    props.selected &&
    css`
      outline: 8px solid #dfe5ec;
    `}
  .title {
    color: white;
    font-family: sans-serif;
    font-size: 18px;
    padding: 8px;
  }
  .output {
    text-align: right;
  }
  .input {
    text-align: left;
  }
  .output-socket {
    display: block;
    position: absolute;
    right: -24px;
    height: 24px;
    width: 24px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    border-radius: 100%;
    background: #a5adba;
  }
  .input-socket {
    text-align: left;
    margin-left: -1px;
    display: inline-block;
  }
  .input-title,
  .output-title {
    vertical-align: middle;
    color: white;
    display: none;
    font-family: sans-serif;
    font-size: 14px;
    margin: ${$socketmargin}px;
    line-height: ${$socketsize}px;
  }
  .input-control {
    z-index: 1;
    width: calc(100% - ${$socketsize + 2 * $socketmargin}px);
    vertical-align: middle;
    display: inline-block;
  }
  .sc-jsJBEP.ciTnqI {
    height: auto !important;
    width: 250px !important;
  }
  .control {
    display: block;
    padding: ${$socketmargin}px ${$socketsize / 2 + $socketmargin}px;
  }

  ${(props) => props.styles && props.styles(props)}
`;

export function TriggerNode(props: Props<Schemes>) {
  const API = createAPI();
  const timearray = [
    { value: "0:00", label: "Midnight" },
    { value: "1:00", label: "1AM" },
    { value: "2:00", label: "2AM" },
    { value: "3:00", label: "3AM" },
    { value: "4:00", label: "4AM" },
    { value: "5:00", label: "5AM" },
    { value: "6:00", label: "6AM" },
    { value: "7:00", label: "7AM" },
    { value: "8:00", label: "8AM" },
    { value: "9:00", label: "9AM" },
    { value: "10:00", label: "10AM" },
    { value: "11:00", label: "11AM" },
    { value: "12:00", label: "Noon" },
    { value: "13:00", label: "1PM" },
    { value: "14:00", label: "2PM" },
    { value: "15:00", label: "3PM" },
    { value: "16:00", label: "4PM" },
    { value: "17:00", label: "5PM" },
    { value: "18:00", label: "6PM" },
    { value: "19:00", label: "7PM" },
    { value: "20:00", label: "8PM" },
    { value: "21:00", label: "9PM" },
    { value: "22:00", label: "10PM" },
    { value: "23:00", label: "11PM" },
  ];

  function formatDate() {
    const date = new Date(); // This can be dynamic
    const options = { timeZoneName: "short" };
    const dateTimeFormat = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      weekday: "long",
      timeZoneName: "short",
    });

    const [
      { value: month },
      ,
      { value: day },
      ,
      { value: year },
      ,
      { value: weekday },
      ,
      { value: hour },
      ,
      { value: minute },
      ,
      { value: second },
      ,
      { value: timeZoneName },
    ] = dateTimeFormat.formatToParts(date);

    return {
      timestamp: date.toISOString(),
      "Readable date": `${month} ${day}th ${year}`,
      "Readable time": `${hour}:${minute}:${second} ${timeZoneName}`,
      "Day of week": weekday,
      Year: year,
      Month: month,
      "Day of month": day,
      Hour: hour,
      Minute: minute,
      Second: second,
      Timezone: timeZoneName,
    };
  }

  const [schduleNodeOutput, setSchduleNodeOutput] = React.useState(
    formatDate()
  );
  const { id, width, height } = props.data;
  const [intalvalType, setIntalvalType] = React.useState("");
  const [triggerData, setTriggerData] = React.useState({});
  const [showMainPopup, setShowMainPopup] = React.useState(false);
  const [showSubPopup, setShowSubPopup] = React.useState(false);
  const [editOutputJson, setEditOutputJson] = React.useState(false);
  const selected = props.data.selected || false;
  const inputs = Object.entries(props.data.inputs);
  const outputs = Object.entries(props.data.outputs);
  const [tabIndexOutput, setTabIndexOutput] = React.useState(0);
  const [outputData, setOutputData] = React.useState([]);
  const [isNodeEnable, setIsNodeEnable] = React.useState(true);
  const [isValidJson, setIsValidJson] = React.useState(true);
  const [isLableEdit, setIsLableEdit] = React.useState(false);
  const [label, setLabel] = React.useState(props.data.label);
  const triggerInterval = [
    { value: "seconds", label: "Seconds" },
    { value: "minutes", label: "Minutes" },
    { value: "hours", label: "Hours" },
    { value: "days", label: "Days" },
    { value: "weeks", label: "Weeks" },
    { value: "months", label: "Months" },
    { value: "custom", label: "Custom (Cron)" },
  ];
  const weekdays = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
  ];

  React.useEffect(() => {
    console.log(schduleNodeOutput);
    props.data.controls.triggerIntervalData.value = JSON.stringify(triggerData);
  }, [triggerData]);

  function handelSaveOutputJson() {
    if (isValidJson) {
      props.data.outputData = outputData;
      setEditOutputJson(false);
    }
  }

  const handleJsonChange = (data) => {
    if (data.jsObject) {
      setOutputData(data.jsObject);
      setIsValidJson(true);
    } else {
      setIsValidJson(false);
    }
  };

  function handelJsonedit() {
    setEditOutputJson((prev) => !prev);
    setTabIndexOutput(1);
  }

  function HandelOnselectTab(index) {
    setTabIndexOutput(index);
    setEditOutputJson(false);
  }

  const closeMainPopup = () => {
    setShowMainPopup(false);
    toggleVisibility(false);
  };

  const closeSubPopup = () => {
    setIsLableEdit(false);
  };

  const toggleVisibility = (show) => {
    const actions = document.querySelector(
      ".workflow-view-actions-inn"
    ) as HTMLElement | null;
    const sidebar = document.querySelector(
      ".workflow-sidebar"
    ) as HTMLElement | null;
    if (show) {
      if (actions) {
        actions.style.display = actions.style.display === "none" ? "" : "none";
      }
      if (sidebar) {
        sidebar.style.display = sidebar.style.display === "none" ? "" : "none";
      }
    } else if (!show) {
      if (actions) {
        actions.style.display = "";
      }
      if (sidebar) {
        sidebar.style.display = "";
      }
    }
  };

  React.useEffect(() => {
    const socket = io(URLS.BACK_END); // Connect to socket
    socket.on("schdule-trigger", (e) => {
      console.log("HIHIHIHI");
      props.data.outputData = JSON.stringify(e);
    });
    return () => {
      socket.disconnect(); // Disconnect socket when the component unmounts
      console.log("Disconnected from server");
    };
  }, []);

  const validationSchema = Yup.object().shape({
    triggerInterval: Yup.string().required("Trigger Interval is required"),
    seconds: Yup.object().shape({
      second: Yup.number()
        .required("Second is required")
        .min(0, "Second must be at least 0")
        .max(59, "Second must be at most 59"),
    }),
    minutes: Yup.object().shape({
      minutes: Yup.number()
        .nullable()
        .min(0, "Minutes must be at least 0")
        .max(59, "Minutes must be at most 59")
        .typeError("Minutes must be a number"),
    }),
    hours: Yup.object().shape({
      hours: Yup.string()
        .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, "Invalid time format")
        .required("Hours are required"),
      minutes: Yup.number()
        .nullable()
        .min(0, "Minutes must be at least 0")
        .max(59, "Minutes must be at most 59")
        .typeError("Minutes must be a number"),
    }),
    days: Yup.object().shape({
      daysBetweenTriggers: Yup.number()
        .nullable()
        .min(0, "Days between triggers must be at least 0")
        .typeError("Days between triggers must be a number"),
      hours: Yup.string()
        .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, "Invalid time format")
        .required("Hours are required"),
      minutes: Yup.number()
        .nullable()
        .min(0, "Minutes must be at least 0")
        .max(59, "Minutes must be at most 59")
        .typeError("Minutes must be a number"),
    }),
    weeks: Yup.object().shape({
      weeksBetweenTriggers: Yup.number()
        .nullable()
        .min(0, "Weeks between triggers must be at least 0")
        .typeError("Weeks between triggers must be a number"),
      triggeronWeekdays: Yup.string()
        .nullable()
        .matches(/^[0-6](,[0-6])*$/, "Invalid weekdays format"),
      hours: Yup.string()
        .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, "Invalid time format")
        .required("Hours are required"),
      minutes: Yup.number()
        .nullable()
        .min(0, "Minutes must be at least 0")
        .max(59, "Minutes must be at most 59")
        .typeError("Minutes must be a number"),
    }),
    months: Yup.object().shape({
      monthsBetweenTriggers: Yup.number()
        .nullable()
        .min(0, "Months between triggers must be at least 0")
        .typeError("Months between triggers must be a number"),
      triggeratDayofMonth: Yup.number()
        .nullable()
        .min(1, "Day of month must be at least 1")
        .max(31, "Day of month must be at most 31")
        .typeError("Day of month must be a number"),
      hours: Yup.string()
        .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, "Invalid time format")
        .required("Hours are required"),
      minutes: Yup.number()
        .nullable()
        .min(0, "Minutes must be at least 0")
        .max(59, "Minutes must be at most 59")
        .typeError("Minutes must be a number"),
    }),
    expression: Yup.string().nullable(),
  });

  const initialValues = {
    triggerInterval: "days",
    seconds: {
      second: 30,
    },
    minutes: {
      minutes: 5,
    },
    hours: {
      hours: 1,
      minutes: 0,
    },
    days: {
      daysBetweenTriggers: 1,
      hours: "0:00",
      minutes: 0,
    },
    weeks: {
      weeksBetweenTriggers: 1,
      triggeronWeekdays: ["sunday"],
      hours: "0:00",
      minutes: 0,
    },
    months: {
      monthsBetweenTriggers: 1,
      triggeratDayofMonth: 1,
      hours: "0:00",
      minutes: 0,
    },
    expression: "",
  };

  const formatOutput = (timezone) => {
    const now = moment.tz(timezone);
    return [
      {
        timestamp: now.format(),
        "Readable date": now.format("MMMM Do YYYY, h:mm:ss a"),
        "Readable time": now.format("h:mm:ss a"),
        "Day of week": now.format("dddd"),
        Year: now.format("YYYY"),
        Month: now.format("MMMM"),
        "Day of month": now.format("DD"),
        Hour: now.format("HH"),
        Minute: now.format("mm"),
        Second: now.format("ss"),
        Timezone: now.format("z Z"),
      },
    ];
  };

  function handelFromSubmit(values) {
    setOutputData(formatOutput("UTC"));
    props.data.outputData = outputData;
    let data = {
      ...values,
      userId: "6618bfb8ebc89958d978546d",
      timezone: "UTC",
    };

    API.post("rete/schedules/dryrun", data).then((data) => {
      console.log(data);
    });
  }

  function handelDeleteNode() {
    deleteNodes(props.data.id);
  }

  function handelEnableDisableNode() {
    setIsNodeEnable((prv) => !prv);
    props.data.isNodeEnable = isNodeEnable;
  }

  function handelCopyNode() {
    props.data.dupicateNode();
  }

  const handleDrop = (item) => {
    console.log("Dropped item:", item);
  };

  function handelLableEdit() {
    setIsLableEdit((prv) => !prv);
    setLabel(props.data.label);
  }

  function handelSaveLableEdit() {
    setIsLableEdit(false);
    props.data.label = label
  }

  const handleChange = (e) => {
    setLabel(e.target.value); 
  };

  return (
    <>
      <Popup
        open={showMainPopup}
        className="trigger-node-popup"
        onClose={closeMainPopup}
      >
        <div className="modal">
          <div className="node-popup webhook-node-popup fixed top-[70px] left-[0] w-full bg-[#F4F1FA] h-[calc(100%_-_70px)] z-[11]">
            <div className="node-popup-inn">
              <div className="node-popup-back border-b [border-bottom-style:solid] [border-bottom-color:#BECBD8] h-[50px] bg-[#eee] px-[29px] py-[0] flex items-center justify-start">
                <button
                  onClick={closeMainPopup}
                  className="flex items-center justify-center border-[0] p-0 h-auto rounded-none bg-transparent [box-shadow:none]"
                >
                  <img src="../../images/node-popup-back.svg" alt="node-popup-back" />
                  <span className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 text-[#000] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] ml-[4px]">
                    Back to Canvas
                  </span>
                </button>
              </div>
              <div className="node-popup-views flex h-[calc(100vh_-_120px)]">
                <div className="node-popup-view node-popup-fields-view [flex-basis:33.33%] w-1/3 bg-[#fff]">
                  <div className="node-popup-midform">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      validateOnChange={true}
                      validateOnBlur={true}
                      onSubmit={(values, { setSubmitting }) => {
                        console.log(values);
                        setSubmitting(false);
                      }}
                    >
                      {({ values, setFieldValue, errors, touched }) => (
                        <Form>
                          <div className="midform-head p-[22px] pt-[13px]">
                            <div className="midform-head-wrap relative">
                              {isLableEdit && (
                                <div className="edit-comm-name absolute z-[3] top-2/4 left-[200px] w-[200px] -translate-y-1/2 bg-[#fff] rounded-[4px] [box-shadow:0_0_15px_-5px_rgba(0,_0,_0,_0.25)] p-[10px]">
                                  <>
                                    <div className="edit-comm-name-title font-[inter-medium] text-[14px] leading-[normal] text-[rgba(0,0,0,0.8)] font-medium mb-[10px]">
                                      Rename node
                                    </div>
                                    <input
                                      type="text"
                                      onChange={handleChange}
                                      value={label}
                                      className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                    />
                                    <div className="flex items-center justify-end mt-[10px]">
                                      <button
                                        type="button"
                                        className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                        onClick={handelLableEdit}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type="button"
                                        className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#006DE0] rounded-[4px]"
                                        onClick={handelSaveLableEdit}
                                      >
                                        Save
                                      </button>
                                    </div>
                                    <span className="edit-comm-name-diamond w-[10px] h-[10px] bg-[#fff] rotate-45 absolute top-[0] bottom-[0] m-auto -left-[5px]"></span>
                                  </>
                                </div>
                              )}
                              <div className="midform-head-inn relative flex items-center justify-between px-[19px] py-[15px]">
                                <div className="midform-head-title relative z-[2] flex items-center justify-start">
                                  <img
                                    src="../../images/node-popup-trigger.svg"
                                    alt="node-popup-trigger"
                                  />
                                  <span
                                    onClick={handelLableEdit}
                                    className="ml-[8px] text-[#60809F] font-['inter-semibold'] text-[16px] not-italic font-semibold leading-[normal]"
                                    title={props.data.label}
                                  >
                                    {props.data.label}
                                  </span>
                                </div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    console.log(values);
                                    handelFromSubmit(values);
                                  }}
                                  className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 relative z-[1] [box-shadow:none] h-[34px] rounded-[4px] bg-[#006DE0] border-0 !px-[12px] !py-[0] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                >
                                  Test Workflow
                                </button>
                                <div className="midform-head-bg absolute w-full h-full top-[0] left-[0] z-0 bg-[linear-gradient(172deg,_rgba(30,_7,_95,_0.70)_6.32%,_#006DE0_93.51%)] rounded-[10px] opacity-[0.09]"></div>
                              </div>
                            </div>
                          </div>
                          <div className="midform-fields">
                            <div className="midform-field midform-field-select relative mb-[30px] px-[45px]">
                              <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                Trigger Interval
                              </label>
                              <Select
                                name="triggerInterval"
                                className="midform-select-main"
                                classNamePrefix="midform-select"
                                options={triggerInterval}
                                defaultValue={triggerInterval.find(
                                  (option) =>
                                    option.value == values.triggerInterval
                                )}
                                onChange={(option) =>
                                  setFieldValue("triggerInterval", option.value)
                                }
                              />
                            </div>
                            {values.triggerInterval == "seconds" && (
                              <div className="midform-field mb-[30px] px-[45px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Seconds Between Triggers
                                </label>
                                <Field
                                  name="seconds.second"
                                  type="number"
                                  className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                />
                                <ErrorMessage
                                  className="trigg_node_error absolute text-[11px] left-[45px] top-full text-[red]"
                                  name="seconds.second"
                                  component="div"
                                />
                              </div>
                            )}
                            {values.triggerInterval == "minutes" && (
                              <div className="midform-field mb-[30px] px-[45px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Minutes Between Triggers
                                </label>
                                <Field
                                  name="minutes.minutes"
                                  type="number"
                                  className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                />
                                <ErrorMessage
                                  className="trigg_node_error absolute text-[11px] left-[45px] top-full text-[red]"
                                  name="minutes.minutes"
                                  component="div"
                                />
                              </div>
                            )}
                            {values.triggerInterval == "hours" && (
                              <div className="midform-field mb-[30px] px-[45px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Hours Between Triggers
                                </label>
                                <Field
                                  name="hours.hours"
                                  type="text"
                                  placeholder="00:00"
                                  className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                />
                                <ErrorMessage
                                  name="hours.hours"
                                  component="div"
                                  className="trigg_node_error absolute text-[11px] left-[45px] top-full text-[red]"
                                />
                              </div>
                            )}
                            {values.triggerInterval == "hours" && (
                              <div className="midform-field mb-[30px] px-[45px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Trigger at Minute
                                </label>
                                <Field
                                  name="hours.minutes"
                                  type="number"
                                  className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                />
                                <ErrorMessage
                                  name="hours.minutes"
                                  component="div"
                                  className="trigg_node_error absolute text-[11px] left-[45px] top-full text-[red]"
                                />
                              </div>
                            )}
                            {values.triggerInterval == "days" && (
                              <div className="midform-field mb-[30px] px-[45px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Days Between Triggers
                                </label>
                                <Field
                                  name="days.daysBetweenTriggers"
                                  type="number"
                                  className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                />
                                <ErrorMessage
                                  name="days.daysBetweenTriggers"
                                  component="div"
                                  className="trigg_node_error absolute text-[11px] left-[45px] top-full text-[red]"
                                />
                              </div>
                            )}
                            {values.triggerInterval == "weeks" && (
                              <div className="midform-field mb-[30px] px-[45px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Weeks Between Triggers
                                </label>
                                <Field
                                  name="weeks.weeksBetweenTriggers"
                                  type="number"
                                  className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                />
                                <ErrorMessage
                                  name="weeks.weeksBetweenTriggers"
                                  component="div"
                                  className="trigg_node_error absolute text-[11px] left-[45px] top-full text-[red]"
                                />
                              </div>
                            )}
                            {values.triggerInterval == "weeks" && (
                              <div className="midform-field midform-field-select relative mb-[30px] px-[45px]">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Trigger on Weekdays
                                </label>
                                <Select
                                  name="weeks.triggeronWeekdays"
                                  className="midform-select-main"
                                  classNamePrefix="midform-select"
                                  defaultValue={weekdays.filter((option) =>
                                    values.weeks.hours.includes(option.value)
                                  )}
                                  onChange={(options) =>
                                    setFieldValue(
                                      "weeks.hours",
                                      options.map((option) => option.value)
                                    )
                                  }
                                  options={weekdays}
                                  isMulti={true}
                                />
                              </div>
                            )}
                            {values.triggerInterval == "weeks" && (
                              <div className="midform-field midform-field-select relative mb-[30px] px-[45px]">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Trigger at Hour
                                </label>
                                <Select
                                  name="weeks.hours"
                                  className="midform-select-main"
                                  classNamePrefix="midform-select"
                                  defaultValue={timearray.find(
                                    (option) =>
                                      option.value === values.weeks.hours
                                  )}
                                  onChange={(option) =>
                                    setFieldValue("weeks.hours", option.value)
                                  }
                                  options={timearray}
                                />
                              </div>
                            )}
                            {values.triggerInterval == "days" && (
                              <div className="midform-field midform-field-select relative mb-[30px] px-[45px]">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Trigger at Hour
                                </label>
                                <Select
                                  name="days.hours"
                                  className="midform-select-main"
                                  classNamePrefix="midform-select"
                                  defaultValue={timearray.find(
                                    (option) =>
                                      option.value === values.days.hours
                                  )}
                                  onChange={(option) =>
                                    setFieldValue("days.hours", option.value)
                                  }
                                  options={timearray}
                                />
                              </div>
                            )}
                            {values.triggerInterval == "weeks" && (
                              <div className="midform-field mb-[30px] px-[45px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Trigger at Minute
                                </label>
                                <Field
                                  name="weeks.minutes"
                                  type="number"
                                  className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                />
                                <ErrorMessage
                                  name="weeks.minutes"
                                  component="div"
                                  className="trigg_node_error absolute text-[11px] left-[45px] top-full text-[red]"
                                />
                              </div>
                            )}
                            {values.triggerInterval == "days" && (
                              <div className="midform-field mb-[30px] px-[45px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Trigger at Minute
                                </label>
                                <Field
                                  name="days.minutes"
                                  type="number"
                                  className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                />
                                <ErrorMessage
                                  name="days.minutes"
                                  component="div"
                                  className="trigg_node_error absolute text-[11px] left-[45px] top-full text-[red]"
                                />
                              </div>
                            )}
                            {values.triggerInterval == "months" && (
                              <div className="midform-field mb-[30px] px-[45px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Months Between Triggers
                                </label>
                                <Field
                                  name="months.monthsBetweenTriggers"
                                  type="number"
                                  className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                />
                                <ErrorMessage
                                  name="months.monthsBetweenTriggers"
                                  component="div"
                                  className="trigg_node_error absolute text-[11px] left-[45px] top-full text-[red]"
                                />
                              </div>
                            )}
                            {values.triggerInterval == "months" && (
                              <div className="midform-field mb-[30px] px-[45px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Trigger at Day of Month
                                </label>
                                <Field
                                  name="months.triggeratDayofMonth"
                                  type="number"
                                  className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                />
                                <ErrorMessage
                                  name="months.triggeratDayofMonth"
                                  component="div"
                                  className="trigg_node_error absolute text-[11px] left-[45px] top-full text-[red]"
                                />
                              </div>
                            )}
                            {values.triggerInterval == "months" && (
                              <div className="midform-field midform-field-select relative mb-[30px] px-[45px]">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Trigger at Hour
                                </label>
                                <Select
                                  name="months.hours"
                                  className="midform-select-main"
                                  classNamePrefix="midform-select"
                                  defaultValue={timearray.find(
                                    (option) =>
                                      option.value === values.months.hours
                                  )}
                                  onChange={(option) =>
                                    setFieldValue("months.hours", option.value)
                                  }
                                  options={timearray}
                                />
                              </div>
                            )}
                            {values.triggerInterval == "months" && (
                              <div className="midform-field mb-[30px] px-[45px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Trigger at Minute
                                </label>
                                <Field
                                  name="months.minutes"
                                  type="number"
                                  className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                />
                                <ErrorMessage
                                  name="months.minutes"
                                  component="div"
                                  className="trigg_node_error absolute text-[11px] left-[45px] top-full text-[red]"
                                />
                              </div>
                            )}
                            {values.triggerInterval == "custom" && (
                              <div className="midform-field mb-[30px] px-[45px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Expression
                                </label>
                                <Field
                                  name="expression"
                                  type="text"
                                  className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                />
                                <ErrorMessage
                                  name="expression"
                                  component="div"
                                  className="trigg_node_error absolute text-[11px] left-[45px] top-full text-[red]"
                                />
                              </div>
                            )}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
                <div className="node-popup-view node-popup-output-view [flex-basis:66.66%]">
                  <div className="node-popup-output-view-inn px-[30px] py-[26px]">
                    <div className="node-popup-output-tabs">
                      <Tabs
                        selectedIndex={tabIndexOutput}
                        onSelect={(index) => HandelOnselectTab(index)}
                      >
                        <div className="node-popup-output-tabs-wrap flex items-center justify-between mb-[26px]">
                          <div className="node-popup-output-title text-[#006DE0] font-['inter-semibold'] text-[16px] not-italic font-semibold leading-[normal] tracking-[1.92px] uppercase">
                            Output
                          </div>
                          <TabList className="flex items-center justify-end">
                            {!editOutputJson ? (
                              <>
                                <div className="node-popup-output-tabs-inn flex items-center justify-end rounded-[4px] bg-[#fff] p-[5px]">
                                  <Tab className="[transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                    Table
                                  </Tab>
                                  <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                    JSON
                                  </Tab>
                                  <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                    Schema
                                  </Tab>
                                </div>
                                <button
                                  className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center ml-[8px] rounded-[4px] bg-[#fff] w-[36px] h-[36px]"
                                  onClick={handelJsonedit}
                                >
                                  <img
                                    src="../../images/node-popup-edit.svg"
                                    alt="node-popup-edit"
                                  />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                  onClick={handelJsonedit}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#006DE0] rounded-[4px]"
                                  onClick={handelSaveOutputJson}
                                  disabled={!isValidJson}
                                >
                                  Save
                                </button>
                              </>
                            )}
                          </TabList>
                        </div>
                        <div className="node-popup-output-tab-panels">
                          <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view">
                            <div className="node-table-view-wrap">
                              <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_235px)]">
                                <DynamicTable data={outputData} />
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel
                            className={`node-popup-output-tab-panel node-json-view  webhook-node-json-view h-[calc(100vh_-_235px)] ${
                              editOutputJson ? "" : "node-json-view-readonly"
                            }`}
                          >
                            <JSONInput
                              id="webhook-json"
                              placeholder={outputData}
                              locale={locale}
                              theme="light_mitsuketa_tribute"
                              colors={{
                                default: "#39ADB5",
                                keys: "#E53935",
                                string: "#91B859",
                                number: "#F76D47",
                                background: "#F7F7F7",
                              }}
                              width={"100%"}
                              height={"100%"}
                              style={{
                                outerBox: {
                                  borderRadius: "6px",
                                  border: "1px solid #CCC",
                                  overflow: "hidden",
                                  background: "#F7F7F7",
                                },
                              }}
                              onChange={handleJsonChange}
                            />
                          </TabPanel>
                          <TabPanel className="node-popup-output-tab-panel node-schema-view webhook-node-schema-view">
                            <div className="node-schema-tree-view webhook-node-schema-tree-view">
                              {outputData.map((item, index) => (
                                <RecursiveComponent
                                  key={index}
                                  item={item}
                                  onFieldDrop={handleDrop}
                                  draggable={false}
                                />
                              ))}
                            </div>
                          </TabPanel>
                        </div>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <NodeStyles
        selected={selected}
        width={166}
        height={166}
        className="nodeWrap node-comm node-trigger"
        data-testid="node"
      >
        <div
          onPointerDown={(e) => {
            e.stopPropagation();
          }}
          onDoubleClick={(e) => {
            setShowMainPopup((pop) => !pop);
            toggleVisibility(true);
            e.stopPropagation();
          }}
          className="title"
          data-testid="title"
        >
          <div className="node-block-parent">
            <div className="node-block-plus-actions flex items-center justify-center absolute left-[185px] top-[0] bottom-[0] m-auto flex items-center justify-start h-[34px] leading-0">
              <div className="node-block-plus-actions-arrow flex items-center justify-center relative">
                <div className="node-block-plus-actions-arrow-line bg-[#919394] h-[2px] w-[105px]"></div>
              </div>
              <button className="node-block-plus-actions-btn w-[34px] h-[34px]">
                <img src="../../images/plus-icon-node.svg" alt="plus-icon-node" />
              </button>
            </div>
            <div className="node-block flex items-center justify-center flex-col">
              <div className="node-block-actions absolute -top-[39px] right-[0] flex items-center justify-end z-[1] opacity-0 scale-0 w-[0] h-[0] [transition:0.3s_opacity_ease] pb-[14px]">
                <button
                  onClick={handelEnableDisableNode}
                  className="cursor-pointer mr-[16px] opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]"
                >
                  <img
                    src="../../images/node-action-power.svg"
                    alt="node-action-power"
                  />
                </button>
                <button
                  onClick={handelCopyNode}
                  className="cursor-pointer mr-[16px] opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]"
                >
                  <img
                    src="../../images/node-action-copy.svg"
                    alt="node-action-copy"
                  />
                </button>
                <button
                  onClick={handelDeleteNode}
                  className="cursor-pointer opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]"
                >
                  <img
                    src="../../images/node-action-delete.svg"
                    alt="node-action-delete"
                  />
                </button>
              </div>
              <div className="node-block-icon-parent w-[70px] h-[70px] flex items-end justify-center">
                <img
                  className="node-block-icon"
                  src="../../images/trigger-node-icon.svg"
                  alt="trigger-node-icon"
                />
              </div>
              <span className="node-block-title mt-[11px] text-[#60809F] text-center font-[inter-medium] text-[16px] not-italic font-medium leading-[normal]" title={props.data.label}>
                {props.data.label}
              </span>
            </div>
          </div>
        </div>
        {outputs.map(
          ([key, output]) =>
            output && (
              <div className="output" key={key} data-testid={`output-${key}`}>
                <div className="output-title" data-testid="output-title">
                  {output?.label}
                </div>
                <RefSocket
                  name="output-socket"
                  side="output"
                  emit={props.emit}
                  socketKey={key}
                  nodeId={id}
                  payload={output.socket}
                />
              </div>
            )
        )}
      </NodeStyles>
    </>
  );
}
