import * as React from "react";
import styled, { css } from "styled-components";
import { ClassicScheme, RenderEmit, Presets } from "rete-react-plugin";
import { Drag } from "rete-react-plugin";
import Popup from "reactjs-popup";
import Switch from "react-switch";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import HttpNode from "../customization/Httpnode";
import { Schemes } from "../rete/types";
import DynamicKeyValueInput from "./dynamicInput";
import DynamicKeyValueInputPagenation from "./dynamicinputPagination";
import WebHookTrigger from "../customization/webhookTrigger";
import io from "socket.io-client";
import { useEffect } from "react";
import { URLS, getInputConnectedNodes, isValidJsonCustom } from "../utils";
import API from "../api";
import EditFields from "../customization/editFieldsNode";
import Select from "react-select";
import CustomSelectSubtitle from "../components/CustomSelectSubtitle";
import CustomSelectIconOption from "../components/CustomSelectIconOption";
import CustomSelectIconValue from "../components/CustomSelectIconValue";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import CodeEditor from "./codeiditor";
import { useFormik } from "formik";
import DynamicTable from "../components/Dynamictable";
import RecursiveComponent from "../components/RecursiveComponent";
import DroppableSelect from "../components/DroppableSelect";
import FixedExpressionTabs from "../components/FixedExpressionTabs";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import JSONInputWrapper from "../components/JSONInputWrapper";
import DynamicTypeInput from "../components/DynamicTypeInput";
import DroppableInput from "../components/DroppableInput";
import { copyNodeWithConnectionsCopy, deleteNodes } from "../editor";
const { RefSocket, RefControl } = Presets.classic;
export const $nodewidth = 200;
export const $socketmargin = 6;
export const $socketsize = 16;

type NodeExtraData = { width?: number; height?: number };
type Props<S extends ClassicScheme> = {
  data: EditFields & NodeExtraData;
  emit: RenderEmit<S>;
};
export const NodeStyles = styled.div<
  NodeExtraData & { selected: boolean; styles?: (props: any) => any }
>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 2px solid #d2dbe4;
  border-radius: 40px;
  cursor: pointer;
  box-sizing: border-box;
  width: ${(props) =>
    Number.isFinite(props.width) ? `${props.width}px` : `${$nodewidth}px`};

  padding-bottom: 6px;
  width: 166px;
  height: 166px;
  position: relative;
  user-select: none;
  // &:hover {
  //   background: #333;
  // }
  &:focus {
    outline: 8px solid #dfe5ec;
  }
  ${(props) =>
    props.selected &&
    css`
      outline: 8px solid #dfe5ec;
    `}
  .output {
    text-align: right;
  }
  .input {
    text-align: left;
  }
  .output-socket {
    display: block;
    position: absolute;
    right: -24px;
    height: 24px;
    width: 24px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    border-radius: 100%;
    background: #a5adba;
  }
  .input-socket {
    display: block;
    position: absolute;
    left: -13px;
    height: 24px;
    width: 11px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    background: #a5adba;
  }
  .input-title,
  .output-title {
    vertical-align: middle;
    color: white;
    display: none;
    font-family: sans-serif;
    font-size: 14px;
    margin: ${$socketmargin}px;
    line-height: ${$socketsize}px;
  }
  .input-control {
    z-index: 1;
    width: calc(100% - ${$socketsize + 2 * $socketmargin}px);
    vertical-align: middle;
    display: inline-block;
  }
  .sc-jsJBEP.ciTnqI {
    height: auto !important;
    width: 250px !important;
  }
  .control {
    display: block;
    padding: ${$socketmargin}px ${$socketsize / 2 + $socketmargin}px;
  }

  ${(props) => props.styles && props.styles(props)}
`;

export function EditFieldComponent(props: Props<Schemes>) {
  const { id, width, height } = props.data;
  const [codesData, setCodesData] = React.useState({});
  const selected = props.data.selected || false;
  const inputs = Object.entries(props.data.inputs);
  const outputs = Object.entries(props.data.outputs);
  const [showpopUp, setShowpopUp] = React.useState(false);
  const [inputOption, setInputOption] = React.useState([]);
  const [inputData, setInputData] = React.useState([]);
  const [outPutData, setOutputData] = React.useState([]);
  const [isNodeEnable, setIsNodeEnable] = React.useState(true);
  const [editOutputJson, setEditOutputJson] = React.useState(false);
  const [isValidJson, setIsValidJson] = React.useState(true);
  const [tabIndexOutput, setTabIndexOutput] = React.useState(0);
  const closeModal = () => {
    setShowpopUp(false);
    toggleVisibility(false);
  };
  const toggleVisibility = (show) => {
    const actions = document.querySelector(
      ".workflow-view-actions-inn"
    ) as HTMLElement | null;
    const sidebar = document.querySelector(
      ".workflow-sidebar"
    ) as HTMLElement | null;
    if (show) {
      if (actions) {
        actions.style.display = actions.style.display === "none" ? "" : "none";
      }
      if (sidebar) {
        sidebar.style.display = sidebar.style.display === "none" ? "" : "none";
      }
    } else if (!show) {
      if (actions) {
        actions.style.display = "";
      }
      if (sidebar) {
        sidebar.style.display = "";
      }
    }
  };
  const options = [
    {
      value: "manual",
      label: "Manual Mapping",
      subtitle: "Edit item fields one by one",
    },
    {
      value: "json",
      label: "JSON",
      subtitle: "Customise item output with JSON",
    },
  ];
  const inputFieldstoInclude = [
    {
      value: "all",
      label: "All",
      subtitle: "Also include all unchanged fields from the input",
    },
    {
      value: "selected",
      label: "Selected",
      subtitle:
        "Also include the fields listed in the parameter “Fields to Include”",
    },
    {
      value: "allexcept",
      label: "All Except",
      subtitle: "Exclude the fields listed in the parameter “Fields to Exclude”",
    },
  ];
  const options2 = [
    {
      value: "string",
      label: "String",
      image: "../../images/edit-field-icon-string.svg",
    },
    {
      value: "number",
      label: "Number",
      image: "../../images/edit-field-icon-number.svg",
    },
    {
      value: "boolean",
      label: "Boolean",
      image: "../../images/edit-field-icon-boolean.svg",
    },
    {
      value: "array",
      label: "Array",
      image: "../../images/edit-field-icon-array.svg",
    },
    {
      value: "object",
      label: "Object",
      image: "../../images/edit-field-icon-object.svg",
    },
  ];
  const validateFields = (values) => {
    const errors = {};
    const fieldstoSet = values.fieldstoSet;
    Object.keys(fieldstoSet).forEach((key) => {
      if (!fieldstoSet[key]) {
        errors[key] = "Field is required";
      }
    });
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      mode: "manual",
      fieldstoSet: {},
      includeOtherInputFields: false,
      inputFieldstoInclude: "all",
      fieldstoInclude: "",
      fieldstoExclude: "",
    },
    validate: validateFields,
    onSubmit: async (values) => {
      try {
        const newOutputData = inputData.map((item) => {
          let newItem = { ...item };

          /* Object.keys(values.fieldstoSet).forEach((key) => {
            newItem[key] = values.fieldstoSet[key];
          }); */

          if (values.includeOtherInputFields) {
            Object.keys(values.fieldstoSet).forEach((key) => {
              newItem[key] = values.fieldstoSet[key];
            });
            if (values.inputFieldstoInclude === "allexcept") {
              values.fieldstoExclude.split(",").forEach((key) => {
                delete newItem[key.trim()];
              });
            } else if (values.inputFieldstoInclude === "selected") {
              const selectedFields = values.fieldstoInclude
                .split(",")
                .map((key) => key.trim());
              newItem = selectedFields.reduce((acc, key) => {
                if (item[key]) acc[key] = item[key];
                return acc;
              }, {});
            }
          } else {
            newItem = {};
            Object.keys(values.fieldstoSet).forEach((key) => {
              newItem[key] = values.fieldstoSet[key];
            });
            console.log("first");
          }

          return newItem;
        });

        setOutputData(newOutputData); 
        props.data.httpData = JSON.stringify(newOutputData);
        //  props.data.controls.formData=JSON.stringify(values)
        props.data.execute("exec", () => {});
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    },
  });
  const handleDrop = (item) => {
    console.log("Dropped item:", item);
  };
  React.useEffect(() => {
    if (props.data?.editor) {
      let inputOption = getInputConnectedNodes(props.data?.editor);
      console.log(inputOption);
      inputOption = inputOption.map((val) => ({
        value: val.id,
        label: val.label,
      }));
      setInputOption(inputOption);
      const inputs = props.data.dataflow
        .fetchInputs(props.data.id)
        .then((val) => { 
          console.log(val)
          if (isValidJsonCustom(val?.input)) { 
            setInputData(JSON.parse(val?.input)); 
          }/*  else if (typeof val?.input == "object") {
            setInputData(val?.input);
          } */
        });
    }
  }, [showpopUp]);
  const handleExpressionChange = (field, value) => {
    formik.setFieldValue(field, value);
  };
  function handelDeleteNode() {
    deleteNodes(props.data.id);
  }
  function handelEnableDisableNode() {
    console.log("enable disable");
    setIsNodeEnable((prv) => !prv);
    props.data.isNodeEnable = isNodeEnable;
  }
  function handelCopyNode() {
    copyNodeWithConnectionsCopy(props.data.id, props.data.editor);
  }
  function handelSaveOutputJson() {
    if (isValidJson) {
      props.data.httpData = outPutData;
      setEditOutputJson(false);
    }
  } 
  function handelJsonedit() {
    setEditOutputJson((prev) => !prev);
    setTabIndexOutput(1);
  }
  const handleJsonChange = (data) => {
    if (data.jsObject) {
      setOutputData(data.jsObject);
      setIsValidJson(true);
    } else {
      // If data.jsObject is undefined, the JSON is invalid
      setIsValidJson(false);
    }
  };
  function HandelOnselectTab(index) {
    setTabIndexOutput(index);
    setEditOutputJson(false);
  }
  const [isLableEdit, setIsLableEdit] = React.useState(false);
  const [label, setLabel] = React.useState(props.data.label);
  function handelLableEdit() {
    setIsLableEdit((prv) => !prv);
    setLabel(props.data.label);
  }

  function handelSaveLableEdit() {
    setIsLableEdit(false);
    props.data.label =label
  }
  const handleChangeLable = (e) => {
    setLabel(e.target.value);
    // If you need to update the parent component or props.data, you should call a function passed as a prop
    
  };
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Popup
          open={showpopUp}
          className="code-node-popup"
          onClose={closeModal}
        >
          <div className="modal">
            {/* onClick={closeModal} */}
            <div className="node-popup webhook-node-popup fixed top-[70px] left-[0] w-full bg-[#F4F1FA] h-[calc(100%_-_70px)] z-[11]">
              <div className="node-popup-inn">
                <div className="node-popup-back border-b [border-bottom-style:solid] [border-bottom-color:#BECBD8] h-[50px] bg-[#eee] px-[29px] py-[0] flex items-center justify-start">
                  <button
                    onClick={closeModal}
                    className="flex items-center justify-center border-[0] p-0 h-auto rounded-none bg-transparent [box-shadow:none]"
                  >
                    <img
                      src="../../images/node-popup-back.svg"
                      alt="node-popup-back"
                    />
                    <span className="text-[#000] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] ml-[4px]">
                      Back to Canvas
                    </span>
                  </button>
                </div>
                <div className="node-popup-views flex h-[calc(100vh_-_120px)]">
                  <div className="node-popup-view node-popup-input-view [flex-basis:33.33%] w-1/3">
                    <div className="node-popup-output-view-inn px-[30px] py-[26px]">
                      <div className="node-popup-output-tabs">
                      <Tabs>
                          <div className="node-popup-output-tabs-wrap flex items-center justify-between mb-[26px]">
                            <div className="node-popup-output-head flex items-center justify-start">
                              <div className="node-popup-output-title mr-[16px] text-[#006DE0] font-['inter-semibold'] text-[16px] not-italic font-semibold leading-[normal] tracking-[1.92px] uppercase">
                                Input
                              </div>
                              <Select
                                className="midform-select-main"
                                classNamePrefix="midform-select"
                                options={inputOption}
                                defaultValue={inputOption}
                              />
                            </div>
                            <TabList className="flex items-center justify-end">
                              <div className="node-popup-output-tabs-inn flex items-center justify-end rounded-[4px] bg-[#fff] p-[5px]">
                                <Tab className="[transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                  Table
                                </Tab>
                                <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                  JSON
                                </Tab>
                                <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                  Schema
                                </Tab>
                              </div>
                            </TabList>
                          </div>
                          <div className="node-popup-output-tab-panels">
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_235px)]">
                                  <DynamicTable data={inputData} />
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-json-view node-json-view-readonly webhook-node-json-view h-[calc(100vh_-_235px)]">
                              <JSONInput
                                id="webhook-json"
                                placeholder={inputData}
                                locale={locale}
                                theme="light_mitsuketa_tribute"
                                colors={{
                                  default: "#39ADB5",
                                  keys: "#E53935",
                                  string: "#91B859",
                                  number: "#F76D47",
                                  background: "#F7F7F7",
                                }}
                                viewOnly={true}
                                width={"100%"}
                                height={"100%"}
                                style={{
                                  outerBox: {
                                    borderRadius: "6px",
                                    border: "1px solid #CCC",
                                    overflow: "hidden",
                                    background: "#F7F7F7",
                                  },
                                }}
                              />
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-schema-view webhook-node-schema-view">
                              <div className="node-schema-tree-view webhook-node-schema-tree-view h-[calc(100vh_-_235px)] overflow-auto">
                                {inputData.map((item, index) => (
                                  <RecursiveComponent
                                    key={index}
                                    item={item}
                                    onFieldDrop={handleDrop}
                                    draggable={true}
                                  />
                                ))}
                              </div>
                            </TabPanel>
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                  <div className="node-popup-view node-popup-fields-view [flex-basis:33.33%] w-1/3 bg-[#fff]">
                    <div className="node-popup-midform">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="midform-head p-[22px] pt-[13px]">
                          <div className="midform-head-inn relative flex items-center justify-between px-[19px] py-[15px]">
                            <div className="midform-head-title relative z-[2] flex items-center justify-start">
                            {isLableEdit && (
                                  <div className="edit-comm-name absolute z-[3] top-2/4 left-[200px] w-[200px] -translate-y-1/2 bg-[#fff] rounded-[4px] [box-shadow:0_0_15px_-5px_rgba(0,_0,_0,_0.25)] p-[10px]">
                                    <>
                                      <div className="edit-comm-name-title font-[inter-medium] text-[14px] leading-[normal] text-[rgba(0,0,0,0.8)] font-medium mb-[10px]">Rename node</div>
                                      <input
                                        type="text"
                                        onChange={handleChangeLable}
                                        value={label}
                                        className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                      />
                                      <div className="flex items-center justify-end mt-[10px]">
                                      <button
                                        className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                        onClick={handelLableEdit}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#006DE0] rounded-[4px]"
                                        onClick={handelSaveLableEdit}
                                      >
                                        Rename
                                      </button>
                                      </div>
                                      <span className="edit-comm-name-diamond w-[10px] h-[10px] bg-[#fff] rotate-45 absolute top-[0] bottom-[0] m-auto -left-[5px]"></span>
                                    </>
                                  </div>
                                )}
                              <img
                                src="../../images/node-popup-edit-head.svg"
                                alt="node-popup-edit-head"
                              />
                               <span
                                onClick={handelLableEdit}
                                className="ml-[8px] text-[#60809F] font-['inter-semibold'] text-[16px] not-italic font-semibold leading-[normal]"
                                title={props.data.label}
                              >
                                {props.data.label}                                
                              </span>
                            </div>
                            <button className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 relative z-[1] [box-shadow:none] h-[34px] rounded-[4px] bg-[#006DE0] border-0 !px-[12px] !py-[0] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]">
                              Test Workflow
                            </button>
                            <div className="midform-head-bg absolute w-full h-full top-[0] left-[0] z-0 bg-[linear-gradient(172deg,_rgba(30,_7,_95,_0.70)_6.32%,_#006DE0_93.51%)] rounded-[10px] opacity-[0.09]"></div>
                          </div>
                        </div>
                        <div className="midform-fields">
                          <div className="midform-field midform-field-select midform-select-subtitle relative mb-[23px] px-[45px]">
                            <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                              Mode
                            </label>
                            <FixedExpressionTabs
                              onExpressionChange={(value) => {}}
                            >
                              <DroppableSelect
                                fieldName="mode"
                                formik={formik}
                                options={options}
                                tab={undefined}
                                setTab={undefined}
                                components={{ Option: CustomSelectSubtitle }}
                                inputData={inputData}
                              />
                            </FixedExpressionTabs>
                          </div>
                          {formik.values.mode == "manual" && (
                          <>
                          <div className="tripple-form-fields-group edit-fields-inn-form-parent midform-field midform-draggable-fields border-t [border-top-style:solid] [border-top-color:rgba(189,_202,_215,_0.3)] border-b [border-bottom-style:solid] [border-bottom-color:rgba(189,_202,_215,_0.3)] relative px-[45px] pt-[15px] pb-[23px]">
                            
                            
                              
                              <div className="midform-draggable-fields_label text-[#000] font-['inter-medium'] text-[12px] not-italic font-medium leading-[normal] opacity-80 mb-[21px]">
                              Fields to Set
                            </div>
                               {/*  <div className="midform-draggable-fields_stack">
                                  <div className="midform-draggable-fields_stack-items">
                                    <div className="midform-draggable-fields_stack-item mb-[23px] pl-[30px]">
                                      <div className="midform-draggable-fields_stack-item-inn relative border border-solid border-[#ccc] rounded-[6px]">
                                        <div className="midform-draggable-fields_stack-item-delete absolute -left-[30px] top-[0] opacity-0 cursor-pointer [transition:0.3s_opacity_ease]">
                                          <img
                                            src="../../images/node-action-delete.svg"
                                            alt="node-action-delete"
                                            className="w-[20px]"
                                          />
                                        </div>
                                        <input
                                          type="text"
                                          className="midform-draggable-fields_stack-item-input focus:outline-[0] w-full h-[36px] bg-transparent text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[14px]"
                                          placeholder="Name"
                                        />
                                        <Select
                                          className="midform-select-main"
                                          classNamePrefix="midform-select"
                                          options={options2}
                                          components={{
                                            Option: CustomSelectIconOption,
                                            SingleValue: CustomSelectIconValue,
                                          }}
                                        />
                                        <input
                                          type="text"
                                          className="midform-draggable-fields_stack-item-input focus:outline-[0] w-full h-[36px] bg-transparent text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[14px]"
                                          placeholder="Value"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="midform-draggable-fields_stack-drag-input ml-[30px] rounded-[6px] border border-dashed border-[rgba(0,0,0,0.2)] bg-[#f7f7f7] flex items-center justify-center py-[17px]">
                                    <div className="midform-draggable-fields_initial-drag-input-action flex items-center justify-center">
                                      <div className="midform-draggable-fields_initial-drag-input-text text-center text-[#000] text-center font-['inter-regular'] text-[12px] not-italic font-normal leading-[19.2px] opacity-80">
                                        Drag input fields here
                                      </div>
                                      <div className="midform-draggable-fields_initial-drag-input-sep text-center opacity-80 text-[rgba(0,_0,_0,_0.60)] font-['inter-regular'] text-[12px] not-italic font-normal leading-[19.2px] mx-[5px]">
                                        or
                                      </div>
                                      <button className="midform-draggable-fields_initial-drag-input-btn text-center opacity-80 text-[#006DE0] font-['inter-regular'] text-[12px] not-italic font-normal leading-[19.2px] hover:underline">
                                        Add Filed
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="midform-draggable-fields_initial-drag-input">
                                  <div className="midform-draggable-fields_initial-drag-input-inn rounded-[6px] border border-dashed border-[rgba(0,0,0,0.2)] bg-[#f7f7f7] h-[168px] flex items-center justify-center">
                                    <div className="midform-draggable-fields_initial-drag-input-action flex items-center justify-center flex-col">
                                      <div className="midform-draggable-fields_initial-drag-input-text text-center text-[#000] text-center font-['inter-regular'] text-[12px] not-italic font-normal leading-[19.2px] opacity-80">
                                        Drag input fields here
                                      </div>
                                      <div className="midform-draggable-fields_initial-drag-input-sep text-center opacity-80 text-[rgba(0,_0,_0,_0.60)] font-['inter-regular'] text-[12px] not-italic font-normal leading-[19.2px]">
                                        or
                                      </div>
                                      <button className="midform-draggable-fields_initial-drag-input-btn text-center opacity-80 text-[#006DE0] font-['inter-regular'] text-[12px] not-italic font-normal leading-[19.2px] hover:underline">
                                        Add Filed
                                      </button>
                                    </div>
                                  </div>
                                </div> */}
                                <DynamicTypeInput
                                  onDataChanged={(data) =>
                                    formik.setFieldValue("fieldstoSet", data)
                                  }
                                  inputData={inputData}
                                  setFieldValue={formik.setFieldValue}
                                  fieldName="fieldstoSet"
                                />
                              
                          </div>
                          </>
                            )}
                          {formik.values.mode == "json" && (
                            <div className="midform-field midform-code-editor relative px-[45px] pb-[23px] border-b [border-bottom-style:solid] [border-bottom-color:rgba(189,_202,_215,_0.3)]">
                              <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                JSON
                              </label>
                              <JSONInputWrapper
                                fieldName={"fieldstoSet"}
                                setFieldValue={formik.setFieldValue}
                                jsonData={formik.values.fieldstoSet}
                              />
                            </div>
                          )}

                          <div className="midform-field midform-input-fields-switch node-comm-switch relative px-[45px] py-[23px] flex items-center justify-start">
                            <div className="text-[#000] font-['inter-medium'] text-[12px] not-italic font-medium leading-[normal] opacity-80 mr-[11px]">
                              Include Other Input Fields
                            </div>
                            <input
                              type="checkbox"
                              id="switch2"
                              className="h-[0] w-[0] invisible"
                              name="includeOtherInputFields"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "includeOtherInputFields",
                                  !formik.values.includeOtherInputFields
                                );
                              }}
                            />
                            <label
                              className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative"
                              htmlFor="switch2"
                            >
                              Toggle
                            </label>
                          </div>
                          { formik.values.includeOtherInputFields && (
                          <>
                          <div className="midform-field midform-field-select midform-select-subtitle relative mb-[23px] px-[45px]">
                            <label className="midform-field-label block text-[#000] font-[Inter-Re  gular] text-[12px] not-italic font-normal leading-[normal] opacity-50 mb-[4px]">
                              Input Fields to Include 
                            </label>
                            <FixedExpressionTabs
                              onExpressionChange={(value) => {}}
                            >
                              <DroppableSelect
                                fieldName="inputFieldstoInclude"
                                formik={formik}
                                options={inputFieldstoInclude}
                                tab={undefined}
                                setTab={undefined}
                                components={{ Option: CustomSelectSubtitle }}
                                inputData={inputData}
                              />
                            </FixedExpressionTabs>
                          </div>   
                          </>
                        )}
                        {formik.values.includeOtherInputFields && formik.values.inputFieldstoInclude=="allexcept" &&(<div className="midform-field midform-field-has-dots-drop mb-[23px] px-[45px] pt-[6px]">
                            <div className="midform-field-dots-drop-wrap relative">
                            <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                Fields to Exclude 
                            </label>
                            <FixedExpressionTabs
                              onExpressionChange={(value) =>
                              handleExpressionChange("fieldstoExclude", value)
                            }
                            >
                              <DroppableInput fieldName="fieldstoExclude" formik={formik} tab={undefined} inputData={inputData} setTab={undefined} placeholder="e.g. fieldToExclude1,fieldToExclude2" />
                            </FixedExpressionTabs>
                          </div>
                        </div>)}
                        {formik.values.includeOtherInputFields && formik.values.inputFieldstoInclude=="selected" &&(<div className="midform-field midform-field-has-dots-drop mb-[23px] px-[45px] pt-[6px]">
                            <div className="midform-field-dots-drop-wrap relative">
                            <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                Fields to Include 
                            </label>
                            <FixedExpressionTabs
                              onExpressionChange={(value) =>
                              handleExpressionChange("fieldstoInclude", value)
                            }
                            >
                              <DroppableInput fieldName="fieldstoInclude" formik={formik} tab={undefined} inputData={inputData} setTab={undefined} placeholder="e.g. fieldToInclude1,fieldToInclude2" />
                            </FixedExpressionTabs>
                          </div>
                        </div>)}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="node-popup-view node-popup-output-view [flex-basis:33.33%] w-1/3">
                    <div className="node-popup-output-view-inn px-[30px] py-[26px]">
                      <div className="node-popup-output-tabs">
                        <Tabs selectedIndex={tabIndexOutput}
                        onSelect={(index) => HandelOnselectTab(index)}>
                          <div className="node-popup-output-tabs-wrap flex items-center justify-between mb-[26px]">
                            <div className="node-popup-output-title text-[#006DE0] font-['inter-semibold'] text-[16px] not-italic font-semibold leading-[normal] tracking-[1.92px] uppercase">
                              Output {editOutputJson}
                            </div>
                            <TabList className="flex items-center justify-end">
                              
                            {!editOutputJson ? (
                              <>
                                <div className="node-popup-output-tabs-inn flex items-center justify-end rounded-[4px] bg-[#fff] p-[5px]">
                                  <Tab className="[transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                    Table
                                  </Tab>
                                  <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                    JSON
                                  </Tab>
                                  <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                    Schema
                                  </Tab>
                                </div> 
                              </>
                            ) : (
                              <>
                                <button
                                  className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                  onClick={handelJsonedit}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#006DE0] rounded-[4px]"
                                  onClick={handelSaveOutputJson}
                                  disabled={!isValidJson}
                                >
                                  Save
                                </button>
                              </>
                            )}
                            {!editOutputJson &&(<button
                                  className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center ml-[8px] rounded-[4px] bg-[#fff] w-[36px] h-[36px]"
                                  onClick={handelJsonedit}
                                >
                                  <img
                                    src="../../images/node-popup-edit.svg"
                                    alt="node-popup-edit"
                                  />
                                </button>)}
                            </TabList>
                          </div>
                          <div className="node-popup-output-tab-panels">
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_235px)]">
                                  {(outPutData != null ||
                                    outPutData != undefined) && (
                                    <DynamicTable data={outPutData} />
                                  )}
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel className={`node-popup-output-tab-panel node-json-view ${
                              editOutputJson ? "" : "node-json-view-readonly"
                            } webhook-node-json-view h-[calc(100vh_-_235px)]`}>
                              <JSONInput
                                id="webhook-json"
                                placeholder={outPutData}
                                locale={locale}
                                theme="light_mitsuketa_tribute"
                                colors={{
                                  default: "#39ADB5",
                                  keys: "#E53935",
                                  string: "#91B859",
                                  number: "#F76D47",
                                  background: "#F7F7F7",
                                }}
                                width={"100%"}
                                height={"100%"}
                                style={{
                                  outerBox: {
                                    borderRadius: "6px",
                                    border: "1px solid #CCC",
                                    overflow: "hidden",
                                    background: "#F7F7F7",
                                  },
                                }}
                                viewOnly={!editOutputJson}
                                onChange={handleJsonChange}
                              />
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-schema-view webhook-node-schema-view">
                              <div className="node-schema-tree-view webhook-node-schema-tree-view">
                                {(outPutData != null ||
                                  outPutData != undefined) &&
                                  outPutData.map((item, index) => (
                                    <RecursiveComponent
                                      key={index}
                                      item={item}
                                      onFieldDrop={handleDrop}
                                      draggable={false}
                                    />
                                  ))}
                              </div>
                            </TabPanel>
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </DndProvider>
      <NodeStyles
        selected={selected}
        width={250}
        className={`nodeWrap node-comm node-edit-fields ${
          isNodeEnable ? "" : "disabled-node"
        }`}
        data-testid="node"
      >
        <div
          className="node-block-parent"
          onPointerDown={(e) => {
            e.stopPropagation();
          }}
          onDoubleClick={(e) => {
            setShowpopUp((pop) => !pop);
            toggleVisibility(true);
            e.stopPropagation();
          }}
        >
          <div className="node-block-plus-actions flex items-center justify-center absolute left-[185px] top-[0] bottom-[0] m-auto flex items-center justify-start h-[34px] leading-0">
            <div className="node-block-plus-actions-arrow flex items-center justify-center relative">
              <div className="node-block-plus-actions-arrow-line bg-[#919394] h-[2px] w-[105px]"></div>
              {/* <div className="node-block-plus-actions-arrow-text text-[#60809F] text-center font-[inter-regular] text-[14px] not-italic font-normal leading-[normal] bg-[#F2F5F7] absolute left-[0] right-[0] m-auto block w-[40px]">
                GET
              </div> */}
            </div>
            <button className="node-block-plus-actions-btn w-[34px] h-[34px]">
              <img src="../../images/plus-icon-node.svg" alt="plus-icon-node" />
            </button>
          </div>
          <div className="node-block flex items-center justify-center flex-col">
            <div className="node-block-actions absolute -top-[39px] right-[0] flex items-center justify-end z-[1] opacity-0 scale-0 w-[0] h-[0] [transition:0.3s_opacity_ease] pb-[14px]">
              <button
                onClick={handelEnableDisableNode}
                className="cursor-pointer mr-[16px] opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]"
              >
                <img
                  src="../../images/node-action-power.svg"
                  alt="node-action-power"
                />
              </button>
              <button
                onClick={handelCopyNode}
                className="cursor-pointer mr-[16px] opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]"
              >
                <img src="../../images/node-action-copy.svg" alt="node-action-copy" />
              </button>
              <button
                onClick={handelDeleteNode}
                className="cursor-pointer opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]"
              >
                <img
                  src="../../images/node-action-delete.svg"
                  alt="node-action-delete"
                />
              </button>
            </div>
            <div className="node-block-icon-parent w-[70px] h-[70px] flex items-end justify-center">
              <img
                className="node-block-icon"
                src="../../images/edit-node-icon.svg"
                alt="edit-node-icon"
              />
            </div>
            <span className="node-block-title mt-[11px] text-[#60809F] text-center font-[inter-medium] text-[16px] not-italic font-medium leading-[normal]" title={props.data.label}>               
              {props.data.label}
            </span>
          </div>
        </div>
        <div
          onPointerDown={(e) => {
            e.stopPropagation();
          }}
          onDoubleClick={(e) => e.stopPropagation()}
          className="title hidden"
          data-testid="title"
        >
          {label}
        </div>

        {outputs.map(
          ([key, output]) =>
            output && (
              <div className="output" key={key} data-testid={`output-${key}`}>
                <div className="output-title" data-testid="output-title">
                  {output?.label}
                </div>
                <RefSocket
                  name="output-socket"
                  side="output"
                  emit={props.emit}
                  socketKey={key}
                  nodeId={id}
                  payload={output.socket}
                />
              </div>
            )
        )}
        {inputs.map(
          ([key, input]) =>
            input && (
              <div className="input" key={key} data-testid={`input-${key}`}>
                <RefSocket
                  name="input-socket"
                  emit={props.emit}
                  side="input"
                  socketKey={key}
                  nodeId={id}
                  payload={input.socket}
                />
                {input && (!input.control || !input.showControl) && (
                  <div className="input-title" data-testid="input-title">
                    {input?.label}
                  </div>
                )}
                {input?.control && input?.showControl && (
                  <span className="input-control">
                    <RefControl
                      key={key}
                      name="input-control"
                      emit={props.emit}
                      payload={input.control}
                    />
                  </span>
                )}
              </div>
            )
        )}
      </NodeStyles>
    </>
  );
}
