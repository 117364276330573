import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  :hover {
    background: #EEEEEE;
  }
`;

const DotsIcon = styled.img`
  cursor: pointer;
`;

const DropdownMenu = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  background-color: #fff;
  min-width: 170px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  z-index: 1;  
  right: 0;
`;

const DropdownItem = styled.div`  
  padding: 6px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #FFF;
  &:hover {
    background-color: #F7F7F7;
    color: #000;
  }

  img {
    margin-right: 8px;
    max-width: 20px;    
    max-height: 20px;    
  }

  span{
    color: #30313D;
  font-family: inter-medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.6px;
  }
`;

const DotsDropdown = ({ options, iconSrc }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef} className="dots_dropdown-parent">
      <DropdownButton type="button" onClick={toggleDropdown} className="dots_dropdown-button">
        <DotsIcon src={iconSrc} alt="dots icon" />
      </DropdownButton>
      <DropdownMenu isOpen={isOpen} className="dots_dropdown-select">
        {options.map((option, index) => (
          <DropdownItem key={index} className="dots_dropdown-option" onClick={() => {
            option.onClick();
            setIsOpen(false);
          }}>
            {option.imageSrc && <img src={option.imageSrc} alt={`${option.label} icon`} />}
            <span>{option.label}</span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  );
};

DotsDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      imageSrc: PropTypes.string, // Optional image source
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  iconSrc: PropTypes.string.isRequired,
};

export default DotsDropdown;
