// src/MySQLPopup.js
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import "./MySQLPopup.css";
import API from "../api";
import Select from "react-select";
const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  host: yup.string().required("Host is required"),
  database: yup.string().required("Database is required"),
  user: yup.string().required("User is required"),
  password: yup.string().required("Password is required"),
  port: yup.number().default(3306),
  connectTimeout: yup.number().default(10000),
  ssl: yup.boolean().default(false),
  caCertificate: yup.string(),
  clientPrivateKey: yup.string(),
  clientCertificate: yup.string(),
  sshTunnel: yup.boolean().default(false),
  sshAuthenticateWith: yup
    .string()
    .oneOf(["Password", "Key"])
    .default("Password"),
  sshHost: yup.string(),
  sshPort: yup.number().default(22),
  sshMysqlPort: yup.number().default(3306),
  sshUser: yup.string(),
  sshPassword: yup.string(),
  sshKey: yup.string(),
});

const MySQLPopup = ({ isOpen, onClose }) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      host: "",
      database: "",
      userDB: "",
      password: "",
      port: 3306,
      connectTimeout: 10000,
      ssl: false,
      caCertificate: "",
      clientPrivateKey: "",
      clientCertificate: "",
      sshTunnel: false,
      sshAuthenticateWith: "Password",
      sshHost: "",
      sshPort: 22,
      sshMysqlPort: 3306,
      sshUser: "",
      sshPassword: "",
      sshKey: "",
      userId: "6618bfb8ebc89958d978546d",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        let apiData = await API.post("mysql/create-credential", values);
        console.log(apiData);
        onClose();
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    },
  });

  if (!isOpen) return null;

  return (
    <div className="popup-overlay global-popup-overlay mysql-node-popup z-[100] fixed top-[0] left-[0] w-full h-full bg-[rgba(0,_0,_0,_0.5)] flex justify-center items-center">
      <div className="global-popup-main w-[800px] h-[600px] bg-[#fff] rounded-[6px] overflow-hidden relative">
        <div className="global-popup-inn">
          <div className="popup-header bg-[#F5F9FF] px-[29px] flex items-center justify-between h-[60px]">
            <div className="global-popup-head-title text-[#1C1E21] font-['inter-semibold'] text-[18px] not-italic font-medium leading-[28px] -tracking-[0.34px]">
              MySQL account
            </div>
            <button
              className="global-popup-head-close [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50"
              onClick={onClose}
            >
              <img src="../../images/close-popup.svg" alt="popup-close" />
            </button>
          </div>
          <div className="global-popup-body p-[30px]">
            <form onSubmit={formik.handleSubmit}>
              <div className="mysql-node-popup-fields h-[440px] overflow-auto flex flex-wrap items-start justify-between">
                <div className="midform-field mb-[30px] relative w-[48%]">
                  <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                    Name
                  </label>
                  <input
                    className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                      {formik.errors.name}
                    </div>
                  )}
                </div>
                <div className="midform-field mb-[30px] relative w-[48%]">
                  <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                    Host
                  </label>
                  <input
                    className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                    type="text"
                    name="host"
                    value={formik.values.host}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.host && formik.errors.host && (
                    <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                      {formik.errors.host}
                    </div>
                  )}
                </div>
                <div className="midform-field mb-[30px] relative w-[48%]">
                  <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                    Database
                  </label>
                  <input
                    className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                    type="text"
                    name="database"
                    value={formik.values.database}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.database && formik.errors.database && (
                    <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                      {formik.errors.database}
                    </div>
                  )}
                </div>
                <div className="midform-field mb-[30px] relative w-[48%]">
                  <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                    User
                  </label>
                  <input
                    className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                    type="text"
                    name="user"
                    value={formik.values.user}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.user && formik.errors.user && (
                    <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                      {formik.errors.user}
                    </div>
                  )}
                </div>
                <div className="midform-field mb-[30px] relative w-[48%]">
                  <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                    Password
                  </label>
                  <input
                    className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                      {formik.errors.password}
                    </div>
                  )}
                </div>
                <div className="midform-field mb-[30px] relative w-[48%]">
                  <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                    Port
                  </label>
                  <input
                    className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                    type="number"
                    name="port"
                    value={formik.values.port}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.port && formik.errors.port && (
                    <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                      {formik.errors.port}
                    </div>
                  )}
                </div>
                <div className="midform-field mb-[30px] relative w-[48%]">
                  <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                    Connect Timeout
                  </label>
                  <input
                    className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                    type="number"
                    name="connectTimeout"
                    value={formik.values.connectTimeout}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.connectTimeout &&
                    formik.errors.connectTimeout && (
                      <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                        {formik.errors.connectTimeout}
                      </div>
                    )}
                </div>
                <div className="midform-field relative w-full border-t [border-top-style:solid] [border-top-color:rgba(189,_202,_215,_0.3)]">
                  <div className="midform-input-fields-switch node-comm-switch relative py-[23px] flex items-center justify-start">
                    <div className="text-[#000] font-['inter-medium'] text-[12px] not-italic font-medium leading-[normal] opacity-80 mr-[11px]">
                      SSL
                    </div>
                    <div className="node-comm-real-switch relative">
                      <input
                        type="checkbox"
                        id="mysql-ssl-switch"
                        className="absolute w-full h-full opacity-0 cursor-pointer top-[0] left-[0] z-[1]"
                        name="ssl"
                        checked={formik.values.ssl}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label
                        className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative"
                        htmlFor="send-query-parameters-switch"
                      >
                        Toggle
                      </label>
                    </div>
                  </div>
                </div>
                {formik.values.ssl && (
                  <>
                    <div className="midform-field mb-[30px] relative w-[48%]">
                      <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                        CA Certificate
                      </label>
                      <input
                        className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                        type="text"
                        name="caCertificate"
                        value={formik.values.caCertificate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.caCertificate &&
                        formik.errors.caCertificate && (
                          <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                            {formik.errors.caCertificate}
                          </div>
                        )}
                    </div>
                    <div className="midform-field mb-[30px] relative w-[48%]">
                      <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                        Client Private Key
                      </label>
                      <input
                        className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                        type="text"
                        name="clientPrivateKey"
                        value={formik.values.clientPrivateKey}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.clientPrivateKey &&
                        formik.errors.clientPrivateKey && (
                          <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                            {formik.errors.clientPrivateKey}
                          </div>
                        )}
                    </div>
                    <div className="midform-field mb-[30px] relative w-[48%]">
                      <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                        Client Certificate
                      </label>
                      <input
                        className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                        type="text"
                        name="clientCertificate"
                        value={formik.values.clientCertificate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.clientCertificate &&
                        formik.errors.clientCertificate && (
                          <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                            {formik.errors.clientCertificate}
                          </div>
                        )}
                    </div>
                  </>
                )}
                <div className="midform-field relative w-full border-t [border-top-style:solid] [border-top-color:rgba(189,_202,_215,_0.3)]">
                  <div className="midform-input-fields-switch node-comm-switch relative  py-[23px] flex items-center justify-start w-full">
                    <div className="text-[#000] font-['inter-medium'] text-[12px] not-italic font-medium leading-[normal] opacity-80 mr-[11px]">
                      SSH Tunnel
                    </div>
                    <div className="node-comm-real-switch relative">
                      <input
                        id="mysql-ssh-switch"
                        type="checkbox"
                        className="absolute w-full h-full opacity-0 cursor-pointer top-[0] left-[0] z-[1]"
                        name="sshTunnel"
                        checked={formik.values.sshTunnel}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label
                        className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative"
                        htmlFor="send-query-parameters-switch"
                      >
                        Toggle
                      </label>
                    </div>
                  </div>
                </div>
                {formik.values.sshTunnel && (
                  <>
                    <div className="midform-field mb-[30px] relative w-[48%]">
                      <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                        SSH Authenticate with
                      </label>
                      <Select
                        className="midform-select-main"
                        classNamePrefix="midform-select"
                        name="sshAuthenticateWith"
                        value={formik.values.sshAuthenticateWith}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="Password">Password</option>
                        <option value="Key">Key</option>
                      </Select>
                      {formik.touched.sshAuthenticateWith &&
                        formik.errors.sshAuthenticateWith && (
                          <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                            {formik.errors.sshAuthenticateWith}
                          </div>
                        )}
                    </div>
                    <div className="midform-field mb-[30px] relative w-[48%]">
                      <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                        SSH Host
                      </label>
                      <input
                        className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                        type="text"
                        name="sshHost"
                        value={formik.values.sshHost}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.sshHost && formik.errors.sshHost && (
                        <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                          {formik.errors.sshHost}
                        </div>
                      )}
                    </div>
                    <div className="midform-field mb-[30px] relative w-[48%]">
                      <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                        SSH Port
                      </label>
                      <input
                        className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                        type="number"
                        name="sshPort"
                        value={formik.values.sshPort}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.sshPort && formik.errors.sshPort && (
                        <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                          {formik.errors.sshPort}
                        </div>
                      )}
                    </div>
                    <div className="midform-field mb-[30px] relative w-[48%]">
                      <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                        SSH MySQL Port
                      </label>
                      <input
                        className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                        type="number"
                        name="sshMysqlPort"
                        value={formik.values.sshMysqlPort}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.sshMysqlPort &&
                        formik.errors.sshMysqlPort && (
                          <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                            {formik.errors.sshMysqlPort}
                          </div>
                        )}
                    </div>
                    <div className="midform-field mb-[30px] relative w-[48%]">
                      <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                        SSH User
                      </label>
                      <input
                        className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                        type="text"
                        name="sshUser"
                        value={formik.values.sshUser}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.sshUser && formik.errors.sshUser && (
                        <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                          {formik.errors.sshUser}
                        </div>
                      )}
                    </div>
                    {formik.values.sshAuthenticateWith === "Password" && (
                      <div className="midform-field mb-[30px] relative w-[48%]">
                        <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                          SSH Password
                        </label>
                        <input
                          className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                          type="password"
                          name="sshPassword"
                          value={formik.values.sshPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.sshPassword &&
                          formik.errors.sshPassword && (
                            <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                              {formik.errors.sshPassword}
                            </div>
                          )}
                      </div>
                    )}
                    {formik.values.sshAuthenticateWith === "Key" && (
                      <div className="midform-field mb-[30px] relative w-[48%]">
                        <label className="midform-field-label block text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                          SSH Key
                        </label>
                        <input
                          className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                          type="text"
                          name="sshKey"
                          value={formik.values.sshKey}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.sshKey && formik.errors.sshKey && (
                          <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                            {formik.errors.sshKey}
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="popup-footer">
                <button
                  type="submit"
                  className="w-[100px] mt-[15px] [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 [box-shadow:none] h-[34px] rounded-[4px] bg-[#006DE0] border-0 !px-[12px] !py-[0] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MySQLPopup;
