import { ClassicPreset } from "rete";
import { DataflowEngine, DataflowNode } from "rete-engine";
import axios from "axios";
import { Schemes } from "../rete/types";
import { TextSocket } from "../editor";
import { getDropValue, isExpression, isValidJsonCustom } from "../utils";
import { toast } from "react-toastify";

const socket = new ClassicPreset.Socket("socket");
class HttpNodeWithCustomTemplete
  extends ClassicPreset.Node<
    {
      input: ClassicPreset.Socket;
    },
    {
      output: ClassicPreset.Socket;
    },
    {
      nodeformData: ClassicPreset.InputControl<"text">; 
    }
  >
  implements DataflowNode
{
  [x: string]: any;
  width = 600;
  height = 600;
  httpData: any;
  response: any;
  isNodeEnable=true
  nodeData={
    imgSrc:"../../images/http-node-icon.svg",
    name: "HTTP Request", 
    description:"HTTP Request"
  }
  constructor(
    public dataflow: DataflowEngine<Schemes>,
    public onClick: (jsonData: any) => void,
    private log: (text: string) => void,
    public editor
  ) {
    super("HTTP Request"); 
    this.addControl(
      "nodeformData",
      new ClassicPreset.InputControl("text", {})
    ); 
    this.addInput("input", new ClassicPreset.Input(new TextSocket(), "input")); 
    this.addOutput(
      "output",
      new ClassicPreset.Output(new TextSocket(), "Output")
    );
  }
  worker(node: any, inputs: any) {
    
  }
  data(): { output: any } {
    return {
      output: this.httpData,
    };
  }
   
  validateRequestConfig(config) {
    const errors = [];

    // Validate non-empty strings
    ["url", "methord"].forEach(
      (key) => {
        if (typeof config[key] !== "string" || config[key].trim() === "") {
          errors.push(`Invalid or missing '${key}'.`);
        }
      }
    );  
    return errors.length > 0 ? errors : null;
  } 

  

  async execute(
    input: "input",
    forward: (output: "output", data?: any) => void
  ) {
      
  }
   
}

export default HttpNodeWithCustomTemplete;
