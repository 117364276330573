import React from "react";

const DynamicTable = ({ data }) => {
  // Function to extract all unique keys as column headers
  const getHeaders = (data) => {
    const headers = new Set();
    data.forEach((item) => {
      if (item && typeof item === "object") { // Check if item is a valid object
        Object.keys(item).forEach((key) => {
          if (typeof item[key] === "object" && item[key] !== null) {
            Object.keys(item[key]).forEach((subKey) => {
              headers.add(`${key}.${subKey}`); // Handle nested objects
            });
          } else {
            headers.add(key);
          }
        });
      }
    });
    return Array.from(headers);
  };

  const formatCell = (item, header) => {
    const value = item[header];
    if (value && typeof value === "object" && !Array.isArray(value)) {
      // Convert object to multiline string
      return Object.entries(value)
        .map(([key, val]) => `${key}: ${val}`)
        .join("\n");
    }
    return value ? value.toString() : "[undefined]";
  };

  // Filter out invalid data
  const validData = Array.isArray(data)
    ? data.filter((item) => item && typeof item === "object")
    : [];

  const headers = getHeaders(validData);

  return (
    <table className="border-[1px] border-[solid] border-[rgba(0,0,0,0.14)] border-r-[0] absolute top-[0] left-[0]">
      <thead>
        <tr>
          {headers.map((header) => (
            <td
              className="bg-[#fff] border-r [border-right-style:solid] [border-right-color:rgba(0,0,0,0.14)]"
              key={header}
            >
              <span className="block pl-[12px] pr-[34px] py-[8px] text-[#000] font-['inter-regular'] text-[11px] not-italic font-normal leading-[normal] whitespace-nowrap">
                {header.split(".")[1] ? header.split(".")[1] : header}
              </span>
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {validData.map((item, index) => (
          <tr key={index}>
            {headers.map((header) => {
              const [mainKey, subKey] = header.split(".");
              const value = subKey ? item[mainKey]?.[subKey] : item[mainKey];
              return (
                <td
                  key={`${index}_${header}`}
                  className="border-r [border-right-style:solid] [border-right-color:rgba(0,0,0,0.14)] align-top"
                >
                  <span className="block px-[12px] py-[8px] text-[rgba(0,0,0,0.5)] font-['inter-regular'] text-[11px] not-italic font-normal leading-[normal]">
                    {typeof value === "object"
                      ? Object.entries(value).map(([key, val], index) => (
                          <div key={index}>
                            <span>{key}</span> <span>:</span> <span>{val}</span>
                          </div>
                        ))
                      : value}
                  </span>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DynamicTable;
