import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const CommonHeader = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Workflows"); // Default active tab

  useEffect(() => {
    // Update active tab based on the current path
    if (location.pathname.includes("credentials")) {
      setActiveTab("Credentials");
    } else if (location.pathname.includes("workflow")) {
      setActiveTab("Workflows");
    } else if (location.pathname.includes("custom-node")) {
      setActiveTab("CustomNode");
    } else if (location.pathname.includes("users")) {
      setActiveTab("Users");
    }
  }, [location.pathname]); // Re-run effect if path changes

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="common-header-parent px-[40px] pt-[20px]">
      <div className="common-header relative w-full flex items-start justify-start bg-[#fff] rounded-[6px] z-0">
        
          <Link
            to="/home/workflow/"
            className={`tab px-[20px] py-[10px] flex items-center justify-center ${activeTab === "Workflows" ? "active" : ""}`}
            onClick={() => handleTabClick("Workflows")}
          >
            <img src="../../images/workflow-head-icon.svg" alt="workflow-head-icon" />
            <span className="ml-[10px] text-[#1A1C1E] font-['inter-semibold'] text-[16px] not-italic font-normal leading-[22.4px]">Workflows</span>
          </Link>
          <Link
            to="/home/credentials/"
            className={`tab px-[20px] py-[10px] flex items-center justify-center ${activeTab === "Credentials" ? "active" : ""}`}
            onClick={() => handleTabClick("Credentials")}
          >
            <img src="../../images/credentials-head-icon.svg" alt="credentials-head-icon" />
            <span className="ml-[10px] text-[#1A1C1E] font-['inter-semibold'] text-[16px] not-italic font-normal leading-[22.4px]">Credentials</span>
          </Link>
        
        <Link
            to="/home/custom-node/"
          className={`tab px-[20px] py-[10px] cursor-pointer flex items-center justify-center ${activeTab === "CustomNode" ? "active" : ""}`}
          onClick={() => handleTabClick("CustomNode")}
        >
          <img src="../../images/custom-node-head-icon.svg" alt="custom-node-head-icon" />
          <span className="ml-[10px] text-[#1A1C1E] font-['inter-semibold'] text-[16px] not-italic font-normal leading-[22.4px]">Custom node</span>
        </Link>
        <Link
        to="/home/users/"
          className={`tab px-[20px] py-[10px] cursor-pointer flex items-center justify-center ${activeTab === "Users" ? "active" : ""}`}
          onClick={() => handleTabClick("Users")}
        >
          <img src="../../images/users-head-icon.svg" alt="users-head-icon" />
          <span className="ml-[10px] text-[#1A1C1E] font-['inter-semibold'] text-[16px] not-italic font-normal leading-[22.4px]">Users</span>
        </Link>
        <div className="indicator absolute -bottom-[1px] h-[3px] w-[100px] bg-[#006DE0]" />
      </div>
    </div>
  );
};

export default CommonHeader;
