import React, { useState } from "react";
import DotsDropdown from './DotsDropdown';

const FixedExpressionTabs = ({ children, onExpressionChange }) => {
  const [tab, setTab] = useState("fixed");

  const handleTabChange = (newTab) => {
    setTab(newTab);
   // if (newTab === "fixed") {
      onExpressionChange(newTab); // Reset to default when switched to fixed
    //}
  };
  const dotoptions = [
    {
      label: 'Reset Value',
      onClick: () => {console.log('Reset Value clicked')},
    }
  ];
  const iconSrc = '../../images/midform-3dots.svg';
  return (
    <div className="midform-field-actions-parent">
      <div className="midform-field-actions absolute -top-[6px] right-0 flex items-center justify-start z-[1]">       
        <DotsDropdown options={dotoptions} iconSrc={iconSrc} />
        <div className="tabs midform-field-action-wrap bg-[#EEEEEE] rounded-[4px] flex items-center justify-center">
            <button type="button" className={`tab midform-field-action midform-field-action-fixed px-[6px] py-[4px] rounded-[4px]
                text-[rgba(53,53,53,0.7)] font-['inter-regular'] text-[10px] not-italic font-normal leading-[normal] mr-[3px]
                ${tab==="fixed" ? "active" : "" }`} onClick={()=> handleTabChange("fixed")}
                >
                Fixed
            </button>
            <button type="button" className={`tab midform-field-action midform-field-action-express px-[6px] py-[4px] rounded-[4px]
                text-[rgba(53,53,53,0.7)] font-['inter-regular'] text-[10px] not-italic font-normal leading-[normal]
                ${tab==="expression" ? "active" : "" }`} onClick={()=> handleTabChange("expression")}
                >
                Expression
            </button>
        </div>
    </div>  
      <div className="tab-content">
        {React.cloneElement(children, { tab, setTab })}
      </div>
    </div>
  );
};

export default FixedExpressionTabs;
