import React, { useState, useEffect } from 'react';
import CommonSidebar from './common-sidebar';

const CommonLayout = ({children}) => {
    
    return (
        <>
             
            <CommonSidebar />
            {children}
            
        </>
    );
};

export default CommonLayout;
