import { useCallback, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import DotsDropdown from "./components/DotsDropdown";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Canvas from "./view/Canvas";
import WorkFlow from "./view/home/workflows";
import NotFoundPage from "./view/404/404";
import Credentials from "./view/home/credentials";
import CustomNode from "./view/home/custom-node";
import Users from "./view/home/users";
import Signin from "./view/auth/signin";
import Signup from "./view/auth/signup";
import Forgot from "./view/auth/forgot";
import Reset from "./view/auth/reset";
import { ToastContainer } from "react-toastify";
import { AuthProvider, useAuth } from "./context/AuthContext";

// Protected Route: Only accessible when authenticated
const ProtectedRoute = ({ element: Component }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Component /> : <Navigate to="/auth/signin" />;
};

// Public Route: Accessible to all users, regardless of authentication
const PublicRoute = ({ element: Component }) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Navigate to="/home/workflow" /> : <Component />;
}; 

export interface VisibilityState {
  isVisible: boolean;
}

export interface RootState {
  visibility: VisibilityState;
}

export default function App() {
  return (
    <>
      <AuthProvider>
        <ToastContainer />
        <Router>
          <div>
            <Routes>
              <Route path="/" element={<Navigate to="/home/workflow" />} />
              <Route path="/auth">
                <Route path="signin" element={<PublicRoute element={Signin} />} />
                <Route path="signup" element={<PublicRoute element={Signup} />} />
                <Route path="forgot" element={<PublicRoute element={Forgot} />} />
                <Route path="reset" element={<PublicRoute element={Reset} />} />
              </Route>
              <Route path="/home">
               {/*  <Route path="workflow" element={<ProtectedRoute element={WorkFlow} />} /> */}
                <Route path="workflow" element={<ProtectedRoute element={WorkFlow} />} />
                <Route path="workflow/new" element={<ProtectedRoute element={Canvas} />} />
                <Route path="workflow/:id" element={<ProtectedRoute element={Canvas} />} />
                <Route path="credentials" element={<ProtectedRoute element={Credentials} />} />
                <Route path="custom-node" element={<ProtectedRoute element={CustomNode} />} />
                <Route path="users" element={<ProtectedRoute element={Users} />} />
              </Route>
              <Route path="/about" element={<ProtectedRoute element={Canvas} />} />
              <Route path="/contact" element={<ProtectedRoute element={Canvas} />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </Router>
      </AuthProvider>
    </>
  );
}
