import { ClassicPreset } from "rete";
import { DataflowEngine, DataflowNode } from "rete-engine";
import axios from "axios";
import { Schemes } from "../rete/types";
import { TextSocket } from "../editor";
import { getDropValue, isExpression, isValidJsonCustom } from "../utils";

const socket = new ClassicPreset.Socket("socket");
class MySqlNode
  extends ClassicPreset.Node<
    {
      input: ClassicPreset.Socket;
    },
    {
      output: ClassicPreset.Socket;
    },
    {
      url: ClassicPreset.InputControl<"text">;
      method: ClassicPreset.InputControl<"text">;
      dataJson: ClassicPreset.InputControl<"text">;
    }
  >
  implements DataflowNode
{
  [x: string]: any;
  width = 600;
  height = 600;
  httpData: any;
  response: any;
  isNodeEnable=true
  nodeData={
    imgSrc:"../../images/mysql.svg",
    name: "Mysql", 
    description:"Mysql"
  }
  constructor(
    public dataflow: DataflowEngine<Schemes>,
    public onClick: (jsonData: any) => void,
    private log: (text: string) => void,
    public editor
  ) {
    super("MySQL");

    this.addControl(
      "url",
      new ClassicPreset.InputControl("text", {
        initial: "Enter Webhook url",
      })
    );

    this.addControl("dataJson", new ClassicPreset.InputControl("text", {}));

    const control2 = new ClassicPreset.InputControl("text", {
      initial: "GET",
      change(value) {
        console.log(value);
      },
    });

    this.addInput("input", new ClassicPreset.Input(new TextSocket(), "input"));
    this.addControl("method", control2);
    this.addOutput(
      "output",
      new ClassicPreset.Output(new TextSocket(), "Output")
    );
  }
  worker(node: any, inputs: any) {
    console.log('worker',inputs);
    console.log(inputs);
  }
  data(): { output: any } {
    return {
      output: this.httpData,
    };
  }
   
  validateRequestConfig(config) {
    const errors = [];

    // Validate non-empty strings
    ["url", "methord"].forEach(
      (key) => {
        if (typeof config[key] !== "string" || config[key].trim() === "") {
          errors.push(`Invalid or missing '${key}'.`);
        }
      }
    );  
    return errors.length > 0 ? errors : null;
  }
  

  

  async execute(
    input: "input",
    forward: (output: "output", data?: any) => void
  ) {
    let formData = this.controls.dataJson.value?JSON.parse(this.controls.dataJson.value as string):[];
    let inputData;
    const inputs = this.dataflow
    .fetchInputs(this.id)
    .then((val) => {
      console.log(val?.input);
      if (isValidJsonCustom(val?.input)) { 
        inputData=JSON.parse(val?.input);
      } else if (typeof val?.input == "object") {
        inputData=val?.input;
      }
    }); 
}
   
}

export default MySqlNode;
