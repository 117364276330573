import React, { useState } from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
 

const NotFoundPage = () => {
return (
    <>
        <div className="NotFoundPage">
            <h1>NotFoundPage</h1>
        </div>
    </>
)
}
export default NotFoundPage;