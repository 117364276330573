import * as React from "react";
import { Schemes } from "../rete/types";
import { ClassicScheme, RenderEmit, Presets } from "rete-react-plugin";
import styled, { css } from "styled-components";
import HttpNodeWithCustomTemplete from "../customization/HttpNodeWithCutomTemplete";
import { DndProvider } from "react-dnd";
import Popup from "reactjs-popup";
import { HTML5Backend } from "react-dnd-html5-backend";
import { copyNodeWithConnectionsCopy, deleteNodes } from "../editor";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Select from "react-select";
import { getInputConnectedNodes, isValidJsonCustom } from "../utils";
import DynamicTable from "../components/Dynamictable";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import RecursiveComponent from "../components/RecursiveComponent";
import HttpRequestForm from "../components/form/HttpRequestForm";

const { RefSocket, RefControl } = Presets.classic;
export const $nodewidth = 200;
export const $socketmargin = 6;
export const $socketsize = 16;
type NodeExtraData = { width?: number; height?: number };

type Props<S extends ClassicScheme> = {
  data: HttpNodeWithCustomTemplete & NodeExtraData;
  emit: RenderEmit<S>;
};

export const NodeStyles = styled.div<
  NodeExtraData & { selected: boolean; styles?: (props: any) => any }
>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 2px solid #d2dbe4;
  border-radius: 40px;
  border-radius: 40px;
  cursor: pointer;
  box-sizing: border-box;
  // width: ${(props) =>
    Number.isFinite(props.width) ? `${props.width}px` : `${$nodewidth}px`};
  width: 166px;
  height: 166px;
  padding-bottom: 6px;
  position: relative;
  user-select: none;
  // &:hover {
  //   background: #333;
  // }
  &:focus {
    outline: 8px solid #dfe5ec;
  }
  ${(props) =>
    props.selected &&
    css`
      outline: 8px solid #dfe5ec;
    `}
  .output {
    text-align: right;
  }
  .input {
    text-align: left;
  }
  .output-socket {
    display: block;
    position: absolute;
    right: -24px;
    height: 24px;
    width: 24px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    border-radius: 100%;
    background: #a5adba;
  }
  .input-socket {
    display: block;
    position: absolute;
    left: -13px;
    height: 24px;
    width: 11px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    background: #a5adba;
  }
  .input-title,
  .output-title {
    vertical-align: middle;
    color: white;
    display: none;
    font-family: sans-serif;
    font-size: 14px;
    margin: ${$socketmargin}px;
    line-height: ${$socketsize}px;
  }
  .input-control {
    z-index: 1;
    width: calc(100% - ${$socketsize + 2 * $socketmargin}px);
    vertical-align: middle;
    display: inline-block;
  }
  .sc-jsJBEP.ciTnqI {
    height: auto !important;
    width: 250px !important;
  }
  .control {
    display: block;
    padding: ${$socketmargin}px ${$socketsize / 2 + $socketmargin}px;
  }

  div[title="socket"] {
    display: none;
  }
  ${(props) => props.styles && props.styles(props)}
`;
export function HttpRequestCustomNode(props: Props<Schemes>) {
  const [showpopUp, setShowpopUp] = React.useState(false);
  const [label, setLabel] = React.useState(props.data.label);
  const [isNodeEnable, setIsNodeEnable] = React.useState(true);
  const [inputOption, setInputOption] = React.useState([]);
  const [inputData, setInputData] = React.useState([]);
  const [tabIndexOutput, setTabIndexOutput] = React.useState(0);
  const [outPutData, setOutputData] = React.useState([]);
  const [editOutputJson, setEditOutputJson] = React.useState(false);
  const [isValidJson, setIsValidJson] = React.useState(true);
  const { id, width, height } = props.data;
  const selected = props.data.selected || false;
  const inputs = Object.entries(props.data.inputs);
  const outputs = Object.entries(props.data.outputs);

  const toggleVisibility = (show) => {
    const actions = document.querySelector(
      ".workflow-view-actions-inn"
    ) as HTMLElement | null;
    const sidebar = document.querySelector(
      ".workflow-sidebar"
    ) as HTMLElement | null;
    if (show) {
      if (actions) {
        actions.style.display = actions.style.display === "none" ? "" : "none";
      }
      if (sidebar) {
        sidebar.style.display = sidebar.style.display === "none" ? "" : "none";
      }
    } else if (!show) {
      if (actions) {
        actions.style.display = "";
      }
      if (sidebar) {
        sidebar.style.display = "";
      }
    }
  };

  const closeModal = () => {
    setShowpopUp(false);
    toggleVisibility(false);
  };
  function handelDeleteNode() {
    deleteNodes(props.data.id);
  }
  function handelEnableDisableNode() {
    console.log("enable disable");
    setIsNodeEnable((prv) => !prv);
    props.data.isNodeEnable = isNodeEnable;
  }
  function handelCopyNode() {
    copyNodeWithConnectionsCopy(props.data.id, props.data.editor);
  }
  function handelJsonedit() {
    setEditOutputJson((prev) => !prev);
    setTabIndexOutput(1);
  }
  React.useEffect(() => {
    console.log(props.data?.editor);
    if (props.data?.editor) {
      let inputOption = getInputConnectedNodes(props.data?.editor);
      inputOption = inputOption.map((val) => ({
        value: val.id,
        label: val.label,
      }));
      setInputOption(inputOption);
      const inputs = props.data.dataflow
        .fetchInputs(props.data.id)
        .then((val) => {
          console.log(val);
          if (isValidJsonCustom(val?.input)) { 
            setInputData(JSON.parse(val?.input));
          } else if (typeof val?.input == "object") {
            setInputData(val?.input);
          }
        });
    }
  }, [showpopUp]);
  const handleDrop = (item) => {
    console.log("Dropped item:", item);
  };
  function HandelOnselectTab(index) {
    setTabIndexOutput(index);
    setEditOutputJson(false);
  }
  function handelSaveOutputJson() {
    if (isValidJson) {
      props.data.httpData = outPutData;
      setEditOutputJson(false);
    }
  }
  const handleJsonChange = (data) => {
    if (data.jsObject) {
      setOutputData(data.jsObject);
      setIsValidJson(true);
    } else {
      // If data.jsObject is undefined, the JSON is invalid
      setIsValidJson(false);
    }
  };
  const handleFormSubmit = (formData) => {
    console.log("Received form data:", formData);
    // You can perform additional actions with formData here, like sending it to an API
  };
  const options2 = [
    { value: 'Get', label: 'Get' },
    { value: 'Post', label: 'Post' },
    // Add other HTTP methods as needed
  ];

  const options3 = [
    { value: 'Using JSON', label: 'Using JSON' },
    { value: 'Using Fields Below', label: 'Using Fields Below' },
    // Add other options as needed
  ];

  // Sample inputData for form fields
  const formInputData = {
    url: 'https://example.com',
    method: 'Get',
    // Add other initial data as needed
  };

  return (
    <>
      <Popup open={showpopUp} className="code-node-popup" onClose={closeModal}>
        <DndProvider backend={HTML5Backend}>
          <div className="modal">
            <div className="node-popup webhook-node-popup fixed top-[70px] left-[0] w-full bg-[#F4F1FA] h-[calc(100%_-_70px)] z-[11]">
              <div className="node-popup-inn">
                <div className="node-popup-back border-b [border-bottom-style:solid] [border-bottom-color:#BECBD8] h-[50px] bg-[#eee] px-[29px] py-[0] flex items-center justify-start">
                  <button
                    onClick={closeModal}
                    className="flex items-center justify-center border-[0] p-0 h-auto rounded-none bg-transparent [box-shadow:none]"
                  >
                    <img
                      src="../../images/node-popup-back.svg"
                      alt="node-popup-back"
                    />
                    <span className="text-[#000] font-['Inter-Regular'] text-[12px] not-italic font-normal leading-[normal] ml-[4px]">
                      Back to Canvas
                    </span>
                  </button>
                </div>
                <div className="node-popup-views flex h-[calc(100vh_-_120px)]">
                  <div className="node-popup-view node-popup-input-view [flex-basis:33.33%] w-1/3">
                    <div className="node-popup-output-view-inn px-[30px] py-[26px]">
                      <div className="node-popup-output-tabs">
                        <Tabs>
                          <div className="node-popup-output-tabs-wrap flex items-center justify-between mb-[26px]">
                            <div className="node-popup-output-head flex items-center justify-start">
                              <div className="node-popup-output-title mr-[16px] text-[#1E075F] font-['Inter-SemiBold'] text-[16px] not-italic font-semibold leading-[normal] tracking-[1.92px] uppercase">
                                Input
                              </div>
                              <Select
                                className="midform-select-main"
                                classNamePrefix="midform-select"
                                options={inputOption}
                                defaultValue={inputOption}
                              />
                            </div>
                            <TabList className="flex items-center justify-end">
                              <div className="node-popup-output-tabs-inn flex items-center justify-end rounded-[4px] bg-[#fff] p-[5px]">
                                <Tab className="[transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['Inter-Regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                  Table
                                </Tab>
                                <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['Inter-Regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                  JSON
                                </Tab>
                                <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['Inter-Regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                  Schema
                                </Tab>
                              </div>
                            </TabList>
                          </div>
                          <div className="node-popup-output-tab-panels">
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_235px)]">
                                  <DynamicTable data={inputData} />
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-json-view node-json-view-readonly webhook-node-json-view h-[calc(100vh_-_235px)]">
                              <JSONInput
                                id="webhook-json"
                                placeholder={inputData}
                                locale={locale}
                                theme="light_mitsuketa_tribute"
                                colors={{
                                  default: "#39ADB5",
                                  keys: "#E53935",
                                  string: "#91B859",
                                  number: "#F76D47",
                                  background: "#F7F7F7",
                                }}
                                width={"100%"}
                                height={"100%"}
                                style={{
                                  outerBox: {
                                    borderRadius: "6px",
                                    border: "1px solid #CCC",
                                    overflow: "hidden",
                                    background: "#F7F7F7",
                                  },
                                }}
                              />
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-schema-view webhook-node-schema-view">
                              <div className="node-schema-tree-view webhook-node-schema-tree-view h-[calc(100vh_-_235px)] overflow-auto">
                                {inputData.map((item, index) => (
                                  <RecursiveComponent
                                    key={index}
                                    item={item}
                                    onFieldDrop={handleDrop}
                                    draggable={true}
                                  />
                                ))}
                              </div>
                            </TabPanel>
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                  <div className="node-popup-view node-popup-fields-view [flex-basis:33.33%] w-1/3 bg-[#fff]">
                    <div className="node-popup-midform">
                      <HttpRequestForm
                        onSubmit={handleFormSubmit}
                        options2={options2}
                        options3={options3}
                        inputData={formInputData}
                      />
                    </div>
                  </div>
                  <div className="node-popup-view node-popup-output-view [flex-basis:33.33%] w-1/3">
                    <div className="node-popup-output-view-inn px-[30px] py-[26px]">
                      <div className="node-popup-output-tabs">
                        <Tabs
                          selectedIndex={tabIndexOutput}
                          onSelect={(index) => HandelOnselectTab(index)}
                        >
                          <div className="node-popup-output-tabs-wrap flex items-center justify-between mb-[26px]">
                            <div className="node-popup-output-title text-[#1E075F] font-['Inter-SemiBold'] text-[16px] not-italic font-semibold leading-[normal] tracking-[1.92px] uppercase">
                              Output
                            </div>
                            <TabList className="flex items-center justify-end">
                              {!editOutputJson ? (
                                <>
                                  <div className="node-popup-output-tabs-inn flex items-center justify-end rounded-[4px] bg-[#fff] p-[5px]">
                                    <Tab className="[transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['Inter-Regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                      Table
                                    </Tab>
                                    <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['Inter-Regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                      JSON
                                    </Tab>
                                    <Tab className="ml-[5px] [transition:0.3s_all_ease] cursor-pointer rounded-[4px] px-[10px] py-[6px] font-['Inter-Regular'] text-[12px] not-italic font-normal leading-[normal] text-[rgba(0,0,0,0.7)] hover:bg-[#8099b2] hover:text-[#fff]">
                                      Schema
                                    </Tab>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[Inter-Regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                    onClick={handelJsonedit}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[Inter-Regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#1E075F] rounded-[4px]"
                                    onClick={handelSaveOutputJson}
                                    disabled={!isValidJson}
                                  >
                                    Save
                                  </button>
                                </>
                              )}

                              {!editOutputJson && (
                                <button
                                  className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center ml-[8px] rounded-[4px] bg-[#fff] w-[36px] h-[36px]"
                                  onClick={handelJsonedit}
                                >
                                  <img
                                    src="../../images/node-popup-edit.svg"
                                    alt="node-popup-edit"
                                  />
                                </button>
                              )}
                            </TabList>
                          </div>
                          <div className="node-popup-output-tab-panels">
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_235px)]">
                                  {outPutData != null ||
                                  outPutData != undefined ? (
                                    <DynamicTable data={outPutData} />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel
                              className={`node-popup-output-tab-panel node-json-view ${
                                editOutputJson ? "" : "node-json-view-readonly"
                              } webhook-node-json-view h-[calc(100vh_-_235px)]`}
                            >
                              <JSONInput
                                id="webhook-json"
                                placeholder={outPutData}
                                locale={locale}
                                theme="light_mitsuketa_tribute"
                                colors={{
                                  default: "#39ADB5",
                                  keys: "#E53935",
                                  string: "#91B859",
                                  number: "#F76D47",
                                  background: "#F7F7F7",
                                }}
                                width={"100%"}
                                height={"100%"}
                                style={{
                                  outerBox: {
                                    borderRadius: "6px",
                                    border: "1px solid #CCC",
                                    overflow: "hidden",
                                    background: "#F7F7F7",
                                  },
                                }}
                                viewOnly={!editOutputJson}
                                onChange={handleJsonChange}
                              />
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-schema-view webhook-node-schema-view">
                              <div className="node-schema-tree-view webhook-node-schema-tree-view h-[calc(100vh_-_235px)] overflow-auto">
                                {outPutData != null || outPutData != undefined
                                  ? outPutData.map((item, index) => (
                                      <RecursiveComponent
                                        key={index}
                                        item={item}
                                        onFieldDrop={handleDrop}
                                        draggable={false}
                                      />
                                    ))
                                  : ""}
                              </div>
                            </TabPanel>
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DndProvider>
      </Popup>

      <NodeStyles
        selected={selected}
        width={166}
        height={166}
        className="nodeWrap node-comm node-http"
        data-testid="node"
      >
        <div
          className="node-block-parent"
          onPointerDown={(e) => {
            e.stopPropagation();
          }}
          onDoubleClick={(e) => {
            setShowpopUp((pop) => !pop);
            toggleVisibility(true);
            e.stopPropagation();
          }}
        >
          <div className="node-block-plus-actions flex items-center justify-center absolute left-[185px] top-[0] bottom-[0] m-auto flex items-center justify-start h-[34px] leading-0">
            <div className="node-block-plus-actions-arrow flex items-center justify-center relative">
              <div className="node-block-plus-actions-arrow-line bg-[#919394] h-[2px] w-[105px]"></div>
              <div className="node-block-plus-actions-arrow-text text-[#60809F] text-center font-[Inter-Regular] text-[14px] not-italic font-normal leading-[normal] bg-[#F2F5F7] absolute left-[0] right-[0] m-auto block w-[40px]">
                GET
              </div>
            </div>
            <button className="node-block-plus-actions-btn w-[34px] h-[34px]">
              <img src="../../images/plus-icon-node.svg" alt="plus-icon-node" />
            </button>
          </div>
          <div className="node-block flex items-center justify-center flex-col">
            <div className="node-block-actions absolute -top-[39px] right-[0] flex items-center justify-end z-[1] opacity-0 scale-0 w-[0] h-[0] [transition:0.3s_opacity_ease] pb-[14px]">
              <button
                onClick={handelEnableDisableNode}
                className="cursor-pointer mr-[16px] opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]"
              >
                <img
                  src="../../images/node-action-power.svg"
                  alt="node-action-power"
                />
              </button>
              <button
                onClick={handelCopyNode}
                className="cursor-pointer mr-[16px] opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]"
              >
                <img
                  src="../../images/node-action-copy.svg"
                  alt="node-action-copy"
                />
              </button>
              <button
                onClick={handelDeleteNode}
                className="cursor-pointer opacity-[0.5] hover:opacity-[1] [transition:0.3s_opacity_ease]"
              >
                <img
                  src="../../images/node-action-delete.svg"
                  alt="node-action-delete"
                />
              </button>
            </div>
            <div className="node-block-icon-parent w-[70px] h-[70px] flex items-end justify-center">
              <img
                className="node-block-icon"
                src="../../images/http-node-icon.svg"
                alt="http-node-icon"
              />
            </div>
            <span
              className="node-block-title mt-[11px] text-[#60809F] text-center font-[Inter-Medium] text-[16px] not-italic font-medium leading-[normal]"
              title={props.data.label}
            >
              {props.data.label}
            </span>
          </div>
        </div>
        <div
          onPointerDown={(e) => {
            e.stopPropagation();
          }}
          onDoubleClick={(e) => e.stopPropagation()}
          className="title hidden"
          data-testid="title"
        >
          {label}
        </div>
        {outputs.map(
          ([key, output]) =>
            output && (
              <div className="output" key={key} data-testid={`output-${key}`}>
                <div className="output-title" data-testid="output-title">
                  {output?.label}
                </div>
                <RefSocket
                  name="output-socket"
                  side="output"
                  emit={props.emit}
                  socketKey={key}
                  nodeId={id}
                  payload={output.socket}
                />
              </div>
            )
        )}
        {inputs.map(
          ([key, input]) =>
            input && (
              <div className="input" key={key} data-testid={`input-${key}`}>
                <RefSocket
                  name="input-socket"
                  emit={props.emit}
                  side="input"
                  socketKey={key}
                  nodeId={id}
                  payload={input.socket}
                />
                {input && (!input.control || !input.showControl) && (
                  <div className="input-title" data-testid="input-title">
                    {input?.label}
                  </div>
                )}
                {input?.control && input?.showControl && (
                  <span className="input-control">
                    <RefControl
                      key={key}
                      name="input-control"
                      emit={props.emit}
                      payload={input.control}
                    />
                  </span>
                )}
              </div>
            )
        )}
      </NodeStyles>
    </>
  );
}
