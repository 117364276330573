import { ClassicPreset, NodeEditor } from "rete"; 
import { Schemes } from "./rete/types";
import { ActionSocket, TextSocket } from "./editor";
import { format, formatDistanceToNow } from 'date-fns';
type Sockets = ActionSocket | TextSocket;
type Input = ClassicPreset.Input<Sockets>;
type Output = ClassicPreset.Output<Sockets>;

export function getConnectionSockets(
  editor: NodeEditor<Schemes>,
  connection: Schemes["Connection"]
) {
  const source = editor.getNode(connection.source);
  const target = editor.getNode(connection.target);

  const output =
    source &&
    (source.outputs as Record<string, Input>)[connection.sourceOutput];
  const input =
    target && (target.inputs as Record<string, Output>)[connection.targetInput];

  return {
    source: output?.socket,
    target: input?.socket
  };
}

export const URLS={
  BACK_END:'https://rete-newra-api.zusedigital.com'
}
export const formatWorkflowInfo = (data) => {
  const { user, created_at, updated_at } = data;

  // Handle cases where the fields may be missing
  if (!created_at || !updated_at || !user || !user.fname) return '';

  // Format "Last updated X hours ago"
  const lastUpdated = formatDistanceToNow(new Date(updated_at), { addSuffix: true });

  // Format "Created DD Month"
  const createdDate = format(new Date(created_at), 'd MMMM');

  // Return the formatted string
  return `Last updated ${lastUpdated} by ${user.fname} • Created ${createdDate}`;
};
export function getSourceConnectionIds(editor){
  let sourceIds=[]
  editor.connections.forEach(val=>{
    console.log(val.source)
    sourceIds.push(val.source)
  })
  return sourceIds
}
export function getInputConnectedNodes(editor){
  let connectedIds= getSourceConnectionIds(editor)
  let connectedNodes=[]
  editor.nodes.forEach(val=>{
      if(connectedIds.includes(val.id)){
        connectedNodes.push({
          id:val.id,
          label:val.label,
        })
      } 
  }) 
  return connectedNodes
}

export function isValidJsonCustom(jsonString) {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (e) {
    return false;
  }
}
export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export const isExpression = (value) => {
  const regex = /{{\s*inputData\[".*?"\]\s*}}/;
  return regex.test(value);
}
const extractKeysFromExpression = (expression) => {
  const regex = /{{\s*inputData\["(.*?)"\]\s*}}/g;
  let matches;
  const keys = [];
  while ((matches = regex.exec(expression)) !== null) {
    keys.push(matches[1]);
  }
  return keys;
};
export function getDropValue(keyData,inputData){
  const keys = extractKeysFromExpression(keyData);
  const value = keys.map(key => getValueFromKey(key, inputData[0]));
  return value.join('')
}

const getValueFromKey = (key, data) => {
  if (!key) return '';

  const keys = key.split(/\.|\[|\]/).filter(k => k); // Handle dot notation and array indices
  let value = data;

  for (const k of keys) {
    if (value[k] !== undefined) {
      value = value[k];
    } else {
      return '';
    }
  }

  return value;
};
