import React, { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import Select from "react-select";
import FixedExpressionTabs from "./FixedExpressionTabs";
import CustomSelectIconOption from "./CustomSelectIconOption";
import CustomSelectIconValue from "./CustomSelectIconValue";
import API from "../api";
import CustomSelectSubtitle from "./CustomSelectSubtitle";

const ItemType = {
  FIELD: "field",
};
 

const DynamicSortInput = ({
  index,
  inputField,
  handleChangeInput,
  handleRemoveField,
  tab,
  setTab,
  handleDrop,
  inputFields,
  inputData,
  formik
}) => {
  const [inputValue, setInputValue] = useState('');
  const [colunms, setColunms] = useState([]);
  
  const [{ canDropKey, isOverKey }, dropColumn] = useDrop({
    accept: ItemType.FIELD,
    drop: (item) => handleDrop(item, index, "column"),
    collect: (monitor) => ({
      isOverKey: monitor.isOver(),
      canDropKey: monitor.canDrop(),
    }),
  });

  

  const [{ canDropValue, isOverValue }, dropValue] = useDrop({
    accept: ItemType.FIELD,
    drop: (item) => handleDrop(item, index, "value"),
    collect: (monitor) => ({
      isOverValue: monitor.isOver(),
      canDropValue: monitor.canDrop(),
    }),
  });
const sort=[
    {
        value:'ASC',
        label:"ASC",

    },
    {
        value:'DESC',
        label:"DESC",
        
    }
]
  const extractKeyFromExpression = (expression) => {
    const regex = /{{\s*inputData\["(.*?)"\]\s*}}/;
    const match = expression.match(regex);
    return match ? match[1].trim() : null;
  };

  const getValueFromKey = (key, data) => {
    if (!key) return '';

    const keys = key.split(/\.|\[|\]/).filter(k => k); // Handle dot notation and array indices
    let value = data;

    for (const k of keys) {
      if (value[k] !== undefined) {
        value = value[k];
      } else {
        return '';
      }
    }

    return value;
  };

  const updateInputValue = (expression, data) => {
    const key = extractKeyFromExpression(expression);
    const value = getValueFromKey(key, data);
    setInputValue(value);
  };

  useEffect(() => {
    updateInputValue(inputField.value, inputData[0]);
  }, [inputField.value, inputData]);
  function getDropValue(keyData){
    const key = extractKeyFromExpression(keyData);
    const value = getValueFromKey(key, inputData[0]); 
    return value
  }


  React.useEffect(() => {
    const fetchData = async () => {
      try {
        if(formik.values['credential'] !='' && formik.values['table'] !=''){
            const response = await API.post("mysql/table-column", {
                credential: formik.values['credential'],
                tableName: formik.values['table'],
            });
    
            console.log(response.data);
            setColunms(response.data.results)
        }
       

         
      } catch (error) {
        //setError(error);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="edit-fields-inn-form-parent mysql-tripple-form-fields mysql-double-form-fields mysql-double-form-fields-sort">
    <div className="midform-draggable-fields_stack-item-inn relative border border-solid border-[#ccc] rounded-[6px]">
      {inputFields.length > 1 && (
        <div className="midform-draggable-fields_stack-item-delete absolute -left-[30px] top-[0] opacity-0 cursor-pointer [transition:0.3s_opacity_ease]"
          onPointerDown={(e) => e.stopPropagation()} onDoubleClick={(e) => e.stopPropagation()} onClick={() => handleRemoveField(index)}
        >
          <img
            src="../../images/node-action-delete.svg"
            alt="node-action-delete"
            className="w-[20px]"
          />
        </div>
      )}
      <div className="diff-dynamic-fields-with-dots-drop midform-field-has-dots-drop midform-field midform-field-select midform-select-subtitle relative">
        <div
          /* ref={dropOperator} */
          className={`input-container ${isOverValue ? "highlight" : ""}`}
        >
          <Select
            className="midform-select-main"
            classNamePrefix="midform-select"
            options={colunms}
            name="column"
            onChange={(e) =>
              handleChangeInput(index, {
                target: { name: "column", value: e.value },
              })
            }
            value={colunms.find((option) => option.value === inputField.column)}
            components={{ Option: CustomSelectSubtitle }}
          />
        </div>
      </div>
      
      <div className="diff-dynamic-fields-with-dots-drop midform-field-has-dots-drop">
        {/* <FixedExpressionTabs
          onExpressionChange={(value) =>{}
            //handleChangeInput(index, { target: { name: "value", value } })
          } 
        > */}
          <div
            ref={dropValue}
            className={`input-container ${isOverValue ? "highlight" : ""}`}
          >
            <Select
            className="midform-select-main"
            classNamePrefix="midform-select"
            options={sort}
            name="value"
            onChange={(e) =>{}
             /*  handleChangeInput(index, {
                target: { name: "value", value: e.value },
              }) */
            }
            value={sort.find((option) => option.value === inputField.value)} 
          />
             
            
          </div>
        {/* </FixedExpressionTabs> */}
      </div> 
    </div>
    </div>
  );
};

export default DynamicSortInput;
