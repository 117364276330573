import React from 'react';
import { components } from 'react-select';

const CustomSelectSubtitle = (props) => {
  return (
    <components.Option {...props}>
      <div className="midform-select-subtitle_option">
        <div className="midform-select-subtitle_title">
            {props.data.label}
        </div>
        <div className="midform-select-subtitle_subtitle">
          {props.data.subtitle}
        </div>
      </div>
    </components.Option>
  );
};

export default CustomSelectSubtitle;
