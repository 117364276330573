import React from 'react';
import { components } from 'react-select';

const CustomSelectMultiValue = (props) => {
  return (
    <components.MultiValue {...props}>
      <div className="midform-select-icon_value flex items-center justify-start">        
        <div className="midform-select-icon_value-img w-[16px] h-[16px] flex items-center justify-center mr-[4px]">        
          <img src={props.data.image} alt={props.data.label} />
        </div>
        <div className="midform-select-icon_value-text">
            {props.data.label}        
        </div>   
      </div>
    </components.MultiValue>
  );
};

export default CustomSelectMultiValue;
