import React from 'react';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FixedExpressionTabs from '../FixedExpressionTabs';
import DroppableSelect from '../DroppableSelect';
import DroppableInput from '../DroppableInput';

const HttpRequestForm = ({ onSubmit, options2, options3, inputData }) => {
  // Validation schema for form fields
  const validationSchema = Yup.object({
    method: Yup.string().required('HTTP Method is required'),
    url: Yup.string().url('Invalid URL format').required('URL is required'),
    queryParameters: Yup.array().of(
      Yup.object({
        name: Yup.string().required('Name is required'),
        value: Yup.string().required('Value is required'),
      })
    ),
    headers: Yup.array().of(
      Yup.object({
        name: Yup.string().required('Name is required'),
        value: Yup.string().required('Value is required'),
      })
    ),
    body: Yup.array().of(
      Yup.object({
        name: Yup.string().required('Name is required'),
        value: Yup.string().required('Value is required'),
      })
    ),
  });

  return (
    <Formik
      initialValues={{
        method: 'Get',
        url: '',
        authentication: 'None',
        sendQueryParameter: false,
        specifyQueryParameters: '',
        queryParameters: [{ name: '', value: '' }],
        sendHeaders: false,
        specifyHeadersType: '',
        headers: [{ name: '', value: '' }],
        sendBody: false,
        specifyBody: '',
        body: [{ name: '', value: '' }],
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (onSubmit) {
          onSubmit(values);
        }
      }}
    >
      {(formik) => {
       const { values, setFieldValue }=formik
        // Function to handle expression changes
        const handleExpressionChange = (field, value) => {
          setFieldValue(field, value);
        };

        return (
          <Form>
            {/* Method Field */}
            <div className="midform-field midform-field-has-dots-drop midform-field-select midform-select-subtitle relative mb-[23px] px-[45px] pt-[6px]">
              <div className="midform-field-dots-drop-wrap relative">
                <label className="midform-field-label block text-[#000] font-[Inter-Regular] text-[12px] not-italic font-normal leading-[normal] opacity-50 mb-[4px]">
                  Method
                </label>
                <FixedExpressionTabs
                  onExpressionChange={(value) =>
                    handleExpressionChange("method", value)
                  }
                >
                  <DroppableSelect
                    fieldName="method"
                    formik={formik}
                    options={options2}
                    tab={undefined}
                    setTab={undefined}
                    inputData={inputData}
                  />
                </FixedExpressionTabs>
              </div>
            </div>

            {/* URL Field */}
            <div className="midform-field midform-field-has-dots-drop mb-[23px] px-[45px] pt-[6px]">
              <div className="midform-field-dots-drop-wrap relative">
                <label className="midform-field-label block text-[#000] font-[Inter-Regular] text-[12px] not-italic font-normal leading-[normal] opacity-50 mb-[4px]">
                  URL
                </label>
                <FixedExpressionTabs
                  onExpressionChange={(value) =>
                    handleExpressionChange("url", value)
                  }
                >
                  <DroppableInput
                    fieldName="url"
                    formik={formik}
                    inputData={inputData}
                    tab={undefined}
                    setTab={undefined}
                  />
                </FixedExpressionTabs>
              </div>
            </div>

            {/* Query Parameters Toggle */}
            <div className="midform-field border-t [border-top-style:solid] [border-top-color:rgba(189,_202,_215,_0.3)]">
              <div className="midform-input-fields-switch node-comm-switch relative px-[45px] py-[23px] flex items-center justify-start">
                <div className="text-[#000] font-['Inter-Medium'] text-[12px] not-italic font-medium leading-[normal] opacity-80 mr-[11px]">
                  Send Query Parameters
                </div>
                <div className="node-comm-real-switch relative">
                  <input
                    type="checkbox"
                    id="send-query-parameters-switch"
                    className="absolute w-full h-full opacity-0 cursor-pointer top-[0] left-[0] z-[1]"
                    name="sendQueryParameter"
                    onChange={() => {
                      setFieldValue(
                        "sendQueryParameter",
                        !values.sendQueryParameter
                      );
                    }}
                  />
                  <label
                    className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 cursor-pointer [text-indent:-9999px] w-[40px] h-[20px] bg-[#A2A2A2] block rounded-[20px] relative"
                    htmlFor="send-query-parameters-switch"
                  >
                    Toggle
                  </label>
                </div>
              </div>

              {/* Query Parameters Fields */}
              {values.sendQueryParameter && (
                <FieldArray name="queryParameters">
                  {({ push, remove }) => (
                    <div className="monaco_editor_width88 px-[45px] pt-[5px] pb-[23px]">
                      {values.queryParameters.map((_, index) => (
                        <div key={index} className="flex items-center mb-[10px]">
                          <Field
                            name={`queryParameters[${index}].name`}
                            placeholder="Name"
                            className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[Inter-Regular] text-[12px] not-italic font-normal leading-[normal]"
                          />
                          <ErrorMessage
                            name={`queryParameters[${index}].name`}
                            component="div"
                            className="text-red-500 text-[12px]"
                          />
                          <Field
                            name={`queryParameters[${index}].value`}
                            placeholder="Value"
                            className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[Inter-Regular] text-[12px] not-italic font-normal leading-[normal] ml-[10px]"
                          />
                          <ErrorMessage
                            name={`queryParameters[${index}].value`}
                            component="div"
                            className="text-red-500 text-[12px]"
                          />
                          <button
                            type="button"
                            onClick={() => remove(index)}
                            className="ml-[10px] bg-red-500 text-white rounded-[4px] px-[10px] py-[2px]"
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() => push({ name: '', value: '' })}
                        className="bg-blue-500 text-white rounded-[4px] px-[10px] py-[2px]"
                      >
                        Add Parameter
                      </button>
                    </div>
                  )}
                </FieldArray>
              )}
            </div>

            {/* Send Headers Toggle */}
            <div className="midform-field border-t [border-top-style:solid] [border-top-color:rgba(189,_202,_215,_0.3)]">
              <div className="midform-input-fields-switch node-comm-switch relative px-[45px] py-[23px] flex items-center justify-start">
                <div className="text-[#000] font-['Inter-Medium'] text-[12px] not-italic font-medium leading-[normal] opacity-80 mr-[11px]">
                  Send Headers
                </div>
                <div className="node-comm-real-switch relative">
                  <input
                    type="checkbox"
                    id="send-headers-switch"
                    className="absolute w-full h-full opacity-0 cursor-pointer top-[0] left-[0] z-[1]"
                    name="sendHeaders"
                    onChange={() => {
                      setFieldValue(
                        "sendHeaders",
                        !values.sendHeaders
                      );
                    }}
                  />
                  <label
                    className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 cursor-pointer [text-indent:-9999px] w-[40px] h-[20px] bg-[#A2A2A2] block rounded-[20px] relative"
                    htmlFor="send-headers-switch"
                  >
                    Toggle
                  </label>
                </div>
              </div>

              {/* Headers Fields */}
              {values.sendHeaders && (
                <FieldArray name="headers">
                  {({ push, remove }) => (
                    <div className="monaco_editor_width88 px-[45px] pt-[5px] pb-[23px]">
                      {values.headers.map((_, index) => (
                        <div key={index} className="flex items-center mb-[10px]">
                          <Field
                            name={`headers[${index}].name`}
                            placeholder="Name"
                            className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[Inter-Regular] text-[12px] not-italic font-normal leading-[normal]"
                          />
                          <ErrorMessage
                            name={`headers[${index}].name`}
                            component="div"
                            className="text-red-500 text-[12px]"
                          />
                          <Field
                            name={`headers[${index}].value`}
                            placeholder="Value"
                            className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[Inter-Regular] text-[12px] not-italic font-normal leading-[normal] ml-[10px]"
                          />
                          <ErrorMessage
                            name={`headers[${index}].value`}
                            component="div"
                            className="text-red-500 text-[12px]"
                          />
                          <button
                            type="button"
                            onClick={() => remove(index)}
                            className="ml-[10px] bg-red-500 text-white rounded-[4px] px-[10px] py-[2px]"
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() => push({ name: '', value: '' })}
                        className="bg-blue-500 text-white rounded-[4px] px-[10px] py-[2px]"
                      >
                        Add Header
                      </button>
                    </div>
                  )}
                </FieldArray>
              )}
            </div>

            {/* Send Body Toggle */}
            <div className="midform-field border-t [border-top-style:solid] [border-top-color:rgba(189,_202,_215,_0.3)]">
              <div className="midform-input-fields-switch node-comm-switch relative px-[45px] py-[23px] flex items-center justify-start">
                <div className="text-[#000] font-['Inter-Medium'] text-[12px] not-italic font-medium leading-[normal] opacity-80 mr-[11px]">
                  Send Body
                </div>
                <div className="node-comm-real-switch relative">
                  <input
                    type="checkbox"
                    id="send-body-switch"
                    className="absolute w-full h-full opacity-0 cursor-pointer top-[0] left-[0] z-[1]"
                    name="sendBody"
                    onChange={() => {
                      setFieldValue(
                        "sendBody",
                        !values.sendBody
                      );
                    }}
                  />
                  <label
                    className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 cursor-pointer [text-indent:-9999px] w-[40px] h-[20px] bg-[#A2A2A2] block rounded-[20px] relative"
                    htmlFor="send-body-switch"
                  >
                    Toggle
                  </label>
                </div>
              </div>

              {/* Body Fields */}
              {values.sendBody && (
                <FieldArray name="body">
                  {({ push, remove }) => (
                    <div className="monaco_editor_width88 px-[45px] pt-[5px] pb-[23px]">
                      {values.body.map((_, index) => (
                        <div key={index} className="flex items-center mb-[10px]">
                          <Field
                            name={`body[${index}].name`}
                            placeholder="Name"
                            className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[Inter-Regular] text-[12px] not-italic font-normal leading-[normal]"
                          />
                          <ErrorMessage
                            name={`body[${index}].name`}
                            component="div"
                            className="text-red-500 text-[12px]"
                          />
                          <Field
                            name={`body[${index}].value`}
                            placeholder="Value"
                            className="w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[Inter-Regular] text-[12px] not-italic font-normal leading-[normal] ml-[10px]"
                          />
                          <ErrorMessage
                            name={`body[${index}].value`}
                            component="div"
                            className="text-red-500 text-[12px]"
                          />
                          <button
                            type="button"
                            onClick={() => remove(index)}
                            className="ml-[10px] bg-red-500 text-white rounded-[4px] px-[10px] py-[2px]"
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() => push({ name: '', value: '' })}
                        className="bg-blue-500 text-white rounded-[4px] px-[10px] py-[2px]"
                      >
                        Add Body Parameter
                      </button>
                    </div>
                  )}
                </FieldArray>
              )}
            </div>

            {/* Submit Button */}
            <div className="midform-head p-[22px] pt-[13px]">
              <div className="midform-head-wrap relative">
                <div className="midform-head-inn relative flex items-center justify-between px-[19px] py-[15px]">
                  <button
                    type="submit"
                    className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 relative z-[1] [box-shadow:none] h-[34px] rounded-[4px] bg-[#824EF0] border-0 !px-[12px] !py-[0] text-[#fff] font-[Inter-Regular] text-[12px] not-italic font-normal leading-[normal]"
                  >
                    Test Workflow
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default HttpRequestForm;
